import { IFund } from 'models/fund.model';
import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import React, { ReactElement, useMemo } from 'react';
import { IconType } from 'react-icons';
import { IoCheckmarkCircleOutline, IoPeopleOutline, IoWarningOutline } from 'react-icons/io5';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AppTheme, { IAppTheme } from 'themes/app-theme.config';

import InvitationsSent from './InvitationsSent';

const StatusBlocksContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StatusBlockContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: whitesmoke;
  padding: 1.5rem 1.5rem 1.5rem 2rem;
  margin-right: 2rem;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }: IAppTheme) => theme.colors.secondaryColor};
  }

  .status-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${({ theme }: IAppTheme) => theme.colors.offBlack};

    &-title {
      ${({ theme }: IAppTheme) => theme.mixins.desktopLinkSmall};
      font-weight: bold;
      white-space: nowrap;
      line-height: 35px;
    }

    &-value {
      ${({ theme }: IAppTheme) => theme.mixins.desktopDisplayXSmall};
      font-weight: bold;
      white-space: nowrap;
      color: ${({ theme }: IAppTheme) => theme.colors.offBlack};
    }

    &-label {
      ${({ theme }: IAppTheme) => theme.mixins.desktopLinkXSmall};
      color: ${({ theme }: IAppTheme) => theme.colors.label};
      white-space: nowrap;
    }
  }

  .status-image {
    width: 80px;
    height: 80px;
    border-radius: 16px;
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }: IAppTheme) => theme.colors.offWhite};
  }
`;

export interface StatusBlockData {
  id: number;
  title: string;
  value: string;
  label?: string;
  color: string;
  icon: ReactElement<IconType>;
}

type StatusBlocksProps = {
  fund: IFund;
};

export default function StatusBlocks({ fund }: StatusBlocksProps) {
  const { push } = useHistory();
  const { openDialog } = useApp();

  const issuesAmount = React.useMemo(() => {
    return fund.subscriptions
      .map((subscription) => {
        try {
          return JSON.parse(subscription.issues).length;
        } catch (error) {
          return 0;
        }
      })
      .reduce((prev, curr) => prev + curr, 0);
  }, [fund.subscriptions]);

  const blocksData = useMemo(
    () => [
      {
        id: 0,
        color: AppTheme.colors.primaryColor,
        icon: <RiMoneyDollarCircleLine size={46} color={AppTheme.colors.primaryColor} />,
        // label: 'Over last month $123,909',
        title: 'Total Commitment',
        value: `$${fund.commitment}`,
        onClick: () => push({ pathname: AppPagesEnum.FundCommitment, state: { fundId: fund.id } })
      },
      {
        id: 1,
        color: issuesAmount > 0 ? AppTheme.colors.warning : AppTheme.colors.success,
        icon:
          issuesAmount > 0 ? (
            <IoWarningOutline size={46} color={AppTheme.colors.warning} />
          ) : (
            <IoCheckmarkCircleOutline size={46} color={AppTheme.colors.success} />
          ),
        title: 'Compliance Status',
        value: `${issuesAmount} Issue(s)`,
        onClick: () => push({ pathname: AppPagesEnum.FundIssues, state: { fundId: fund.id } })
      },
      {
        id: 2,
        color: AppTheme.colors.success,
        icon: <IoPeopleOutline size={46} color={AppTheme.colors.success} />,
        title: 'Registered Subscribers',
        value: `${fund.subscriptions.length} Subscriber(s)`,
        onClick: () => fund && openDialog?.(<InvitationsSent {...{ fund }} />)
      }
    ],
    [fund, issuesAmount, push, openDialog]
  );

  return (
    <StatusBlocksContainer>
      {blocksData.map((block) => {
        return (
          <StatusBlockContainer key={block.id} onClick={block.onClick}>
            <div className="status-data">
              <div className="status-data-title">{block.title}</div>
              <div className="status-data-value" style={{ color: block.color }}>
                {block.value}
              </div>
              {/* <div className="status-data-label">{block.label}</div> */}
            </div>
            <div className="status-image">{block.icon}</div>
          </StatusBlockContainer>
        );
      })}
    </StatusBlocksContainer>
  );
}
