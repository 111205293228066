import AppPagesEnum from 'pages/pages.enum';
import { configRoutes, RouteConfig } from 'routes/config.routes';

import ForgotPasswordPage from './pages/forgot-password/ForgotPasswordPage';
import LoginPage from './pages/login/LoginPage';
import NewPasswordPage from './pages/new-password/NewPasswordPage';
import SignUpSubscriberPage from './pages/sign-up-subscriber/SignUpSubscriberPage';

const routes: RouteConfig[] = [
  { path: AppPagesEnum.Login, component: LoginPage },
  { path: AppPagesEnum.SignUpSubscriber, component: SignUpSubscriberPage },
  { path: AppPagesEnum.ChangePassword, component: NewPasswordPage },
  { path: AppPagesEnum.ForgotPassword, component: ForgotPasswordPage }
];

const AccessRoutes = () => configRoutes(routes);

export default AccessRoutes;
