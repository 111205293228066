import React, { ReactElement, useCallback } from 'react';
import { IconType } from 'react-icons';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 3px solid
    ${({ theme }: IAppTheme) => theme.colors.secondaryLine};

  .tab-item {
    display: flex;
    align-items: center;
    padding: 2rem 1rem;
    ${({ theme }: IAppTheme) => theme.mixins.desktopLinkSmall}
    font-weight: 600;
    color: ${({ theme }: IAppTheme) => theme.colors.placeholder};
    margin-bottom: -3px;
    width: 200px;
    transition: color 0.3s;
    cursor: pointer;
    border-bottom: 3px solid transparent;

    span {
      margin-left: 0.5rem;
    }

    &:hover {
      color: ${({ theme }: IAppTheme) => theme.colors.label};
    }

    &.active {
      color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
      border-bottom: 3px solid
        ${({ theme }: IAppTheme) => theme.colors.primaryColor};
    }
  }
`;

export interface ITab {
  key: any;
  icon: ReactElement<IconType>;
  label: string;
}

type TableTabsProps = {
  tabs: ITab[];
  tabSelected: any;
  changeTab: (tab: any) => void;
};

export default function GTableTabs({
  tabs,
  tabSelected,
  changeTab
}: TableTabsProps) {
  const activeClass = useCallback(
    (tab: number) => (tab === tabSelected ? 'active' : ''),
    [tabSelected]
  );

  return (
    <TabsContainer>
      {tabs.map((tab) => (
        <div
          key={tab.key}
          className={`tab-item ${activeClass(tab.key)}`}
          onClick={() => changeTab?.(tab.key)}
          aria-hidden="true">
          {tab.icon} <span>{tab.label}</span>
        </div>
      ))}
    </TabsContainer>
  );
}
