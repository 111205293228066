import React from 'react';
import Title from 'shared/styled/Title';
import styled from 'styled-components';

const GQuestionDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  height: 100%;
  width: 600px;
  padding: 4rem;

  .btn-actions {
    display: flex;
  }

  .btn {
    padding: 12px 70px;
    width: 200px;
  }
`;

export enum QuestionDialogAction {
  YES_NO,
  OK
}

export interface IQuestionDialogContext {
  title: string;
  message: string;
  action: QuestionDialogAction;
}

type GQuestionDialogProps = {
  onAnswer: (answer: boolean) => void;
  context: IQuestionDialogContext;
};

export default function GQuestionDialog({
  onAnswer,
  context
}: GQuestionDialogProps) {
  return (
    <GQuestionDialogContainer>
      <Title className="mb-5">{context.title}</Title>

      <div className="d-flex mb-5">
        <span>{context.message}</span>
      </div>

      {context.action === QuestionDialogAction.YES_NO && (
        <div className="btn-actions">
          <button
            type="button"
            className="btn btn-primary mx-3"
            onClick={() => onAnswer(true)}>
            Yes
          </button>
          <button
            type="button"
            className="btn btn-light mx-3"
            onClick={() => onAnswer(false)}>
            No
          </button>
        </div>
      )}

      {context.action === QuestionDialogAction.OK && (
        <button
          type="button"
          className="btn btn-primary mx-3"
          onClick={() => onAnswer(true)}>
          Ok
        </button>
      )}
    </GQuestionDialogContainer>
  );
}
