import { IFund } from './fund.model';
import { IUser } from './user.model';

export enum SubscriptionStatus {
  CREATED = 'created',
  IN_PROGRESS = 'in_progress',
  WITH_ISSUES = 'with_issues',
  COMPLETED = 'completed'
}

export interface ISubscription {
  id?: number;
  totalAmount: number;
  createdAt: Date;
  subscriber: IUser;
  fund: IFund;
  answers?: string;
  issues: string;
  reviewMessage?: string;
  status: SubscriptionStatus;
}
