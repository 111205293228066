import { ISubscription, SubscriptionStatus } from 'models/subscription.model';
import moment from 'moment';
import AppPagesEnum from 'pages/pages.enum';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSubscriptionService from 'services/subscription.service';
import GTable, { RowStructure, TableActionsEnum } from 'shared/controls/GTable';
import TopBarActions from 'shared/sections/TopBarActions';
import { numberWithCommas } from 'shared/utils/functions';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const FundDocumentListPageContainer = styled.div`
  display: flex;
  height: 100%;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .page-content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const tableHeaders = ['#Id', 'Fund Name', 'Commitment', 'Subscription Status', 'Subscription Date'];

const tableRowsStructure: RowStructure[] = [
  { key: 'id' },
  {
    key: 'fund.name',
    useComponent: (subscription: ISubscription) => (
      <h6>
        <span className="badge bg-primary">{subscription.fund?.name}</span>
      </h6>
    )
  },
  {
    key: 'totalAmount',
    useComponent: (subscription: ISubscription) => <span>${numberWithCommas(Number(subscription.totalAmount))}</span>
  },
  {
    key: 'status',
    useComponent: (subscription: ISubscription) => <span className="badge bg-primary">{subscription.status.toUpperCase()}</span>
  },
  {
    key: 'createdat',
    useComponent: (subscription: ISubscription) => <span>{moment(subscription.createdAt).format('MM/DD/YYYY HH:mm')}</span>
  }
];

export default function MySubscriptionsPage() {
  const { push } = useHistory();
  const SubscriptionService = useSubscriptionService();

  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    SubscriptionService.allBySubscriber()
      .then((res) => {
        setSubscriptions(res.data);
      })
      .finally(() => setLoadingData(false));
  }, []);

  const actionExecuted = useCallback((subscription: ISubscription, actionType: TableActionsEnum) => {
    if (actionType === TableActionsEnum.RowClick) {
      if ([SubscriptionStatus.CREATED, SubscriptionStatus.WITH_ISSUES].includes(subscription.status)) {
        push({
          pathname: AppPagesEnum.FundDocumentAnswer,
          state: { subscriptionId: subscription.id, editMode: true }
        });
      } else {
        push({
          pathname: AppPagesEnum.SubscriptionDetails,
          state: { subscriptionId: subscription.id, page: AppPagesEnum.SubscriptionDetails }
        });
      }
    }
  }, []);

  const tableProps = {
    headers: tableHeaders,
    rows: tableRowsStructure,
    data: subscriptions,
    onExecuteAction: actionExecuted,
    loading: loadingData
  };

  return (
    <FundDocumentListPageContainer>
      <div className="page-content">
        <TopBarActions label={`My Subscriptions (${subscriptions.length})`} />
        <div className="px-3">
          <GTable {...tableProps} />
        </div>
      </div>
    </FundDocumentListPageContainer>
  );
}
