import { FundInvitation, IFund } from 'models/fund.model';
import { useApp } from 'providers/app.provider';
import React, { useEffect, useState } from 'react';
import { BsCheck2 } from 'react-icons/bs';
import useFundService from 'services/fund.service';
import GLoading from 'shared/controls/GLoading';
import Title from 'shared/styled/Title';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const InviteSubscribersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  height: 100%;
  padding: 4rem;

  .email-list {
    width: 400px;
    padding: 10px 20px;
    border: 1px solid ${({ theme }: IAppTheme) => theme.colors.line};
    border-radius: 5px;

    &-title {
      ${({ theme }: IAppTheme) => theme.mixins.desktopLinkSmall};
      font-weight: bold;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0px 0 0 0;
      li {
        padding: 15px 2px;
        display: flex;
        align-items: center;
        transition: color 0.3s ease;
      }
    }
  }
  .btn-actions {
    display: flex;
  }
  .btn {
    padding: 12px 70px;
    width: 250px;
    &.btn-info {
      color: white;
    }
  }
`;

type InviteSubscribersProps = {
  fund: IFund;
};

export default function InvitationsSent({ fund }: InviteSubscribersProps) {
  const FundsService = useFundService();

  const { closeDialog, showSnackbar } = useApp();

  const [loading, setLoading] = useState(false);
  const [invitationsSent, setInvitationsSent] = useState<FundInvitation[]>();

  useEffect(() => {
    if (fund.id) {
      setLoading(true);

      FundsService.getInvitationsSent(fund.id)
        .finally(() => setLoading(false))
        .then((res) => setInvitationsSent(res.data))
        .catch(() => {
          showSnackbar?.({
            variant: 'error',
            message: 'Something went wrong. Please try again!'
          });

          closeDialog?.();
        });
    }
  }, [closeDialog, showSnackbar, fund.id]);

  return (
    <InviteSubscribersContainer>
      <Title className="mb-5">Invitations Sent</Title>

      <div className="d-flex mb-4">
        <strong className="me-2">Fund:</strong>
        <span>{fund.name}</span>
      </div>

      <div className="email-list mb-4">
        <div className="email-list-title">List of sent emails:</div>

        {!!invitationsSent?.length && (
          <ul className="mt-3">
            {invitationsSent?.map((e: any) => {
              return (
                <li className="d-flex justify-content-between">
                  <div className="">{e.email}</div>
                  {!!e.registered && (
                    <div className="text-success text-small">
                      <BsCheck2 size={18} className="me-1" />
                      Registered
                    </div>
                  )}

                  {!e.registered && <span className="text-secondary text small">Not Registered</span>}
                </li>
              );
            })}
          </ul>
        )}

        {!invitationsSent?.length && <div>No invitations sent.</div>}

        {loading && <GLoading />}
      </div>

      <button type="button" className="btn btn-light" onClick={() => closeDialog?.()}>
        Close
      </button>
    </InviteSubscribersContainer>
  );
}
