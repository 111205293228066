import './SignUpSubscriberPage.scss';

import { IUser } from 'models/user.model';
import AppPagesEnum from 'pages/pages.enum';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FormStepOne from './steps/FormStepOne';
import FormStepTwo from './steps/FormStepTwo';

type ISubscriberSignUpContext = {
  userData: Partial<IUser>;
  updateUserData(data: Partial<IUser>): void;
  back(): void;
};

const SubscriberSignUpContext = React.createContext<Partial<ISubscriberSignUpContext>>({});

export function useSubscriberSignUpContext() {
  return useContext(SubscriberSignUpContext);
}

export default function SignUpSubscriberPage() {
  const { push } = useHistory();

  const signUpSteps = [<FormStepOne />, <FormStepTwo />];

  const [userData, setUserData] = useState<Partial<IUser>>({});
  const [currentStep, setCurrentStep] = useState(0);

  function updateUserData(data: Partial<IUser>) {
    setUserData((prev) => ({ ...prev, ...data }));
    setCurrentStep(currentStep + 1);
  }

  function back() {
    setCurrentStep(currentStep - 1);
  }

  return (
    <div className="SignUpSubscriberPage">
      <div>
        <h2 className="form-title">Sign Up as a Subscriber</h2>
      </div>

      <SubscriberSignUpContext.Provider value={{ userData, updateUserData, back }}>{signUpSteps[currentStep]}</SubscriberSignUpContext.Provider>

      <div className="d-flex align-items-center">
        <div>Already have an account?</div>

        <button type="button" className="btn text-primary p-0 ms-1" onClick={() => push(AppPagesEnum.Login)}>
          Sign in instead
        </button>
      </div>
    </div>
  );
}
