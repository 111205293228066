import { IUser } from 'models/user.model';

import useHttpService from './http.service';

export default function useUserService() {
  const url = 'user';

  const { instance, ...genericMethods } = useHttpService<IUser>(url);

  function allSubscribers() {
    return instance.get<IUser[]>(`${url}/subscribers`);
  }

  function oneSubscriber(subscriberId: number) {
    return instance.get<IUser>(`${url}/subscriber/${subscriberId}`);
  }

  function createNew(user: IUser) {
    return instance.post<IUser>(`${url}/create-new`, user);
  }

  function activateUserAccount(userId: number) {
    return instance.put(`${url}/activate/${userId}`);
  }

  function deactivateUserAccount(userId: number) {
    return instance.put(`${url}/deactivate/${userId}`);
  }

  function forgotPassword(email: string) {
    return instance.post('auth/reset-password', { email });
  }

  return { ...genericMethods, allSubscribers, oneSubscriber, createNew, activateUserAccount, deactivateUserAccount, forgotPassword };
}
