import './AccessPage.scss';

import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import React from 'react';
import { Switch, useHistory } from 'react-router-dom';

import AccessRoutes from './access.routes';

const AccessPage = () => {
  const { push } = useHistory();
  const { documentToken, newUserToken } = useApp();

  const redirectTo = React.useMemo(() => {
    if (documentToken) {
      return AppPagesEnum.SignUpSubscriber;
    }

    if (newUserToken) {
      return AppPagesEnum.ChangePassword;
    }

    return AppPagesEnum.Login;
  }, [documentToken, newUserToken]);

  React.useEffect(() => {
    push(redirectTo);
  }, [push, redirectTo]);

  return (
    <div className="AccessPage">
      <div className="AccessPage-content d-flex h-100 align-items-stretch">
        <div className="form-container">
          <div className="content">
            <Switch>{AccessRoutes()}</Switch>
          </div>
        </div>
        <div className="aside-block">
          <div className="aside-block-subtitle fw-medium text-primary">Seven Bridges</div>
          <br /> <br />
          <div className="aside-block-title text-dark">
            <div>
              <span className="fs-3 fw-bold">A simple way to manage fund subscription documents.</span>
            </div>
            <br />
            <p className="fs-4 text-dark me-5">
              Issue, collect, and analyze documents from your subscribers. Use Seven Bridges to collaborate across your organization and with
              compliance professionals to expedite closings and ongoing compliance.
            </p>
            <p className="fs-4 text-dark me-5">
              Seven Bridges was built from the ground up with security in mind. Data is encrypted in rest, and in transit. All sensitive data is
              stored using state of the art methodologies.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessPage;
