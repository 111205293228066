const AppTheme = {
  colors: {
    offBlack: '#14142B',
    ash: '#262338',
    body: '#4E4B66',
    label: '#6E7191',
    placeholder: '#A0A3BD',
    line: '#D9DBE9',
    secondaryLine: '#F7F2FF',
    input: '#EFF0F6',
    background: '#F7F7FC',
    offWhite: '#FCFCFC',
    primaryColor: '#002a48',
    secondaryColor: '#e5f8ffb8',
    warning: '#CC5E00',
    success: '#009977',
    danger: '#990000'
  },
  transitions: {
    fadeIn: `
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;
    
    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }`
  },
  mixins: {
    desktopDisplayHuge: `
    font-size: 60px;
      line-height: 72px;
      letter-spacing: 1px;
      `,

    desktopDisplayLarge: `
    font-size: 60px;
      line-height: 72px;
      letter-spacing: 1px;
      `,

    desktopDisplayMedium: `
    font-size: 40px;
      line-height: 56px;
      letter-spacing: 1px;
      `,

    desktopDisplaySmall: `
    font-size: 33px;
      line-height: 40px;
      letter-spacing: 1px;`,

    desktopDisplayXSmall: `
    font-size: 24px;
      line-height: 32px;
      letter-spacing: 1px;
    `,

    desktopTextLarge: `
    font-size: 20px;
      line-height: 38px;
      letter-spacing: 0.75px;
    `,

    desktopTextMedium: `
    font-size: 17px;
      line-height: 34px;
      letter-spacing: 0.75px;
    `,

    desktopTextSmall: `
    font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.75px;
    `,

    desktopTextXSmall: `
    font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.75px;
    `,

    desktopLinkLarge: `
    font-size: 20px;
      line-height: 38px;
      letter-spacing: 0.75px;
    `,

    desktopLinkMedium: `
    font-size: 17px;
      line-height: 34px;
      letter-spacing: 0.75px;
    `,

    desktopLinkSmall: `
    font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.75px;
    `,

    desktopLinkXSmall: `
    font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.25px;
    `
  },
  vgsInput: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    boxSizing: 'border-box',
    backgroundColor: 'aliceblue',
    borderRadius: '6px',
    padding: '10px 16px',
    objectFit: 'contain',
    border: '1px solid #d3dce6',
    color: '#6e7191',
    width: '100%',
    height: '44px',
    '&::placeholder': {
      color: '#6E7191',
      fontWeight: 300
    }
  }
};

export default AppTheme;

export type IAppTheme = { theme: typeof AppTheme };
