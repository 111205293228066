import DashboardPage from 'pages/dashboard/DashboardPage';
import FundDocumentListPage from 'pages/fund-document/FundDocumentListPage';
import FundDocumentAnswerPage from 'pages/fund-document/pages/fund-document-answer/FundDocumentAnswerPage';
import FundDocumentTemplatePage from 'pages/fund-document/pages/fund-document-template/FundDocumentTemplatePage';
import FundsPage from 'pages/funds/FundsPage';
import FundDetailsPage from 'pages/funds/pages/fund-details/FundDetailsPage';
import FundCommitmentPage from 'pages/funds/pages/FundCommitmentPage';
import FundIssuesPage from 'pages/funds/pages/FundIssuesPage';
import AppPagesEnum from 'pages/pages.enum';
import SubscriberDetailsPage from 'pages/subscribers/pages/subscriber-details/SubscriberDetailsPage';
import SubscribersPage from 'pages/subscribers/SubscribersPage';
import MySubscriptionsPage from 'pages/subscription/MySubscriptionsPage';
import SubscriptionPage from 'pages/subscription/subscription-page/SubscriptionPage';
import UserAccountPage from 'pages/users/pages/user-account/UserAccountPage';
import UsersPage from 'pages/users/UsersPage';

import { configRoutes, RouteConfig } from './config.routes';

const routes: RouteConfig[] = [
  { path: AppPagesEnum.Dashboard, component: DashboardPage },
  { path: AppPagesEnum.FundDetails, component: FundDetailsPage },
  { path: AppPagesEnum.FundCommitment, component: FundCommitmentPage },
  { path: AppPagesEnum.FundIssues, component: FundIssuesPage },
  { path: AppPagesEnum.Funds, component: FundsPage },
  { path: AppPagesEnum.Subscribers, component: SubscribersPage },
  { path: AppPagesEnum.SubscriberDetails, component: SubscriberDetailsPage },
  { path: AppPagesEnum.SubscriptionDetails, component: SubscriptionPage },
  { path: AppPagesEnum.MySubscriptions, component: MySubscriptionsPage },
  { path: AppPagesEnum.FundDocuments, component: FundDocumentListPage },
  { path: AppPagesEnum.FundDocumentTemplate, component: FundDocumentTemplatePage },
  { path: AppPagesEnum.FundDocumentAnswer, component: FundDocumentAnswerPage },
  { path: AppPagesEnum.UserAccount, component: UserAccountPage },
  { path: AppPagesEnum.Users, component: UsersPage }
];

const AppRoutes = () => configRoutes(routes, false);

export default AppRoutes;
