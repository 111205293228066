import { IFund } from 'models/fund.model';
import { ISubscription } from 'models/subscription.model';
import moment from 'moment';
import AppPagesEnum from 'pages/pages.enum';
import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useFundService from 'services/fund.service';
import useSubscriptionService from 'services/subscription.service';
import GLoading from 'shared/controls/GLoading';
import TopBarActions from 'shared/sections/TopBarActions';
import Title from 'shared/styled/Title';
import TopBarLabelContainer from 'shared/styled/TopBarLabelContainer';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const FundIssuesPageContainer = styled.div`
  display: flex;
  height: 100%;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .fund-commitment-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export default function FundIssuesPage() {
  const { state } = useLocation<{ fundId: number }>();
  const { goBack } = useHistory();

  const FundService = useFundService();
  const SubscriptionService = useSubscriptionService();

  const [fund, setFund] = React.useState<IFund>();
  const [subscriptions, setSubscriptions] = React.useState<ISubscription[]>();
  const [loading, setLoading] = React.useState(false);

  const fetch = React.useCallback(() => {
    if (state.fundId) {
      setLoading(true);
      FundService.one(state.fundId).then((res) => {
        setFund(res.data);

        SubscriptionService.allByFund(state.fundId)
          .finally(() => setLoading(false))
          .then((res) => setSubscriptions(res.data));
      });
    }
  }, []);

  React.useEffect(() => fetch(), [state.fundId]);

  const topBarLabel = React.useMemo(() => {
    return (
      <TopBarLabelContainer>
        <Link to={AppPagesEnum.Funds}>Funds</Link>
        <RiArrowRightSLine size={20} />
        <span className="current-item">{fund?.name}</span>
        <RiArrowRightSLine size={20} />
        <span className="current-item">Fund Commitment</span>
      </TopBarLabelContainer>
    );
  }, [fund]);

  const getIssuesAmount = React.useCallback((subscription: ISubscription) => {
    return JSON.parse(subscription.issues || '[]').length;
  }, []);

  const filteredSubscriptions = React.useMemo(() => subscriptions?.filter((subscription) => getIssuesAmount(subscription) > 0), [subscriptions]);

  return (
    <FundIssuesPageContainer>
      {loading && <GLoading />}

      <div className="fund-commitment-page-content">
        <TopBarActions label={topBarLabel} />
        <div className="d-flex justify-content-between">
          <Title className="my-4">{fund?.name} Issues</Title>
          <button type="button" className="btn btn-dark m-4" onClick={() => goBack()}>
            <BiArrowBack size={20} />
          </button>
        </div>
        <br /> <br />
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Subscriber Name</th>
              <th>Number Of Issues</th>
              <th>Message To Subscriber</th>
              <th>Subscription Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredSubscriptions?.map((subscription) => (
              <tr key={subscription.id}>
                <td>#{subscription.id}</td>
                <td>{subscription.subscriber.name}</td>
                <td>{getIssuesAmount(subscription)}</td>
                <td>{moment(subscription.createdAt).format('MM/DD/YYYY HH:mm')}</td>
                <td>{subscription.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </FundIssuesPageContainer>
  );
}
