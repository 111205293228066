export function hex2bin(hex: string) {
  return parseInt(hex, 16).toString(2).padStart(8, '0');
}

export function byteArray2string(byteArray: number[]) {
  const hexData = byteArray
    .reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, '')
    .split(' ');

  return hex2string(hexData);
}

export function hex2string(array: string[]) {
  return array
    .map((hex) => {
      return String.fromCharCode(parseInt(hex, 16));
    })
    .join('');
}

export function numberWithCommas(x: number) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0';
}
