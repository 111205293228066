import { IconButton, Tooltip } from '@material-ui/core';
import { useApp } from 'providers/app.provider';
import { useAuth } from 'providers/auth.provider';
import React from 'react';
import { CgMenuLeft } from 'react-icons/cg';
import { FiLogOut } from 'react-icons/fi';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const HeaderContainer = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
  /* border-bottom: 2px solid ${({ theme }: IAppTheme) => theme.colors.line}; */

  .MuiIconButton-root {
    color: ${({ theme }: IAppTheme) => theme.colors.offWhite};
  }
`;

export default function AppHeader() {
  const { signOut } = useAuth();
  const { toggleSidebar } = useApp();

  return (
    <HeaderContainer>
      <Tooltip title="Toggle Navigation">
        <IconButton aria-label="menu" onClick={toggleSidebar}>
          <CgMenuLeft />
        </IconButton>
      </Tooltip>

      {/* <div className="flex-grow-1 mx-3">
        <GForm onValidate={() => {}}>
          <GInput name="search" className="w-25" placeholder="Search Fund" />
        </GForm>
      </div> */}

      <div className="text-white fw-bold fs-5">Seven Bridges</div>

      {/* <Tooltip title="Help">
        <IconButton aria-label="menu" className="me-2" size="medium">
          <GrCircleQuestion color={AppTheme.colors.offWhite} />
        </IconButton>
      </Tooltip> */}

      {/* <Avatar src="../../assets/images/RajRaunak.png" style={{ width: 40, height: 40 }} /> */}

      <Tooltip title="Logout">
        <IconButton className="ms-4" onClick={() => signOut?.()}>
          <FiLogOut />
        </IconButton>
      </Tooltip>
    </HeaderContainer>
  );
}
