import './NewPasswordPage.scss';

import { useApp } from 'providers/app.provider';
import { useAuth } from 'providers/auth.provider';
import React, { useCallback, useState } from 'react';
import GForm, { IValidateResult } from 'shared/controls/GForm';
import GInput from 'shared/controls/GInput';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';
import * as yup from 'yup';

const LoginPageContainer = styled(GForm)`
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}
`;

const validationSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords do not match')
});

interface ILoginData {
  email: string;
  password: string;
}

export default function NewPasswordPage() {
  const { changePassword } = useAuth();
  const { showSnackbar } = useApp();

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    ({ data }: IValidateResult<ILoginData>) => {
      try {
        if (changePassword) {
          setLoading(true);

          const { password } = data;
          changePassword(password);

          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        showSnackbar?.({ variant: 'error', message: 'Something went wrong. Please, try again!' });
      }
    },
    [changePassword, setLoading]
  );

  const wait = (
    <div className="d-flex align-items-center justify-content-center">
      <span className="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true" />
      Wait...
    </div>
  );

  return (
    <LoginPageContainer onValidate={onSubmit} validationSchema={validationSchema} className="LoginPage">
      <h2 className="form-title mb-5">Change Password</h2>

      <div className="mt-5">
        <GInput type="password" name="password" className="mb-4" label="Password" placeholder="Use 8 characters or more" />

        <GInput type="password" name="confirmPassword" className="mb-5" label="Confirm Password" placeholder="Passwords must match" />

        <button type="submit" className="btn btn-primary py-2 w-100 text-white" disabled={loading}>
          {loading ? wait : 'Submit'}
        </button>
      </div>
    </LoginPageContainer>
  );
}
