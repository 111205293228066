import { IFund } from 'models/fund.model';
import moment from 'moment';
import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import React, { useEffect, useMemo, useState } from 'react';
import { BiDollar } from 'react-icons/bi';
import { IoAdd } from 'react-icons/io5';
import { MdAccessTime } from 'react-icons/md';
import { RiFileExcel2Line, RiSurveyLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import useFundService from 'services/fund.service';
import GTableTabs from 'shared/controls/GTableTabs';
import GTableV2, { GTableV2RowStructure } from 'shared/controls/GTableV2';
import ActionsBar, { IAction } from 'shared/sections/ActionsBar';
import TopBarActions from 'shared/sections/TopBarActions';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

import FundForm, { FundFormMode } from './components/FundForm';

const FundsPageContainer = styled.div`
  display: flex;
  height: 100%;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .funds-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const tableTabs = [
  {
    key: 0,
    icon: <BiDollar size={20} />,
    label: 'Current Funds'
  },
  {
    key: 1,
    icon: <MdAccessTime size={20} />,
    label: 'Past Funds'
  }
];

const tableRowsStructure: GTableV2RowStructure[] = [
  { key: 'name', strong: true },
  {
    key: 'commitment',
    getValue: (fund: IFund) => (
      <span>
        Commitment: <strong>${Number(fund.commitment ?? 0).toFixed(2)}</strong>
      </span>
    )
  },
  {
    key: 'subscriptionsamount',
    getValue: (fund: IFund) => (
      <span>
        <strong>{fund.subscriptionsamount ?? 0}</strong> Subscription(s)
      </span>
    )
  },
  {
    key: 'createdat',
    getValue: (fund: IFund) => (
      <span>
        Created on <br />
        {moment(fund.createdAt).format('MM/DD/YYYY HH:mm')}
      </span>
    )
  }
];

export default function FundsPage() {
  const FundService = useFundService();
  const { openDialog, modalContent } = useApp();
  const { push } = useHistory();

  const [tabSelected, changeTab] = useState(0);
  const [funds, setFunds] = useState<IFund[]>([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    fetch();
  }, [modalContent]);

  const fetch = React.useCallback(() => {
    if (!modalContent) {
      setLoadingData(true);
      FundService.all()
        .then((res) => {
          setFunds(res.data);
        })
        .finally(() => setLoadingData(false));
    }
  }, [modalContent]);

  const activeTabFunds = useMemo(() => {
    if (tabSelected === tableTabs[0].key) {
      return funds.filter((fund) => fund.active);
    }

    return funds.filter((fund) => !fund.active);
  }, [funds, tabSelected]);

  function exportData() {
    setLoadingData(true);
    FundService.exportToExcel()
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'funds_overview.xlsx');
        link.click();
      })
      .finally(() => setLoadingData(false));
  }

  const sideBarActions: IAction[] = [
    {
      id: 0,
      icon: <IoAdd size={32} />,
      executeAction: () => openDialog?.(<FundForm {...{ mode: FundFormMode.New }} />),
      title: 'Create New Fund'
    },
    {
      id: 1,
      icon: <RiFileExcel2Line size={32} />,
      executeAction: () => exportData(),
      title: 'Export To Excel'
    },
    {
      id: 1,
      icon: <RiSurveyLine size={32} />,
      executeAction: () => push(AppPagesEnum.FundDocuments),
      title: 'Subscription Documents'
    }
  ];

  function seeDetails(fund: IFund) {
    push({ pathname: AppPagesEnum.FundDetails, state: { fundId: fund.id } });
  }

  const tableProps = {
    data: activeTabFunds,
    rowsStructure: tableRowsStructure,
    loading: loadingData,
    onClickRow: seeDetails
  };

  return (
    <FundsPageContainer>
      <div className="funds-page-content">
        <TopBarActions label={`Funds Overview (${funds.length})`} {...{ fetch }} />
        <GTableTabs {...{ tabs: tableTabs, changeTab, tabSelected }} />
        <GTableV2 {...tableProps} useRowSelection />
      </div>

      <ActionsBar actions={sideBarActions} />
    </FundsPageContainer>
  );
}
