import { IUser } from 'models/user.model';
import AccessPage from 'pages/access/AccessPage';
import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configureAxiosInstance } from 'services/http.service';
import { LoginResponse } from 'services/models/login-response.model';
import LoadingApp from 'shared/controls/LoadingApp';

export enum UserRole {
  General = 'general',
  Admin = 'admin',
  Subscriber = 'subscriber'
}

type IAuthContext = {
  currentUser: IUser;
  signUp: (user: IUser) => Promise<LoginResponse | void>;
  signIn(email: string, password: string): Promise<LoginResponse | void>;
  changePassword: (password: string) => void;
  signOut: () => void;
  userToken?: string;
};

const AuthContext = React.createContext<Partial<IAuthContext>>({});

export function useAuth() {
  return useContext(AuthContext);
}

type AuthProviderProps = {
  children: React.ReactNode;
};

export default function AuthProvider({ children }: AuthProviderProps) {
  const apiTokenLSKey = 'appAccessToken';
  const userDataLSKey = 'appUserData';

  const { documentToken, newUserToken } = useApp();
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [userToken, setUserToken] = useState<string>();
  const [currentUser, setCurrentUser] = useState<IUser>();

  const axiosInstance = configureAxiosInstance();

  useEffect(() => {
    const token = localStorage.getItem(apiTokenLSKey);
    const userData = localStorage.getItem(userDataLSKey);

    if (token) {
      setUserToken(token);
    }

    if (userData) {
      setCurrentUser(JSON.parse(userData));
    }
  }, []);

  function registerUserData(data: LoginResponse) {
    setUserToken(data.accessToken);
    setCurrentUser(data.user);
    localStorage.setItem(apiTokenLSKey, data.accessToken);
    localStorage.setItem(userDataLSKey, JSON.stringify(data.user));
  }

  function signUp(user: IUser) {
    setLoading(true);
    return axiosInstance
      .post<LoginResponse>(`auth/register/${documentToken}`, user)
      .then((res) => registerUserData(res.data))
      .finally(() => setLoading(false));
  }

  async function signIn(email: string, password: string) {
    return axiosInstance.post<LoginResponse>('auth/login', { email, password }).then((res) => registerUserData(res.data));
  }

  async function changePassword(password: string) {
    if (newUserToken) {
      setLoading(true);

      axiosInstance
        .post<LoginResponse>(`auth/change-password/${newUserToken}`, { password })
        .then((res) => registerUserData(res.data))
        .finally(() => setLoading(false));
    }
  }

  function signOut() {
    setUserToken(undefined);
    localStorage.clear();
    push(AppPagesEnum.Login);
  }

  const renderApp = useMemo(() => {
    return userToken ? children : <AccessPage />;
  }, [userToken]);

  const value = {
    currentUser,
    signUp,
    signIn,
    signOut,
    userToken,
    changePassword
  };

  return (
    <AuthContext.Provider value={value}>
      {loading && <LoadingApp />}
      {!loading && renderApp}
    </AuthContext.Provider>
  );
}
