// eslint-disable
import $ from 'jquery';
import { IFundDocument, IFundDocumentField } from 'models/fund-document.model';
import { ISubscription } from 'models/subscription.model';
import moment from 'moment';
import documentsFields from 'pages/fund-document/documents/documents-fields.json';
import { DocumentTemplateCodeEnum, GetDocumentTemplateByCode } from 'pages/fund-document/documents/documents.config';
import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import { useAuth } from 'providers/auth.provider';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useFundDocumentService from 'services/fund-documents.service';
import useSubscriptionService from 'services/subscription.service';
import GForm, { IValidateResult } from 'shared/controls/GForm';
import GLoading from 'shared/controls/GLoading';
import { QuestionDialogAction } from 'shared/controls/GQuestionDialog';
import Title from 'shared/styled/Title';
import generateVGSForm from 'shared/utils/vgs';
import styled from 'styled-components';
import AppTheme, { IAppTheme } from 'themes/app-theme.config';

const FundDocumentAnswerPageContainer = styled(GForm)`
  flex: 1;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  max-height: 100%;
  padding: 4rem;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .document-content {
    width: 70%;
    height: auto;
    border-radius: 16px;
    background-color: ${({ theme }: IAppTheme) => theme.colors.offWhite};
  }

  .MuiPaper-root {
    background-color: ${({ theme }: IAppTheme) => theme.colors.offWhite};
  }

  .MuiStepper-root {
    padding: 0px;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
    color: white;
  }

  iframe {
    height: 46px;
    width: 100%;
  }
`;

export default function FundDocumentAnswerPage() {
  const { push } = useHistory();
  const { state } = useLocation<{ subscriptionId: number; editMode: boolean }>();
  const { documentToken, requestAdjustmentsValue, showSnackbar, clearDocumentData, openQuestionDialog } = useApp();
  const { userToken, signOut } = useAuth();

  const FundDocumentService = useFundDocumentService();
  const SubscriptionService = useSubscriptionService();

  const [document, setDocument] = React.useState<IFundDocument>();
  const [subscription, setSubscription] = React.useState<ISubscription>();
  const [vgsForm, setVGSForm] = React.useState<any>();
  const [answers, setAnswers] = React.useState<any>();
  const [issues, setIssues] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);

  const fetchData = React.useCallback(async () => {
    const subscriptionId = state?.subscriptionId || requestAdjustmentsValue;

    try {
      if (documentToken) {
        setLoading(true);
        const fundResult = await FundDocumentService.oneByInvite();
        setDocument(fundResult.data);
      }

      if (subscriptionId) {
        setLoading(true);

        const result = await SubscriptionService.one(subscriptionId);

        if (result?.data) {
          setSubscription(result.data);

          const { answers, issues, fund } = result.data;

          setAnswers(answers);
          setIssues(JSON.parse(issues || '[]'));

          const fundDocumentResult = await FundDocumentService.oneByFund(fund.id);

          setDocument(fundDocumentResult.data);
        }
      }
    } catch (error: any) {
      if (error?.response?.data?.message?.length) {
        showSnackbar?.({ variant: 'error', message: error.response.data.message });
      } else {
        showSnackbar?.({ variant: 'error', message: 'Tha action could not be executed. Please, try again!' });
      }

      if ([401, 403].includes(error.response.status)) {
        clearDocumentData?.();
        signOut?.();
        return;
      }

      push(AppPagesEnum.MySubscriptions);
    } finally {
      setLoading(false);
    }
  }, [documentToken, state?.subscriptionId, requestAdjustmentsValue]);

  React.useEffect(() => {
    fetchData();
  }, [documentToken, requestAdjustmentsValue]);

  async function createForm() {
    if (!vgsForm) {
      const form = await generateVGSForm();
      setVGSForm(form);
    }
  }

  React.useEffect(() => {
    if (document) {
      createForm();
      generateVGSFields();
    }
  }, [document, vgsForm, answers]);

  async function generateVGSFields() {
    const shouldSolveIssues = (!!state?.subscriptionId && !state?.editMode) || !!requestAdjustmentsValue;

    if (vgsForm && !!documentsFields) {
      (documentsFields as IFundDocumentField[]).forEach((field) => {
        if (shouldSolveIssues && !issues.includes(field.key)) {
          return;
        }

        const element = $(`#${field.key}`);
        const frame = $(`#${field.key} iframe`);

        if (!element.length) {
          console.log('FIELD NOT FOUND >>', field.key);
        }

        if (element.length && !frame.length) {
          element.empty();

          const fieldParams = {
            type: field.type,
            name: field.key,
            placeholder: answers?.[field.key as any] || '',
            css: AppTheme.vgsInput
          };

          vgsForm.field(`#${field.key}`, fieldParams);
        }
      });

      if (answers && !state?.editMode) {
        (documentsFields as IFundDocumentField[]).forEach((field) => {
          if (!issues.includes(field.key)) {
            const answer = answers[field.key as any] as string;
            const element = $(`#${field.key}`);

            element.empty();

            if (answer) {
              element.append(answer);
            } else {
              element.css('color', 'blue');
              element.append('<span>EMPTY</span>');
            }
          }
        });
      }
    }
  }

  const handleFormSubmit = async ({ data: notCodedAnswers, initialData }: IValidateResult<any>) => {
    const { vgsForm } = initialData;

    const subscriptionId = state?.subscriptionId || requestAdjustmentsValue;

    ($ as any).blockUI({ message: '<div style="padding:10px; font-weight:bold;">Securing and submitting your data, please wait...</div>' });

    setTimeout(() => {
      if (userToken && (subscriptionId || document?.fund?.id)) {
        const { totalAmount } = notCodedAnswers;

        const vgsURL = subscriptionId
          ? `app/update-subscription/${userToken}/${subscriptionId}`
          : `app/create-subscription/${userToken}/${document?.fund?.id}/${totalAmount}`;

        vgsForm?.submit?.(vgsURL, { Authorization: `Bearer ${userToken}` }, async (status: any, res: any) => {
          try {
            if (![200, 201].includes(status)) {
              throw new Error('The action could not be executed. Please, try again!');
            }

            await SubscriptionService.updateSubscription(res.id, notCodedAnswers);

            showSnackbar?.({
              variant: 'success',
              message: 'The changes were successfully saved!'
            });

            const shouldFinalize = await openQuestionDialog?.({
              action: QuestionDialogAction.YES_NO,
              message: 'Do you want to finalize this subscription?',
              title: 'Finalize?'
            });

            if (shouldFinalize) {
              await SubscriptionService.finalizeSubscription(res.id);

              showSnackbar?.({
                variant: 'success',
                message: 'The subscription was finalized!'
              });
            }

            clearDocumentData?.();
            push(AppPagesEnum.MySubscriptions);
          } catch (error: any) {
            showSnackbar?.({
              variant: 'error',
              message: error.message
            });
          }
        });
      }

      ($ as any).unblockUI();
    }, 3000);
  };

  return (
    <FundDocumentAnswerPageContainer onValidate={handleFormSubmit} initialData={{ vgsForm, ...answers }}>
      <Title className="text-center mb-5">Fund Subscription Document</Title>

      {loading && <GLoading />}

      <span>
        <strong>Fund name: </strong>
        {document?.fund?.name}
      </span>

      {subscription?.reviewMessage && (
        <div className="border-top mt-3 pt-2">
          <label className="mb-2 ">
            <strong>Message from the reviewer:</strong>{' '}
          </label>
          <p className="pe-3 border-bottom pb-4">
            <i>{subscription?.reviewMessage}</i>
          </p>

          <small>Subscription held in {moment(subscription?.createdAt).format('MM-DD-YYYY HH:mm')}</small>
        </div>
      )}

      {GetDocumentTemplateByCode(document?.templateCode as DocumentTemplateCodeEnum)}

      <div className="action-buttons">
        <button type="submit" className="btn btn-primary mx-4">
          SUBMIT ANSWERS
        </button>
      </div>
    </FundDocumentAnswerPageContainer>
  );
}
