import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import { useAuth, UserRole } from 'providers/auth.provider';
import React, { useCallback } from 'react';
import { BiCollection, BiDollar } from 'react-icons/bi';
import { FiPieChart, FiUsers } from 'react-icons/fi';
import { RiFileList3Line, RiSurveyLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const NavigationBarContainer = styled.div<{ expanded: boolean }>`
  padding: 2rem 0;
  padding-right: 2rem;
  width: ${({ expanded }) => (expanded ? '320px' : '80px')};
  transition: width 0.2s ease;

  .item-link {
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    width: 290px;
    border-radius: 0 50px 50px 0;
    transition: all 0.3s;
    font-size: 14px;
    transition: color 0.2s ease, background-color 0.2s ease;

    .item-link-text {
      transition: width 0.2s ease, opacity 0.2s ease;
      opacity: ${({ expanded }) => (expanded ? '1' : '0')};
      width: ${({ expanded }) => (expanded ? '180px' : '0px')};
    }

    &:hover {
      color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
      cursor: pointer;
    }

    &.active {
      color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
      background-color: ${({ theme }: IAppTheme) => theme.colors.secondaryColor};
    }

    span {
      margin-left: 10px;
    }
  }
`;

const navigationLinks = [
  {
    id: 0,
    icon: <FiPieChart size={20} />,
    label: 'Dashboard',
    pages: [AppPagesEnum.Dashboard],
    allowedRoles: [UserRole.Admin, UserRole.General]
  },
  {
    id: 1,
    icon: <BiDollar size={20} />,
    label: 'Funds Overview',
    pages: [AppPagesEnum.Funds, AppPagesEnum.FundDetails, AppPagesEnum.FundDocuments],
    allowedRoles: [UserRole.Admin, UserRole.General]
  },
  {
    id: 2,
    icon: <RiFileList3Line size={20} />,
    label: 'All Subscribers',
    pages: [AppPagesEnum.Subscribers, AppPagesEnum.SubscriberDetails],
    allowedRoles: [UserRole.Admin, UserRole.General]
  },
  {
    id: 3,
    icon: <FiUsers size={20} />,
    label: 'Users Management',
    pages: [AppPagesEnum.Users, AppPagesEnum.UserAccount],
    allowedRoles: [UserRole.Admin]
  },
  {
    id: 4,
    icon: <BiCollection size={22} />,
    label: 'My Subscriptions',
    pages: [AppPagesEnum.MySubscriptions, AppPagesEnum.SubscriptionDetails],
    allowedRoles: [UserRole.Subscriber]
  }
];

export default function NavigationBar() {
  const { push } = useHistory();
  const { currentUser } = useAuth();
  const { currentPage, sidebarExpanded } = useApp();

  const activeClass = useCallback(
    (pages: AppPagesEnum[]) => {
      if (currentPage) {
        return pages.includes(currentPage) ? 'active' : '';
      }

      return '';
    },
    [currentPage]
  );

  return (
    <NavigationBarContainer {...{ expanded: !!sidebarExpanded }}>
      {currentUser &&
        navigationLinks
          .filter((link) => link.allowedRoles.includes(currentUser.role))
          .map((link) => (
            <div key={link.id} className={`item-link ${activeClass(link.pages)}`} onClick={() => push(link.pages[0])} aria-hidden="true">
              {link.icon} <span className="item-link-text">{link.label}</span>
            </div>
          ))}
    </NavigationBarContainer>
  );
}
