import './ForgotPasswordPage.scss';

import { useApp } from 'providers/app.provider';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useUserService from 'services/user.service';
import GForm, { IValidateResult } from 'shared/controls/GForm';
import GInput from 'shared/controls/GInput';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';
import * as yup from 'yup';

const LoginPageContainer = styled(GForm)`
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}
`;

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Provide a valid email')
});

interface IForgotPasswordData {
  email: string;
}

export default function ForgotPasswordPage() {
  const { goBack } = useHistory();
  const { showSnackbar } = useApp();

  const UserService = useUserService();

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    ({ data }: IValidateResult<IForgotPasswordData>) => {
      setLoading(true);

      UserService.forgotPassword(data.email)
        .finally(() => setLoading(false))
        .then(() =>
          showSnackbar?.({ variant: 'info', message: 'Email verified. If an account with this email exists, a password reset link will be sent.' })
        )
        .catch(() => showSnackbar?.({ variant: 'error', message: 'Something went wrong. Please, try again!' }));
    },
    [setLoading]
  );

  const wait = (
    <div className="d-flex align-items-center justify-content-center">
      <span className="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true" />
      Wait...
    </div>
  );

  return (
    <LoginPageContainer onValidate={onSubmit} validationSchema={validationSchema} className="LoginPage">
      <h2 className="form-title mb-5">Forgot Password</h2>

      <div className="mt-5">
        <p>If an account with this email exists, a password reset link will be sent.</p>

        <br />

        <GInput type="email" name="email" className="mb-4" label="Email" placeholder="Provide your email" />

        <div className="row">
          <div className="col-md-6">
            <button type="button" className="btn btn-secondary py-2 w-100 text-white" onClick={() => goBack()}>
              Back
            </button>
          </div>

          <div className="col-md-6">
            <button type="submit" className="btn btn-primary py-2 w-100 text-white">
              {loading ? wait : 'Reset Password'}
            </button>
          </div>
        </div>
      </div>
    </LoginPageContainer>
  );
}
