import { IFundDocument } from 'models/fund-document.model';
import AppPagesEnum from 'pages/pages.enum';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import useFundDocumentService from 'services/fund-documents.service';
import GForm from 'shared/controls/GForm';
import GLoading from 'shared/controls/GLoading';
import TopBarActions from 'shared/sections/TopBarActions';
import TopBarLabelContainer from 'shared/styled/TopBarLabelContainer';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

import TemplateForm from './components/TemplateForm';

const FundDocumentTemplatePageContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .page-content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .bottom-actions {
      display: flex;
      padding: 1rem 1.5rem;
      border-top: 2px solid ${({ theme }: IAppTheme) => theme.colors.line};
    }
  }
`;

type IFundDocumentTemplateContext = {
  fundDocument: IFundDocument;
  addFieldToSection(sectionIndex: number): void;
  selectField(field: any | null): void;
  fieldSelected: any | null;
  refreshData(): void;
};

const FundDocumentTemplateContext = React.createContext<Partial<IFundDocumentTemplateContext>>({});

export function useFundDocumentTemplate() {
  return useContext(FundDocumentTemplateContext);
}

export default function FundDocumentTemplatePage() {
  const FundDocumentService = useFundDocumentService();

  const { state } = useLocation<{ documentId: number }>();

  const [fundDocument, setFundDocument] = useState<any>();
  const [fieldSelected, setFieldSelected] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    setLoading(true);
    FundDocumentService.one(state.documentId)
      .then((res) => setFundDocument(res.data))
      .finally(() => setLoading(false));
  }

  const topBarLabel = useMemo(() => {
    return (
      <TopBarLabelContainer>
        <Link to={AppPagesEnum.FundDocuments}>Subscription Documents</Link>
        <RiArrowRightSLine size={20} />
        <span className="current-item">{fundDocument?.name}</span>
      </TopBarLabelContainer>
    );
  }, [fundDocument]);

  function addFieldToSection(sectionIndex: number) {
    if (fundDocument?.sections) {
      const section = fundDocument?.sections[sectionIndex];
      const id = section?.fields?.length - 1;

      section?.fields.push({
        id,
        key: 'toDefine',
        label: 'Define Input Type',
        columns: 3,
        type: 'text',
        order: id
      });

      setFundDocument({ ...fundDocument });
    }
  }

  const value = {
    fundDocument,
    addFieldToSection,
    selectField: setFieldSelected,
    fieldSelected,
    refreshData
  };

  return (
    <FundDocumentTemplateContext.Provider {...{ value }}>
      {loading && <GLoading />}
      <FundDocumentTemplatePageContainer>
        <GForm onValidate={() => {}} initialData={fundDocument} className="page-content">
          <TopBarActions label={topBarLabel} />

          <div className="d-flex flex-grow-1 overflow-hidden">
            <TemplateForm />
          </div>
        </GForm>
      </FundDocumentTemplatePageContainer>
    </FundDocumentTemplateContext.Provider>
  );
}
