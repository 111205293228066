import { ISubscription } from 'models/subscription.model';
import { FilterPeriodEnum } from 'pages/dashboard/DashboardPage';

import useHttpService from './http.service';

interface CreateSubscriptionDTO {
  fundId: number;
  answers: any;
}

interface RequestAdjustmentsDTO {
  message: string;
  issues: string;
}

export default function useSubscriptionService() {
  const url = 'subscription';

  const { instance, ...genericMethods } = useHttpService<ISubscription>(url);

  function allBySubscriber() {
    return instance.get<ISubscription[]>(`${url}/all-by-subscriber`);
  }

  function allByFund(fundId: number) {
    return instance.get<ISubscription[]>(`${url}/all-by-fund/${fundId}`);
  }

  function createSubscription(data: CreateSubscriptionDTO) {
    return instance.post<ISubscription>(`${url}/create-subscription`, data);
  }

  function updateSubscription(id: number, answers: string) {
    return instance.put(`${url}/update-subscription/${id}`, { answers });
  }

  function finalizeSubscription(id: number) {
    return instance.put(`${url}/finalize-subscription/${id}`);
  }

  function approve(id: number) {
    return instance.put(`${url}/approve/${id}`);
  }

  function requestAdjustments(id: number, data: RequestAdjustmentsDTO) {
    return instance.post<ISubscription>(`${url}/request-adjustments/${id}`, data);
  }

  function getDashboardData(period: FilterPeriodEnum) {
    return instance.get(`${url}/dashboard-data/${period}`);
  }

  function exportSubscriptionsDataToExcel(fundId: number) {
    return instance({
      method: 'GET',
      url: `${url}/export-subscriptions-data/${fundId}`,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    });
  }

  return {
    ...genericMethods,
    allByFund,
    allBySubscriber,
    createSubscription,
    finalizeSubscription,
    updateSubscription,
    approve,
    requestAdjustments,
    getDashboardData,
    exportSubscriptionsDataToExcel
  };
}
