import { useField } from '@unform/core';
import React, { TextareaHTMLAttributes, useEffect, useRef } from 'react';

type InputProps = {
  label?: string;
};

export default function Textarea(props: TextareaHTMLAttributes<HTMLTextAreaElement> & InputProps) {
  const { name, ...rest } = props;

  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name as string);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);

  return <textarea ref={inputRef} className="form-control" {...rest} />;
}
