import { IUser } from 'models/user.model';
import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import React, { useCallback, useEffect, useState } from 'react';
import { IoAdd } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import useUserService from 'services/user.service';
import { QuestionDialogAction } from 'shared/controls/GQuestionDialog';
import GTable, { RowStructure, TableActionsEnum } from 'shared/controls/GTable';
import ActionsBar, { IAction } from 'shared/sections/ActionsBar';
import TopBarActions from 'shared/sections/TopBarActions';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';
import AddUserForm from './components/AddUserForm';

const UsersPageContainer = styled.div`
  display: flex;
  height: 100%;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .users-page-content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const tableHeaders = ['Id', 'Full Name', 'Email', 'Role'];

const tableRowsStructure: RowStructure[] = [
  { key: 'id' },
  { key: 'name' },
  { key: 'email' },
  {
    key: 'roles',
    useComponent: (user: IUser) => (
      <h6>
        <span className="badge bg-primary">{user.role}</span>
      </h6>
    )
  }
];

export default function FundsPage() {
  const { push } = useHistory();
  const { openQuestionDialog, showSnackbar, openDialog, modalContent } = useApp();

  const UserService = useUserService();

  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, [modalContent]);

  const fetchUsers = React.useCallback(() => {
    setLoading(true);
    UserService.all()
      .then((res) => {
        const result = res.data.map((user) => ({
          ...user,
          role: user.role
        }));
        setUsers(result);
      })
      .finally(() => setLoading(false));
  }, [UserService, setLoading, setUsers, showSnackbar]);

  const removeUser = React.useCallback(
    (userId: number) => {
      setLoading(true);
      UserService.remove(userId)
        .then(() => {
          showSnackbar?.({
            variant: 'success',
            message: 'User removed successfully!'
          });
          fetchUsers();
        })
        .catch(() => {
          setLoading(false);
          showSnackbar?.({
            variant: 'error',
            message: 'The action could not be executed. Please, try again!'
          });
        });
    },
    [UserService, setLoading, showSnackbar, fetchUsers]
  );

  const actionExecuted = useCallback((user: IUser, actionType: TableActionsEnum) => {
    switch (actionType) {
      case TableActionsEnum.RowClick:
        push({
          pathname: AppPagesEnum.UserAccount,
          state: { user }
        });
        break;

      case TableActionsEnum.Remove:
        openQuestionDialog?.({
          action: QuestionDialogAction.YES_NO,
          message: 'Are you sure you want to remove this user?',
          title: 'Remove User?'
        }).then((answer) => {
          if (answer) {
            removeUser(user.id);
          }
        });
        break;

      default:
        break;
    }
  }, []);

  const sideBarActions: IAction[] = [
    {
      id: 0,
      icon: <IoAdd size={32} />,
      executeAction: () => openDialog?.(<AddUserForm />)
    }
  ];

  const tableProps = {
    headers: tableHeaders,
    rows: tableRowsStructure,
    data: users,
    actions: [TableActionsEnum.Remove],
    onExecuteAction: actionExecuted,
    loading
  };

  return (
    <UsersPageContainer>
      <div className="users-page-content">
        <TopBarActions {...{ label: `System Users (${users.length})`, fetch: fetchUsers }} />

        <GTable {...tableProps} />
      </div>

      <ActionsBar actions={sideBarActions} />
    </UsersPageContainer>
  );
}
