import { Tooltip } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { IconType } from 'react-icons';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const ActionsBarContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background-color: ${({ theme }: IAppTheme) => theme.colors.input};
  border-left: 2px solid ${({ theme }: IAppTheme) => theme.colors.line};

  .action-button {
    position: relative;
    height: 60px;
    width: 60px;
    opacity: 0.8;
    transition: all 0.2s;

    &-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export interface IAction {
  id: number;
  icon: ReactElement<IconType>;
  executeAction: Function;
  title?: string;
}

type ActionsBarProps = {
  actions: IAction[];
};

export default function ActionsBar({ actions }: ActionsBarProps) {
  return (
    <ActionsBarContainer>
      {actions.map((action) => (
        <Tooltip key={action.id} title={action.title ?? ''}>
          <button
            type="button"
            onClick={() => action.executeAction()}
            className="btn btn-primary mb-4 action-button">
            <div className="action-button-content">{action.icon}</div>
          </button>
        </Tooltip>
      ))}
    </ActionsBarContainer>
  );
}
