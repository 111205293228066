import { useApp } from 'providers/app.provider';
import React from 'react';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

type GModalContainerProps = {
  active: boolean;
};

const GModalContainer = styled.div<GModalContainerProps>`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(20, 20, 43, 0.45);
  backdrop-filter: blur(5px);
  z-index: 10;
  display: ${({ active }) => (active ? 'block' : 'none')};
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .modal-content {
    width: auto;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    border: none;
    border-radius: 8px;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export default function GModal() {
  const { modalContent } = useApp();

  return (
    <GModalContainer active={!!modalContent} aria-hidden>
      <div className="modal-content">{modalContent}</div>
    </GModalContainer>
  );
}
