import { IFundDocument } from 'models/fund-document.model';
import { useApp } from 'providers/app.provider';

import useHttpService from './http.service';

export default function useFundDocumentService() {
  const url = 'documents';
  const { documentToken } = useApp();

  const { instance, ...genericMethods } = useHttpService<IFundDocument>(url);

  function oneByInvite() {
    return instance.get(`${url}/one-by-invite/${documentToken}`);
  }

  function oneByFund(fundId: number) {
    return instance.get(`${url}/one-active-by-fund/${fundId}`);
  }

  function enableTemplate(fundId: number) {
    return instance.put(`${url}/enable/${fundId}`);
  }

  return { ...genericMethods, oneByInvite, oneByFund, enableTemplate };
}
