import { DocumentTemplateCodeEnum, GetDocumentTemplateByCode } from 'pages/fund-document/documents/documents.config';
import { useApp } from 'providers/app.provider';
import React from 'react';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

import { useFundDocumentTemplate } from '../FundDocumentTemplatePage';
import PageTitleActions from './PageTitleActions';

const FundDocumentForm = styled.div`
  flex: 1;
  padding: 1rem 1.5rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  textarea {
    font-family: 'Courier New', Courier, monospace;
  }

  .sections-container {
    flex: 1;

    input {
      background-color: ${({ theme }: IAppTheme) => theme.colors.secondaryColor};
    }

    .section-field {
      .form-label {
        transition: color 0.2s ease;
        cursor: pointer;
      }

      .form-control {
        transition: background-color 0.2s ease, opacity 0.2s ease;
        cursor: pointer;
      }

      &.active {
        .form-label {
          font-weight: 600;
          color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
        }

        .form-control {
          background-color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
        }

        input::placeholder {
          color: ${({ theme }: IAppTheme) => theme.colors.offWhite};
        }
      }

      &:hover {
        cursor: pointer;

        .form-label {
          color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
        }

        .form-control {
          background-color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
          opacity: 0.6;
        }
      }
    }

    .section-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px dashed ${({ theme }: IAppTheme) => theme.colors.line};

      &-label {
        font-weight: 600;
      }
    }
  }
`;

export default function TemplateForm() {
  const { modalContent } = useApp();

  const { fundDocument, refreshData } = useFundDocumentTemplate();

  React.useEffect(() => {
    if (!modalContent) {
      refreshData?.();
    }
  }, [modalContent]);

  return (
    <FundDocumentForm>
      <PageTitleActions />

      <div className="my-3">
        <p className={`text-${fundDocument?.active ? 'success' : 'danger'} fw-bold`}>
          <span>Status: </span>
          {fundDocument?.active ? 'ACTIVE' : 'DISABLED'}
        </p>

        <p>
          <strong>This document will be applied to: </strong>
          <span>{fundDocument?.fund?.name}</span>
        </p>
      </div>

      {fundDocument?.templateCode && <div className="flex-1">{GetDocumentTemplateByCode(fundDocument.templateCode as DocumentTemplateCodeEnum)}</div>}
    </FundDocumentForm>
  );
}
