import { IconButton, Tooltip } from '@material-ui/core';
import React, { ReactElement, useMemo } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { FiCopy, FiTrash2 } from 'react-icons/fi';
import GInputCheckbox from 'shared/controls/GInputCheckbox';
import styled from 'styled-components';
import AppTheme, { IAppTheme } from 'themes/app-theme.config';

import GLoading from './GLoading';

type TableContainerProps = {
  actionsDefined: boolean;
};

const LoadingContainer = styled.div`
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
`;

const TableContainer = styled.div<TableContainerProps>`
  position: relative;
  overflow-y: auto;
  flex-grow: 1;

  table {
    width: 100%;

    thead {
      height: 60px;
      border-bottom: 2px solid #f7f2ff;

      th {
        padding: 1rem;
        ${({ theme }: IAppTheme) => theme.mixins.desktopLinkXSmall};
        text-transform: uppercase;
      }
    }

    tbody tr {
      td {
        padding: ${({ actionsDefined }) => (actionsDefined ? '1rem' : '1.5rem')} 1rem;
        border-bottom: 1px solid ${({ theme }: IAppTheme) => theme.colors.input};
        transition: border-color 0.2s ease, color 0.2s ease;
      }

      &:hover {
        cursor: pointer;
        td {
          color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
          border-bottom: 1px solid ${({ theme }: IAppTheme) => theme.colors.primaryColor};
        }
      }
    }
  }
`;

export interface RowStructure {
  key?: string;
  useComponent?: (item: any) => ReactElement<any>;
}

export enum TableActionsEnum {
  Edit,
  Select,
  View,
  Duplicate,
  RowClick,
  Remove
}

type GTableProps = {
  headers: string[];
  rows: RowStructure[];
  data: any[];
  actions?: TableActionsEnum[];
  loading?: boolean;
  onExecuteAction?: (item: any, action: TableActionsEnum) => void;
};

const possibleActions = [
  {
    actionEnum: TableActionsEnum.Edit,
    label: 'Edit',
    icon: <BiEdit color={AppTheme.colors.primaryColor} />
  },
  {
    actionEnum: TableActionsEnum.View,
    label: 'View',
    icon: <AiOutlineEye color={AppTheme.colors.primaryColor} />
  },
  {
    actionEnum: TableActionsEnum.Duplicate,
    label: 'Duplicate',
    icon: <FiCopy color={AppTheme.colors.primaryColor} />
  },
  {
    actionEnum: TableActionsEnum.Remove,
    label: 'Remove',
    icon: <FiTrash2 color={AppTheme.colors.primaryColor} />
  }
];

export default function GTable({ headers, rows, data, actions, loading, onExecuteAction }: GTableProps) {
  const selectEnabled = useMemo(() => {
    return !!actions?.includes(TableActionsEnum.Select);
  }, [actions]);

  const tableElement = useMemo(
    () => (
      <TableContainer actionsDefined={!!actions}>
        <table>
          <thead>
            <tr>
              {selectEnabled && <th />}
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
              {!!actions && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id} onClick={() => onExecuteAction?.(item, TableActionsEnum.RowClick)}>
                {selectEnabled && (
                  <td>
                    <GInputCheckbox name={`item-select-${item.id}`} disableForm />
                  </td>
                )}

                {rows.map((row) => {
                  if (row.useComponent) {
                    return <td key={row.key}>{row.useComponent(item)}</td>;
                  }
                  return <td key={row.key}>{row.key ? item[row.key] : ''}</td>;
                })}

                {actions && (
                  <td>
                    <div className="d-flex">
                      {possibleActions
                        .filter((pAction) => actions.includes(pAction.actionEnum))
                        .map((pAction) => (
                          <Tooltip key={pAction.actionEnum} title={pAction.label}>
                            <IconButton onClick={() => onExecuteAction?.(item, pAction.actionEnum)}>{pAction.icon}</IconButton>
                          </Tooltip>
                        ))}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    ),
    [selectEnabled, headers, data, rows, actions]
  );

  const loadingElement = (
    <LoadingContainer>
      <GLoading />
    </LoadingContainer>
  );

  const tableRender = data.length ? tableElement : <h6 className="d-flex justify-content-center text-dark p-5">No records found!</h6>;

  return loading ? loadingElement : tableRender;
}
