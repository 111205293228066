import React from 'react';

import FundCabotValueDocument from './FundCabotValueDocument';

export enum DocumentTemplateCodeEnum {
  CabotValueDocument = 'cabotValue'
}

export const DocumentTemplates = [
  {
    name: DocumentTemplateCodeEnum.CabotValueDocument,
    label: 'Cabot Value Document Template'
  }
];

export function GetDocumentTemplateByCode(code: DocumentTemplateCodeEnum) {
  switch (code) {
    case DocumentTemplateCodeEnum.CabotValueDocument:
      return <FundCabotValueDocument />;

    default:
      return <></>;
  }
}
