import { Button } from '@material-ui/core';
import AppPagesEnum from 'pages/pages.enum';
import { OpenModeEnum, useApp } from 'providers/app.provider';
import React from 'react';
import { FaRegCheckSquare, FaRegEdit } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import useFundDocumentService from 'services/fund-documents.service';
import { QuestionDialogAction } from 'shared/controls/GQuestionDialog';

import { useFundDocumentTemplate } from '../FundDocumentTemplatePage';
import FundDocumentFormDialog from './FundDocumentFormDialog';

export default function PageTitleActions() {
  const { push } = useHistory();
  const { fundDocument } = useFundDocumentTemplate();
  const { openDialog, openQuestionDialog, showSnackbar } = useApp();

  const FundDocumentService = useFundDocumentService();

  function enableTemplate() {
    if (fundDocument) {
      FundDocumentService.enableTemplate(fundDocument.id)
        .then(() => {
          showSnackbar?.({
            variant: 'success',
            message: 'The template was successfully enabled!'
          });

          push(AppPagesEnum.FundDocuments);
        })
        .catch(() =>
          showSnackbar?.({
            variant: 'error',
            message: 'The action could not be executed. Please, try again!'
          })
        );
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-between">
      <h5 className="fw-bold text-black">{fundDocument?.name} - Template</h5>

      <div className="d-flex align-items-start">
        <Button
          color="primary"
          startIcon={<FaRegEdit />}
          style={{ fontWeight: 600 }}
          className="me-3 px-3"
          onClick={() => openDialog?.(<FundDocumentFormDialog {...{ fundDocument, openMode: OpenModeEnum.Edit }} />)}
          disableElevation>
          Edit Template Details
        </Button>

        {!fundDocument?.active && (
          <Button
            color="primary"
            startIcon={<FaRegCheckSquare />}
            style={{ fontWeight: 600 }}
            onClick={() =>
              openQuestionDialog?.({
                title: 'Enable Template?',
                message: 'The other templates related to this fund will be automatically disabled. Do you want to proceed?',
                action: QuestionDialogAction.YES_NO
              }).then((res) => res && enableTemplate())}
            className="px-3"
            disableElevation>
            Enable Template
          </Button>
        )}
      </div>
    </div>
  );
}
