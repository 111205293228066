import { IUser } from 'models/user.model';
import { useApp } from 'providers/app.provider';
import { useAuth } from 'providers/auth.provider';
import React, { useCallback, useState } from 'react';
import GForm, { IValidateResult } from 'shared/controls/GForm';
import GInput from 'shared/controls/GInput';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';
import * as yup from 'yup';

const FormStepOneContainer = styled(GForm)`
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}
`;

export default function FormStepOne() {
  const { signUp } = useAuth();
  const { showSnackbar } = useApp();

  const [inputPasswordType, setInputPasswordType] = useState<React.HTMLInputTypeAttribute>('password');

  const onSubmit = useCallback(({ data }: IValidateResult<IUser>) => {
    signUp?.(data)
      ?.then(() => {
        showSnackbar?.({
          variant: 'success',
          message: 'Your account was successful created!'
        });
      })
      .catch((error) => {
        showSnackbar?.({
          variant: 'error',
          message: error?.response?.data?.message
        });
      });
  }, []);

  const validationSchema = yup.object().shape({
    name: yup.string().required('You should provide your complete name'),
    email: yup.string().required('Email is required').email('Provide a valid email'),
    password: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
        'The password must contain at least 8 characters, one uppercase, one lowercase, one number and a special character.'
      ),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords do not match')
  });

  const togglePasswordVisibility = useCallback(() => {
    setInputPasswordType((prev) => (prev === 'text' ? 'password' : 'text'));
  }, []);

  return (
    <FormStepOneContainer onValidate={onSubmit} validationSchema={validationSchema}>
      <GInput type="text" name="name" className="mb-4" label="Your Name" placeholder="Provide your complete name" />

      <GInput type="email" name="email" className="mb-4" label="Your Email" placeholder="name@domain.com" />

      <GInput type={inputPasswordType} name="password" className="mb-2" label="Password" placeholder="Use 8 characters or more" />

      <div className="form-check mb-4">
        <input className="form-check-input" type="checkbox" checked={inputPasswordType === 'text'} onChange={togglePasswordVisibility} />
        <label className="form-check-label">Show password</label>
      </div>

      <GInput type="password" name="confirmPassword" className="mb-5" label="Confirm Password" placeholder="Passwords must match" />

      <div className="row">
        <div className="col-md-6">
          <button type="submit" className="btn btn-primary py-2 w-100 text-white">
            Create Account
          </button>
        </div>
      </div>
    </FormStepOneContainer>
  );
}
