import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';

export interface InputAutocompleteProps
  extends InputHTMLAttributes<HTMLInputElement> {}

export default function Checkbox(props: InputAutocompleteProps) {
  const { name, ...rest } = props;

  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name as string);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked'
    });
  }, [fieldName, registerField]);

  return <input ref={inputRef} type="checkbox" {...rest} />;
}
