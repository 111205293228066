import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';
import ReactSelect, { Props as SelectProps } from 'react-select';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const GInputSelectContainer = styled.div`
  position: relative;

  .react-select {
    &__value-container {
      padding: 2px 12px;
    }

    &__control {
      @include font-body-small;
      background-color: transparent;
      background-color: ${({ theme }: IAppTheme) => theme.colors.input};
      border-radius: 6px;
      border: none;
      color: ${({ theme }: IAppTheme) => theme.colors.label};
      height: 44px;

      &::placeholder {
        color: $placeholder;
        color: ${({ theme }: IAppTheme) => theme.mixins.desktopTextXSmall};
        font-weight: 400;
        opacity: 1;
      }
    }
  }

  label {
    display: block;
  }

  .form-label {
    color: $label;
    color: ${({ theme }: IAppTheme) => theme.mixins.desktopTextSmall};
  }

  .invalid-feedback {
    display: block;
  }
`;

export interface GInputSelectProps extends SelectProps<any> {
  label?: string;
  valueKey?: string;
}

export default function GInputSelect(props: GInputSelectProps) {
  const { name, className, label, valueKey, options, ...rest } = props;

  const selectRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name as string);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.selectValue) {
            return [];
          }

          return ref.state.selectValue;
        }

        return ref?.state?.selectValue?.[0];
      }
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <GInputSelectContainer className={`${className ?? ''}`}>
      {label && <label className="form-label">{label}</label>}

      <ReactSelect
        {...{
          ...rest,
          ref: selectRef,
          defaultValue,
          options,
          classNamePrefix: 'react-select'
        }}
      />

      {error && <div className="invalid-feedback">{error}</div>}
    </GInputSelectContainer>
  );
}
