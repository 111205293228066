enum AppPagesEnum {
  Login = '/login',
  SignUpSubscriber = '/sign-up-subscriber',
  ChangePassword = '/change-password',
  ForgotPassword = '/forgot-password',
  Dashboard = '/dashboard',
  Funds = '/funds',
  FundDetails = '/funds/details',
  FundCommitment = '/funds/commitment',
  FundIssues = '/funds/issues',
  Subscribers = '/subscribers',
  SubscriberDetails = '/subscriber/details',
  MySubscriptions = '/my-subscriptions',
  SubscriptionDetails = '/subscription',
  Users = '/users',
  UserAccount = '/user/account',
  FundDocuments = '/documents',
  FundDocumentTemplate = '/document-template',
  FundDocumentAnswer = '/document-answer'
}

export default AppPagesEnum;
