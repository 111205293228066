import $ from 'jquery';
import { ISubscription, SubscriptionStatus } from 'models/subscription.model';
import moment from 'moment';
import AppPagesEnum from 'pages/pages.enum';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const HeaderTable = styled.div`
  tr td {
    padding: 3px 20px 3px 0px;
  }
`;

type SubscriberHeaderProps = {
  subscription: ISubscription;
  issues: string[];
};

export default function SubscriberHeader({ subscription, issues }: SubscriberHeaderProps) {
  const { push } = useHistory();

  React.useEffect(() => {
    $('.input-container').css('color', 'black');

    issues.forEach((issue) => {
      $(`#${issue}`).css('color', 'red');
    });
  }, [issues]);

  const subscriptionStatus = React.useMemo(() => {
    if (subscription?.status === SubscriptionStatus.CREATED) {
      return <span className="badge bg-primary mx-2">Initiated</span>;
    }

    if (subscription?.status === SubscriptionStatus.IN_PROGRESS) {
      return <span className="badge bg-primary mx-2">Under Evaluation</span>;
    }

    if (subscription?.status === SubscriptionStatus.WITH_ISSUES) {
      return <span className="badge bg-danger mx-2">With Issues</span>;
    }

    return <span className="badge bg-success mx-2">Completed</span>;
  }, [subscription]);

  return (
    <div>
      <HeaderTable>
        <tr>
          <td>
            <strong>Subscription Amount:</strong>
          </td>
          <td>${subscription?.totalAmount.toFixed(2)}</td>
        </tr>
        <tr>
          <td>
            <strong>Subscription Status:</strong>
          </td>
          <td>{subscriptionStatus}</td>
        </tr>
      </HeaderTable>

      <br />

      <p>
        <small>Subscription held in {moment(subscription?.createdAt).format('MM-DD-YYYY HH:mm')}</small>
      </p>

      <br />

      {subscription?.reviewMessage && (
        <div>
          <label className="mb-2">
            <strong>Subscription review message:</strong>{' '}
          </label>
          <p className="pe-3">
            <i>{subscription?.reviewMessage}</i>
          </p>
        </div>
      )}

      {subscription.status === SubscriptionStatus.WITH_ISSUES && (
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => push({ pathname: AppPagesEnum.FundDocumentAnswer, state: { subscriptionId: subscription.id } })}>
            Update Subscription
          </button>
        </div>
      )}

      <hr />
    </div>
  );
}
