import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';

export interface InputAutocompleteProps {
  name: string;
  options: string[];
}

export default function Radio({ name, options }: InputAutocompleteProps) {
  const inputRefs = useRef([]);

  const { fieldName, registerField, defaultValue } = useField(name as string);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      getValue(refs: any) {
        const checked = refs.find((ref: any) => ref.checked);
        return checked?.value ?? null;
      },
      setValue(refs: any, value: any) {
        const item = refs.find((ref: any) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      }
    } as any);
  }, [fieldName, registerField]);

  return (
    <span>
      {options.map((option, index) => (
        <label key={option} className="mx-2">
          <input
            ref={(elRef: any) => {
              (inputRefs.current[index] as any) = elRef;
            }}
            type="radio"
            name={fieldName}
            value={option}
            className="form-check-input me-2"
            defaultChecked={defaultValue === option}
          />
          <span>{option}</span>
        </label>
      ))}
    </span>
  );
}
