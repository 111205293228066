import { IUser } from 'models/user.model';
import { useApp } from 'providers/app.provider';
import { UserRole } from 'providers/auth.provider';
import React, { useCallback, useState } from 'react';
import useUserService from 'services/user.service';
import GForm, { IValidateResult } from 'shared/controls/GForm';
import GInput from 'shared/controls/GInput';
import GInputSelect from 'shared/controls/GInputSelect';
import GLoading from 'shared/controls/GLoading';
import Title from 'shared/styled/Title';
import styled from 'styled-components';

const AddUserFormContainer = styled(GForm)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 4rem;
  width: 600px;

  .btn-actions {
    display: flex;
    justify-content: center;
  }
`;

export default function AddUserForm() {
  const { closeDialog, showSnackbar } = useApp();

  const UserService = useUserService();

  const [loading, setLoading] = useState(false);

  const createUser = useCallback(({ data }: IValidateResult<IUser>) => {
    setLoading(true);

    const { role, ...userData } = data;

    UserService.createNew({ ...userData, role: (role as any).name })
      .then(() => {
        showSnackbar?.({
          variant: 'success',
          message: 'User created successfully!'
        });

        closeDialog?.();
      })
      .catch(() =>
        showSnackbar?.({
          variant: 'error',
          message: 'The action could not be executed. Please, try again.'
        })
      )
      .finally(() => setLoading(false));
  }, []);

  const roleOptions = React.useMemo(() => {
    return [UserRole.Admin, UserRole.General].map((role) => ({ name: role.toLocaleLowerCase(), label: role }));
  }, []);

  return (
    <AddUserFormContainer onValidate={createUser}>
      <Title className="mb-5 w-100 text-center">Create New User</Title>

      <GInput name="name" label="Full Name" placeholder="User full name" className="mb-3 w-100" />

      <GInput name="email" label="Email" placeholder="User email" className="mb-3 w-100" />

      <GInputSelect name="role" label="Role" options={roleOptions} className="mb-4 w-100 text-capitalize" />

      <small className="alert alert-secondary mt-3 w-100" role="alert">
        <strong>Main Roles</strong> <br />
        Admin - Can perform all actions in the system <br />
        General - Manage funds and subscribers
      </small>

      <small className="alert alert-info mt-3 w-100" role="alert">
        Credentials will be sent later by email.
      </small>

      <br />

      {loading && <GLoading />}

      <div className="btn-actions mt-3">
        <button type="submit" className="btn btn-primary px-5 py-3 me-3">
          Save
        </button>
        <button type="button" className="btn btn-light px-5 py-3" onClick={() => closeDialog?.()}>
          Cancel
        </button>
      </div>
    </AddUserFormContainer>
  );
}
