import { loadVGSCollect } from '@vgs/collect-js';

export default async function generateVGSForm() {
  const VGSCollect: any = await loadVGSCollect({
    vaultId: process.env.REACT_APP_VGS_VAULT_ID || '',
    environment: process.env.REACT_APP_VGS_ENVIRONMENT || 'sandbox',
    version: '2.12.0'
  }).catch((e) => {
    console.log(e);
  });

  return VGSCollect?.init?.(() => {});
}
