import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const Title = styled.h2`
  ${({ theme }: IAppTheme) => theme.mixins.desktopDisplayXSmall};
  color: ${({ theme }: IAppTheme) => theme.colors.offBlack};
  font-weight: bold;
  margin-bottom: 2rem;
`;

export default Title;
