import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const TopBarLabelContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    color: ${({ theme }: IAppTheme) => theme.colors.offBlack};
  }

  .current-item {
    color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
  }
`;

export default TopBarLabelContainer;
