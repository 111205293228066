import React from 'react';
import Checkbox from 'shared/controls/Checkbox';
import GInput from 'shared/controls/GInput';
import Radio from 'shared/controls/Radio';
import styled from 'styled-components';

const FundCabotValueDocumentContainer = styled.div`
  overflow-y: auto;
  padding: 3rem;
  font-size: 16px;
  max-width: 100%;

  p {
    text-align: justify;
  }

  ol li,
  ul li {
    padding: 5px 0px;
    text-align: justify;
  }

  hr {
    color: #4e4b66;
  }

  table {
    tr td {
      vertical-align: top;
      padding: 10px 15px;
    }
  }

  .text-small {
    font-size: 0.9rem;
  }

  .bg-gray {
    background-color: lightgray;
  }

  .vgs-input-container {
    font-weight: bold;
    width: 100%;
  }
`;

export default function FundCabotValueDocument() {
  return (
    <FundCabotValueDocumentContainer>
      <div className="d-flex flex-column align-items-center">
        <h3>CABOT INDUSTRIAL CORE FUND III, L.P.</h3>
        <h4>(A Delaware Limited Partnership)</h4>

        <hr className="w-100" />

        <h4>SUBSCRIPTION DOCUMENTS</h4>

        <br />
        <br />
        <br />

        <h4>CABOT INDUSTRIAL CORE FUND III, L.P.</h4>
        <h5>
          <u>SUBSCRIPTION INSTRUCTIONS</u>
        </h5>
        <br />
      </div>
      <p>Please find the following materials (the “Subscription Documents”) that are enclosed with or attached to these Subscription Instructions:</p>
      <ol>
        <li>
          Amended and Restated Limited Partnership Agreement (the “Partnership Agreement”) of Cabot Industrial Core Fund III, L.P. (the
          “Partnership”);
        </li>
        <li>Limited Partner Signature Page to the Partnership Agreement;</li>
        <li>Subscription Agreement of the Partnership (the “Subscription Agreement”);</li>
        <li>Privacy Policy, attached as Attachment (K); and</li>
        <li>IRS Form W-9.</li>
      </ol>
      <p>
        To subscribe for a limited partnership interest in the Partnership, a prospective investor (the “Subscriber”) must review each of the
        Subscription Documents and complete and return certain of the Subscription Documents as instructed below.
      </p>
      <ol>
        <li>Sign and date of the Limited Partner Signature Page to the Partnership Agreement, which page follows these Subscription Instructions.</li>
        <li>Read the Subscription Agreement in its entirety and complete the attachments as described herein.</li>
        <li>
          If the Subscriber is an entity or a natural person and is a United States person as defined in Section 7701(a)(30) of the Internal Revenue
          Code of 1986, as amended (the “Code”) (a “United States Person”), complete the enclosed IRS Form W-9.
          <br /> <br />
          If the Subscriber is an entity disregarded as separate from its owner for U.S. federal income tax purposes (a “Disregarded Entity”) and the
          first direct or indirect beneficial owner of the Subscriber that is not a Disregarded Entity (the “Subscriber’s Owner”) is a United States
          Person, have the Subscriber’s Owner complete and submit an IRS Form W-9 and have the Subscriber complete and submit an additional IRS Form
          W-9 in accordance with the instructions thereto.
          <br /> <br />
          If the Subscriber or the Subscriber’s Owner is a United States Person and is also a grantor trust for U.S. federal income tax purposes, then
          (i) have each of the Subscriber and the Subscriber’s Owner, if applicable, complete and submit an IRS Form W-9, and (ii) have each of the
          grantor trust’s grantors or other owners complete and submit an IRS Form W-9 or appropriate Internal Revenue Service Form W-8 (“IRS Form
          W-8”) (together with any additional documentation required in connection therewith), as applicable.
        </li>
        <li>
          If the Subscriber or the Subscriber’s Owner (as applicable) is an entity or a natural person and is not a United States Person, complete or
          have the Subscriber’s Owner complete (as applicable) the appropriate IRS Form W-8, which can be obtained at the Internal Revenue Service
          website at www.irs.gov, and submit with the completed IRS Form W-8 any additional documentation required in connection therewith. If the
          Subscriber or the Subscriber’s Owner (as applicable) is a “qualified foreign pension fund” (“QFPF”) as defined in Section 897(l) of the Code
          (or a wholly-owned subsidiary of a QFPF) , complete the enclosed FIRPTA Certificate included as Attachment (I) Part A in addition to the IRS
          Form W-8.
        </li>
        <li>Sign and date the Subscriber signature page to the Subscription Agreement.</li>
        <li>
          Email one (1) complete set of the foregoing documents to both of:
          <br /> <br />
          Cabot Properties, Inc. <br />
          One Beacon Street, Suite 2800 <br />
          Boston, MA 02108 <br />
          Attention: Kathleen Reardon, Senior Vice President <br />
          Email: KReardon@CabotProp.com <br />
          <br />
          <strong>and</strong> <br />
          <br />
          Goodwin Procter LLP <br />
          100 Northern Avenue <br />
          Boston, MA 02109 <br />
          Attention: Margo Borders <br />
          MBorders@goodwinlaw.com <br />
          <br />
          <p className="fw-bold">
            <i>
              NOTE: If the Subscriber is a natural person who is a resident of Massachusetts, then documents should only be sent to Cabot Properties,
              Inc. and <u>NOT</u> Goodwin Procter LLP.
            </i>
          </p>
        </li>
      </ol>
      <p>
        If you have any questions concerning the completion of the Subscription Documents, please contact Margo Borders at 617-570-1719 or
        MBorders@goodwinlaw.com.
      </p>
      <br />
      <h4 className="text-center">CABOT INDUSTRIAL CORE FUND III, L.P.</h4>
      <h4 className="text-center">Limited Partner Signature Page</h4>
      <br />
      <p>
        The undersigned, desiring to become a Limited Partner of Cabot Industrial Core Fund III, L.P., a Delaware limited partnership (the
        “Partnership”), hereby becomes a party to the Amended and Restated Limited Partnership Agreement of the Partnership, as amended and/or
        restated (the “Partnership Agreement”), to which Cabot Industrial Core Fund III Manager, Limited Partnership, a Massachusetts limited
        partnership, is a party as the General Partner. The undersigned hereby agrees to all the provisions of the Partnership Agreement, and agrees
        that this signature page may be attached to any counterpart copy of the Partnership Agreement.
      </p>
      <h5>LIMITED PARTNER:</h5>
      <br />
      <table className="w-100">
        <tr>
          <td>
            <strong>
              For <u>Natural Persons:</u>
            </strong>
          </td>
          <td>
            <strong>
              For <u>Entities:</u>
            </strong>
          </td>
        </tr>
        <tr>
          <td>
            <div id="limitedPartnerNaturalPersonName" className="vgs-input-container text-center">
              limitedPartnerNaturalPersonName
            </div>
          </td>
          <td>
            <div id="limitedPartnerEntityName" className="vgs-input-container text-center">
              limitedPartnerEntityName
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <p className="text-center">(Name of Limited Partner)</p>
          </td>
          <td>
            <p className="text-center">(Name of Limited Partner)</p>
          </td>
        </tr>
        <tr>
          <td>
            <div id="limitedPartnerNaturalPersonSignature" className="vgs-input-container text-center">
              limitedPartnerNaturalPersonSignature
            </div>
          </td>
          <td rowSpan={4}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <span className="me-3">By:</span>
                  </td>
                  <td>
                    <span id="limitedPartnerEntityBy" className="vgs-input-container">
                      limitedPartnerEntityBy
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="me-3">Name:</span>
                  </td>
                  <td>
                    <span id="limitedPartnerEntityByName" className="vgs-input-container">
                      limitedPartnerEntityByName
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="me-3">Title:</span>
                  </td>
                  <td>
                    <span id="limitedPartnerEntityByTitle" className="vgs-input-container">
                      limitedPartnerEntityByTitle
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="me-3">Dated:</span>
                  </td>
                  <td>
                    <span id="limitedPartnerEntityDated" className="vgs-input-container">
                      limitedPartnerEntityDated
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <p className="text-center">(Signature of Limited Partner)</p>
          </td>
          <td />
        </tr>
        <tr>
          <td>
            <div id="limitedPartnerNaturalPersonAdditionalSignature" className="vgs-input-container text-center">
              limitedPartnerNaturalPersonAdditionalSignature
            </div>
          </td>
          <td className="d-flex align-items-center" />
        </tr>
        <tr>
          <td>
            <p className="text-center">(Additional Signature, If Applicable)</p>
          </td>
          <td />
        </tr>
        <tr>
          <td className="d-flex align-items-center">
            <span className="me-3">Dated:</span>
            <span id="limitedPartnerNaturalPersonDated" className="vgs-input-container">
              limitedPartnerNaturalPersonDated
            </span>
          </td>
        </tr>
      </table>
      <br />
      <br />
      <h4 className="text-center">CABOT INDUSTRIAL CORE FUND III, L.P.</h4>
      <h5 className="text-center">
        <u>SUBSCRIPTION AGREEMENT</u>
      </h5>
      <br />
      <p>
        The undersigned (the “<u>Subscriber</u>”) hereby agrees with Cabot Industrial Core Fund III, L.P., a Delaware limited partnership (the “
        <u>Partnership</u>”), as set forth below. Capitalized terms not otherwise defined herein shall have the respective meanings set forth in the
        Amended and Restated Limited Partnership Agreement of the Partnership, as amended and/or restated and in effect from time to time (the “
        <u>Partnership Agreement</u>”). Unless the context otherwise requires, references herein to “person” include both a natural person and an
        entity. If the Subscriber is a trustee subscribing on behalf of a trust, such as an IRA, a Keogh plan or another type of trust, and has so
        indicated on its signature page hereto and specified the type of trust on behalf of which the Subscriber is subscribing, references in this
        Subscription Agreement to the “Subscriber” are intended to refer to the trust estate rather than to the trustee individually unless the
        context otherwise requires.
      </p>
      <ol>
        <li>
          <strong>
            <u>Subscription.</u>
          </strong>
          &nbsp; The Subscriber hereby subscribes for a limited partnership interest in the Partnership (the “Interest”) in accordance with and
          subject to the terms, provisions and conditions set forth herein and in the Partnership Agreement and, in connection therewith, agrees to
          become a limited partner (a “Limited Partner”) of the Partnership subject to acceptance of this Subscription Agreement.
        </li>
        <li>
          <strong>
            <u>Acceptance/Rejection of Subscription; Payment.</u>
          </strong>
          &nbsp; Acceptance of this Subscription Agreement shall be by the execution of this Subscription Agreement by the General Partner on behalf
          of the Partnership. The Subscriber understands that the General Partner reserves the right to reject the subscription evidenced by this
          Subscription Agreement for any reason whatsoever (if accepted, the subscription amount in Attachment (A) is referred to herein as the
          Subscriber’s “Subscription Amount”). In the event that this Subscription Agreement is rejected by the Partnership, the subscription of the
          Subscriber herein shall become null and void. Upon such rejection, the Subscriber shall have no further obligations to the Partnership,
          except for such obligations of confidentiality as the Subscriber has agreed to by acceptance of the Memorandum (as defined below). If the
          subscription evidenced by this Subscription Agreement is accepted by the General Partner, the Subscriber shall be admitted to the
          Partnership as a Limited Partner on the date established by the Partnership (the “Admission Date”). The Subscriber agrees to pay its
          Subscription Amount at the times and in the manner set forth in the Partnership Agreement. The Subscriber understands and agrees that,
          except as otherwise provided in the Partnership Agreement, the Subscriber may not make less than the full amount of any capital contribution
          required under the Partnership Agreement, and that default provisions with respect thereto, pursuant to which the Subscriber may suffer
          substantial adverse consequences are contained in the Partnership Agreement.
        </li>
        <li>
          <strong>
            <u>Acknowledgements, Representations, Warranties and Agreements.</u>
          </strong>
          &nbsp; The Subscriber acknowledges, represents, warrants and agrees that the following statements are true as of the date hereof and will be
          true as of the date the Partnership admits the Subscriber to the Partnership as a Limited Partner, if ever, and on each date on which the
          Subscriber makes any capital contributions to the Partnership:
          <ol type="a">
            <li>
              <u>
                <i>Subscriber Information.</i>
              </u>
              &nbsp; Subscriber Information. The Subscriber information specified by the Subscriber on Attachment (A) hereto is complete and correct.
            </li>
            <li>
              <u>
                <i>Subscriber’s Diligence.</i>
              </u>
              &nbsp; The Subscriber has been furnished with, and has carefully read (i) the Confidential Private Placement Memorandum relating to the
              Partnership (as supplemented from time to time, the “Memorandum”), which contains certain important information concerning an investment
              in the Partnership, including certain risk factors, conflicts of interest, tax considerations and ERISA considerations and (ii) the
              Partnership Agreement (collectively, the “Offering Materials”), and the Subscriber acknowledges and agrees that the Offering Materials
              supersede any other offering materials previously made available to prospective investors. In considering his, her or its subscription,
              the Subscriber has been given the opportunity to (a) make a thorough investigation of the current and proposed activities of the
              Partnership, has been furnished with all materials relating to the Partnership and its proposed activities that the Subscriber has
              requested, and has been afforded the opportunity to obtain any additional information necessary to verify the accuracy of any
              representations made or information conveyed to the Subscriber, and (b) ask questions of, and receive answers from, the General Partner
              concerning the terms and conditions and other matters pertaining to an investment in the Partnership. In considering his, her or its
              subscription, the Subscriber has not relied upon any representations made by, or other information (whether oral or written) furnished
              by or on behalf of, the Partnership, any placement agent (if applicable), or any partners, member, manager, director, officer, employee
              or agent of the Partnership or any Affiliate of such persons, other than as set forth in the Offering Materials and this Subscription
              Agreement. The Subscriber recognizes that an investment in the Partnership involves certain risks and the Subscriber understands and
              accepts such risks. The Subscriber has carefully considered and has, to the extent he, she or it believes such discussion necessary,
              discussed with legal, tax, accounting, regulatory and financial advisers the suitability and potential risks of the subscription in
              light of his, her or its particular tax and financial situation, and has determined that the Interest is a suitable investment for him,
              her or it.
            </li>
            <li>
              <u>
                <i>Subscriber’s Sophistication and Location of Investment Decision.</i>
              </u>
              &nbsp; The Subscriber (either alone or together with any advisors retained by such Subscriber in connection with evaluating the merits
              and risks of the prospective investment in the Partnership) has sufficient knowledge and experience in financial and business matters so
              as to be capable of evaluating the merits and risks of subscribing for the Interest, and is able to bear the economic risk of its
              investment in the Partnership for an indefinite period of time, including a complete loss of capital. The Subscriber’s decision to
              invest in the Partnership was made in the state indicated in Attachment (A) hereto as the address of the Subscriber’s primary contact.
            </li>
            <li>
              <u>
                <i>Subscriber Not an Advisory Client.</i>
              </u>
              &nbsp; The Subscriber acknowledges that: (a) neither Cabot Properties L.P. (the “Manager”) nor any of its Affiliates nor any of their
              respective partners, members, managers, officers, employees or agents (each, a “Manager Party”) has acted for the Subscriber or advised
              the Subscriber in connection with the Subscriber’s subscription for the Interest; (b) accordingly, no Manager Party is responsible for
              providing the Subscriber with the protections afforded to clients of any Manager Party in connection with the Subscriber’s subscription
              for the Interest; (c) no advisory services will be provided to the Subscriber and no duties will be owed to the Subscriber except in the
              Subscriber’s capacity as a Limited Partner of the Partnership; and (d) the Subscriber has taken such advice from such other persons (if
              any) as the Subscriber considers appropriate. The Subscriber further acknowledges that Goodwin Procter LLP represents only the
              Partnership, the General Partner and the Manager, and not the Subscriber, in connection with the formation of the Partnership and the
              offer and sale of the Interest, and that the Subscriber should consult his, her or its own legal and tax advisors in connection
              therewith.
            </li>
            <li>
              <u>
                <i>Subscription for Investment Only.</i>
              </u>
              &nbsp; The Interest is being subscribed to for the Subscriber’s own account and not for the account of any other person, for investment
              only and not with a view to, or with any intention of, a distribution or resale thereof, in whole or in part, or the grant of any
              participation therein.
            </li>
            <li>
              <u>
                <i>No Derivative Transactions.</i>
              </u>
              &nbsp; Unless otherwise agreed in a separate writing by the General Partner, the Subscriber has not entered and will not enter into: (i)
              a swap, structured note or other derivative instrument with a third party, the return from which is based in whole or in part on the
              return of the Partnership; (ii) a variable annuity or insurance policy with a third party, the value of which is based in whole or in
              part on the return of the Partnership; or (iii) any other hedging transaction involving the Interest.
            </li>
            <li>
              <u>
                <i>Employee Benefit Plan Status.</i>
              </u>
              &nbsp; The Subscriber understands that because it is the intent of the Partnership to operate in a manner such that the assets of the
              Partnership will not be “plan assets” subject to the provisions of Part 4 of Subtitle B of Title I of the U.S. Employee Retirement
              Income Security Act of 1974, as amended (“ERISA”), the Subscriber must provide the Partnership with information necessary for the
              General Partner to determine whether the Partnership will be subject to the provisions of ERISA. In this regard, the Subscriber, if an
              entity, makes the representations and warranties contained in &nbsp;
              <u>
                Attachment
                <strong> Error! Reference source not found.</strong>
              </u>
            </li>
            <li>
              <u>
                <i>No Advisory Relationship to Partnership.</i>
              </u>
              &nbsp; Neither the Subscriber nor any of its Affiliates (i) has discretionary authority or control, or otherwise provides investment
              advice, with respect to the assets of the Partnership or (ii) provides investment advice (direct or indirect) with respect to the assets
              of the Partnership.
            </li>
            <li>
              <u>
                <i>Not a Registered Offering; Accredited Investor Status.</i>
              </u>
              &nbsp; The Subscriber is fully aware that the offering and sale of limited partnership interests in the Partnership, including the
              Interest being acquired by the Subscriber, have not been and will not be registered under the U.S. Securities Act of 1933, as amended
              (the “Securities
            </li>
            <li>
              <u>
                <i>Restrictions on Transfer.</i>
              </u>
              &nbsp; The Subscriber understands that the Interest cannot be resold or transferred unless it is subsequently registered under the
              Securities Act and the applicable laws of any states or other jurisdictions, or unless an exemption from such registration is available.
              The Subscriber also understands that any transfer of the Interest, or any part thereof, is subject to certain restrictions set forth in
              the Partnership Agreement, including without limitation, approval by the General Partner. The Subscriber understands that the Interest
              will not be evidenced by a certificate subject to Article 8 of the Uniform Commercial Code. The Subscriber shall not engage in any
              hedging transactions involving the Interest.
            </li>
            <li>
              <u>
                <i>Investment Company Act Representations; Qualified Purchaser Status.</i>
              </u>
              &nbsp; The Subscriber acknowledges that the Partnership has not been and will not be registered as an investment company under the U.S.
              Investment Company Act of 1940, as amended (the &quot;
              <u>Investment Company Act</u>
              &quot;). In furtherance thereof, the Subscriber hereby makes the representations and warranties contained in <u>Attachment (E)</u>.
            </li>
            <li>
              <u>
                <i>Independent Decision; Power and Authority; No Conflicts.</i>
              </u>
              &nbsp; If the Subscriber is an entity: (a) it was not formed or recapitalized (e.g., through new investments made in the Subscriber
              solely for the purpose of financing its acquisition of the Interest and not pursuant to a prior financial commitment) for the purpose of
              investing in the Partnership (or the Subscriber was formed or recapitalized for such purpose and has so indicated on Attachment (A); (b)
              its decision to purchase the Interest was made in a centralized fashion (e.g., by a board of directors, general partner, manager,
              trustee, investment committee or similar governing or managing body); (c) it is not managed to facilitate the individual decisions of
              its beneficial owners regarding investments (including the purchase of the Interest); (d) its shareholders, partners, members or
              beneficiaries, as applicable, did not and will not (i) have any discretion to determine whether or how much of the Subscriber&apos;s
              assets are invested in any investment made by the Subscriber (including the Subscriber&apos;s purchase of the Interest), or (ii) have
              the ability individually to elect whether or to what extent such shareholder, partner, member or beneficiary, as applicable, will
              participate in the Subscriber&apos;s purchase of the Interest; (e) it is duly organized or formed, validly existing and in good standing
              under the laws of its jurisdiction of organization or formation; (f) the execution, delivery and performance by it of this Subscription
              Agreement and the Partnership Agreement are within its powers, have been duly authorized by all necessary corporate or other action on
              its behalf, require no action by or in respect of, or filing with, any governmental body, agency or official, except as has been
              previously obtained and is in full force and effect, and do not and will not contravene, or constitute a default under, any provision of
              applicable law or regulation or of its certificate of incorporation or other comparable organizational documents or any agreement,
              judgment, injunction, order, decree or other instrument to which the Subscriber is a party or by which the Subscriber or any of its
              properties is bound; and (g) it has its principal place of business at the address set forth on Attachment (A) hereto.
            </li>
            <li>
              <u>
                <i>Power and Capacity; No Conflicts.</i>
              </u>
              &nbsp; If the Subscriber is a natural person, the execution, delivery and performance by the Subscriber of this Subscription Agreement
              and the Partnership Agreement are within the Subscriber&apos;s legal right, power and capacity, require no action by or in respect of,
              or filing with, any governmental body, agency or official (except as has been previously obtained and is in full force and effect), and
              do not and will not contravene, or constitute a default under, any provision of applicable law or regulation or of any agreement,
              judgment, injunction, order, decree or other instrument to which the Subscriber is a party or by which the Subscriber or any of his or
              her assets is bound.
            </li>
            <li>
              <u>
                <i>Due Execution; Binding Agreement.</i>
              </u>
              &nbsp; This Subscription Agreement and the Partnership Agreement have been duly executed and delivered by the Subscriber. This
              Subscription Agreement constitutes, and if the Subscriber is accepted as a limited partner of the Partnership, then the Partnership
              Agreement will constitute, valid and binding agreements of the Subscriber, enforceable against the Subscriber in accordance with their
              respective terms.
            </li>
            <li>
              <u>
                <i>Pass-Through Entity. </i>
              </u>
              &nbsp; If the Subscriber is a partnership, grantor trust, S corporation or other entity treated as a pass-through entity for U.S.
              federal tax purposes (a &quot;Pass-Through Entity&quot;): (i) at no time will 50% or more of the value of any beneficial owner&apos;s
              direct or indirect interest in the Subscriber be attributable to the Subscriber&apos;s interest in the Partnership; (ii) at no time will
              50% or more of the Subscriber&apos;s value be attributable to the Subscriber&apos;s interest in the Partnership; and (iii) the
              Subscriber&apos;s beneficial owners are not investing in the Partnership through a Pass-Through Entity with a principal purpose of
              permitting the Partnership to satisfy the 100-partner limitation set forth in Treasury Regulations Section 1.7704-1(h) (regarding the
              private placement safe harbor from treatment as a publicly traded partnership). In addition, the Subscriber understands that the
              Partnership is not intended to be treated as a publicly traded partnership taxable as a corporation under the rules of Section 7704 of
              the Internal Revenue Code of 1986, as amended (the &quot;Code&quot;). The Subscriber hereby covenants and agrees that the Subscriber (x)
              is not currently making a market in its Interest and (y) will not transfer its Interest on an established securities market or a
              secondary market (or the substantial equivalent thereof) within the meaning of Sections 469(k)(2) and 7704(b) of the Code (and any
              Treasury Regulations, revenue rulings, or other official pronouncements of the Internal Revenue Service or the Treasury Department
              promulgated or published thereunder). If the Subscriber is a Disregarded Entity and Subscriber&apos;s Owner is a Pass-Through Entity,
              the Subscriber represents and warrants that the representations in this Section 3(o) would be true if all references to &quot;the
              Subscriber&quot; were replaced with &quot;the Subscriber&apos;s Owner.&quot;
            </li>
            <li>
              <u>
                <i>Privacy Rule.</i>
              </u>
              &nbsp; If the Subscriber is a natural person, he or she acknowledges receipt of the Privacy Policy attached as Attachment (K) regarding
              privacy of financial information under the U.S. Federal Trade Commission privacy rule, 16 C.F.R. Part 313, and agrees that the Interest
              is a financial product that the Subscriber has requested and authorized.
            </li>
            <li>
              <u>
                <i>Tax Owner.</i>
              </u>
              &nbsp; The Subscriber represents and warrants that the Subscriber will be the beneficial owner of the Interest to be acquired pursuant
              to this Subscription Agreement and is not acquiring the Interest on behalf of or as nominee for another person. If the Subscriber is an
              entity, the Subscriber makes the representations and warranties contained in Attachment (E).
            </li>
            <li>
              <u>
                <i>Information Relevant to Subsidiary REITs.</i>
              </u>
              &nbsp; The Subscriber makes the representations, warranties and covenants contained in Attachment (F) and certifies that the information
              contained in Attachment (F) is accurate and complete.
            </li>
            <li>
              <u>
                <i>Information for UBTI Reporting.</i>
              </u>
              &nbsp; If the Subscriber is an entity, the Subscriber makes the representations and warranties contained in Attachment (G).
            </li>
            <li>
              <u>
                <i>Domestic Grantor Trust Certification.</i>
              </u>
              &nbsp; If Subscriber is an entity, by signing below the undersigned, under penalty of perjury, declares that the undersigned has
              examined and properly completed Attachment (H) (the &quot;Domestic Grantor Trust Certification&quot;) and, to the best of the knowledge
              and belief of the undersigned, the Domestic Grantor Trust Certification is true, correct, and complete. Further, the undersigned
              declares, under penalty of perjury, that the undersigned has authority to sign this document on behalf of the Subscriber (or the
              Subscriber&apos;s Owner, as applicable).
            </li>
            <li>
              <u>
                <i>Rule 506(d) and Rule 506(e) Compliance.</i>
              </u>
              &nbsp; The Subscriber, after due inquiry, represents and warrants that unless the Subscriber has indicated &quot;Yes&quot; to Question
              10 on Attachment (J) and has make a separate written disclosure to the General Partner relating to the information requested by
              Attachment (J), neither the Subscriber, nor any person who for purposes of Rule 506(d) and Rule 506(e) (collectively, the &quot;Bad
              Actor Rule&quot;) of the Securities Act beneficially owns or will beneficially own the Subscriber&apos;s interest in the Partnership is
              subject to any conviction, order, judgment, decree, suspension, expulsion or bar described in the Bad Actor Rule, whether it occurred or
              was issued before, on or after September 23, 2013, and agrees that it will notify the Partnership immediately upon becoming aware that
              the foregoing is not, or is no longer, complete and accurate in every material respect, including as a result of events occurring after
              the date of this Subscription Agreement. In furtherance thereof, the Subscriber represents and warrants that it has accurately answered
              the questions on Attachment (J).
            </li>
            <li>
              <u>
                <i>Qualified Client.</i>
              </u>
              &nbsp; Qualified Client. The Subscriber is a &quot;qualified client,&quot; as defined in Rule 205-3 under the Investment Advisers Act of
              1940, as amended, and hereby makes the representations and warranties contained in Attachment (L).
            </li>
            <li>
              <u>
                <i>Non-U.S. Entity Status.</i>
              </u>
              &nbsp; If the Subscriber is a legal entity located outside of the United States, the Subscriber makes the representations and warranties
              contained in Attachment (M).
            </li>
          </ol>
        </li>
        <li>
          <p>
            <u>
              <strong>Representations of the Partnership and General Partner.</strong>
            </u>
            &nbsp; The General Partner, for itself and on behalf of the Partnership, hereby represents and warrants that, as of the Admission Date:
          </p>

          <ol type="a">
            <li>
              The Partnership is duly formed, validly existing and in good standing as a limited partnership under the Delaware Revised Uniform
              Limited Partnership Act, as amended (the &quot;Act&quot;), with all requisite power and authority under the Partnership Agreement and
              under the Act to conduct its business as described in the Partnership Agreement and to issue and sell the Interest in accordance with
              the terms of this Subscription Agreement. This Subscription Agreement (when accepted) will have been duly authorized, executed and
              delivered by the Partnership, and will be the legal, valid and binding obligation of the Partnership, enforceable in accordance with its
              terms.
            </li>
            <li>
              The General Partner is duly formed, validly existing and in good standing as a limited partnership under applicable law of The
              Commonwealth of Massachusetts (the &quot;Massachusetts Act&quot;), with all requisite power and authority under its limited partnership
              agreement and under the Massachusetts Act to conduct its business as described in such limited partnership agreement. The Partnership
              Agreement has been duly authorized, executed and delivered by the General Partner, and is the legal, valid and binding obligation of the
              General Partner, enforceable in accordance with its terms.
            </li>
            <li>
              The execution, delivery and performance by the Partnership of this Subscription Agreement (i) do not and will not violate any laws,
              rules or regulations of the United States or any state or other jurisdiction applicable to the Partnership, or require the Partnership
              to obtain any approval, consent or waiver of any Person that has not been obtained or made; and (ii) do not and will not result in a
              breach of, constitute a default under, accelerate any obligation under or give rise to a right of termination of any agreement to which
              the Partnership is a party.
            </li>
            <li>
              Assuming the accuracy and completeness of all of the representations made by each of the subscribers in their respective subscription
              agreements, pursuant to which they have subscribed for the limited partnership interests in the Partnership, the issuance and sale of
              the limited partnership interests in the Partnership are not required to be registered under the registration provisions of the
              Securities Act or any applicable state securities laws.
            </li>
            <li>
              There is no litigation or governmental or administrative proceeding or investigation pending or, to the Partnership&apos;s or the
              General Partner&apos;s actual knowledge, threatened against the Partnership or the General Partner.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <u>
              <strong> Tax Matters.</strong>
            </u>
          </p>
          <ol type="a">
            <li>
              <u>
                <i>Acknowledgment of Withholding.</i>
              </u>
              &nbsp; The Subscriber acknowledges and agrees that under U.S. federal tax law (including Sections 1441, 1442, 1445, 1446, 1471, 1472,
              1473 and 1474 of the Code), and possibly under applicable non-U.S. or U.S. state or local law, the Partnership and/or its subsidiaries
              must withhold tax with respect to certain transfers of property and/or other income or activities of the Partnership and/or its
              subsidiaries. In addition, backup withholding may be required in certain circumstances.
            </li>
            <li>
              <u>
                <i>Certain Documentation Relating to Withholding.</i>
              </u>
              &nbsp; The Subscriber certifies that the information contained in the executed copy (or copies) of IRS Form W-9 or appropriate IRS Form
              W-8 (and any accompanying required documentation), the FIRPTA Certificate, if applicable, and any applicable Attachments E-H when
              submitted to the Partnership will be true, correct and complete. The Subscriber shall (i) promptly inform the General Partner of any
              change in such information and (ii) furnish to the Partnership a new properly completed and executed IRS Form W-9, appropriate IRS Form
              W-8 (and any accompanying required documentation), or FIRPTA Certificate as applicable, and any applicable Attachments E-H as may be
              requested from time to time by the Partnership and as may be required under the Internal Revenue Service instructions to such forms, the
              Code, any applicable Treasury Regulations or as may be requested from time to time by the Partnership.
            </li>
            <li>
              <u>
                <i>Additional Tax Information.</i>
              </u>
              &nbsp; The Subscriber shall promptly provide such information, documentation or certification as may be requested by the General Partner
              to determine whether withholding may be required with respect to the Subscriber&apos;s Interest in the Partnership or in connection with
              tax filings in any jurisdiction in which or through which the Partnership invests, directly or indirectly, including any information or
              certification required for the Partnership or its subsidiaries (or any other entity in which the Partnership directly or indirectly
              invests or any parallel fund or alternative investment vehicle) to comply with any tax return or information filing requirements or to
              obtain a reduced rate of, or exemption from, any applicable tax, whether pursuant to the laws of such jurisdiction or an applicable tax
              treaty. Such information may include, without limitation, information regarding the ultimate beneficial owners of the Subscriber. The
              Subscriber hereby acknowledges and agrees that the General Partner may provide any such information, documentation or certifications to
              any applicable tax authority.
            </li>
          </ol>
        </li>
        <li>
          <u>
            <strong>Anti-Money Laundering Representations.</strong>
          </u>
          &nbsp; The Subscriber represents and warrants that the amounts paid or to be paid by it to the Partnership in respect of this Subscription
          Agreement are not directly, or to the Subscriber&apos;s knowledge indirectly, derived from activities that may contravene U.S. federal or
          state or non-U.S. laws or regulations, including laws and regulations governing money laundering and terrorist financing. The Subscriber
          also represents and warrants to, and agrees and covenants with, the Partnership, as of the date hereof and as of each subsequent date on
          which the Subscriber acquires any additional interest in, or makes a capital contribution to, the Partnership that, to the best of its
          knowledge, none of (a) the Subscriber, (b) any person controlling or controlled by the Subscriber, (c) if the Subscriber is a privately held
          entity, any person having a beneficial interest in the Subscriber, or (d) if the Subscriber is a trustee, any beneficiary of the trust for
          which the Subscriber is acting as trustee in connection with this Subscription Agreement (those persons covered by (a), (b) and (c)
          collectively being referred to as &apos;Related Parties&apos;) is named on any list of prohibited persons, entities or jurisdictions
          maintained and administered by the U.S. Treasury Department&apos;s Office of Foreign Assets Control (&apos;OFAC&apos;), or otherwise covered
          by any other sanctions program administered by OFAC. The lists of OFAC prohibited persons, entities or jurisdictions can be found on the
          OFAC website at www.treas.gov/ofac and the Subscriber should review the website before making this representation. The Subscriber agrees
          promptly to notify the Partnership should the Subscriber become aware of any change in the information set forth in this Section 6.
        </li>
        <li>
          <u>
            <strong>Anti-Money Laundering Compliance.</strong>
          </u>
          &nbsp; The Subscriber acknowledges that, to comply with anti-money laundering, OFAC and related requirements that are applicable to the
          Partnership, the General Partner may at any time require such information as the General Partner deems necessary to establish the identity
          of the Subscriber and any Related Parties and may seek to verify such identity and the source of funds for the subscription. If the General
          Partner deems it necessary, for other reasons, to comply with anti-money laundering, OFAC and related requirements applicable to the
          Partnership, including, without limitation, as a result of any delay or failure by the Subscriber or any Related Party to produce any
          information required for identification, identity verification and/or source-of-funds confirmation purposes, the General Partner, on behalf
          of the Partnership, may refuse to accept this Subscription Agreement and/or any portion or all of the subscription and may return any funds
          received to the account from which such funds were sent (unless such return is, in the judgment of the General Partner, contrary to
          applicable law or regulation or contrary to the dictate of law enforcement officials, in which case the funds may be blocked or retained).
          The Subscriber acknowledges that the General Partner may refuse to make any distribution or other payment to the Subscriber if the General
          Partner determines, suspects, or is advised that such distribution or payment might result in a violation of any applicable anti-money
          laundering, OFAC or other laws or regulations by any person in any relevant jurisdiction, or such refusal is considered by the General
          Partner necessary or appropriate to ensure the compliance by the General Partner with any such laws or regulations in any relevant
          jurisdiction. The Subscriber acknowledges that the General Partner or the Partnership may be required to report transactions that raise
          suspicions of money laundering or OFAC violations and to disclose the identity of the Subscriber and any Related Parties to appropriate
          government authorities. The Subscriber agrees further that the Indemnified Parties (as defined in Section 10) shall be held harmless and
          indemnified against any loss, claim, cost, damage or expense (a) arising as a result of a failure to process any subscription or the refusal
          to make a distribution or other payment under terms of this Section 7, or (b) which the Partnership or the General Partner may suffer as a
          result of any violations of law, rule or regulation committed by the Subscriber.
        </li>
        <li>
          <u>
            <strong>Additional Information.</strong>
          </u>
          &nbsp; The Subscriber agrees to supply any additional written information concerning the representations in this Subscription Agreement or
          any other matter relevant to the compliance of the Partnership, the General Partner or the Manager with applicable law, that the General
          Partner may reasonably request from time to time.
        </li>
        <li>
          <u>
            <strong>Confirmation of Representations.</strong>
          </u>
          &nbsp; The Subscriber shall (i) be deemed to have confirmed the accuracy of the representations in this Subscription Agreement, including
          the attachments hereto, to the Partnership as of the date the General Partner, on behalf of the Partnership, accepts this Subscription
          Agreement and each subsequent date on which the Subscriber acquires an additional interest in the Partnership or makes a capital
          contribution to the Partnership, (ii) promptly notify the Partnership if the Subscriber becomes aware that such representations are, at any
          time, inaccurate in any respect and (iii) furnish the Partnership with such updated information as may be necessary in order to ensure that
          the Subscriber&apos;s responses to all portions of this Subscription Agreement are, at all times, accurate and complete.
        </li>
        <li>
          <u>
            <strong>Indemnification.</strong>
          </u>
          &nbsp; The Subscriber shall indemnify and hold harmless each of the Indemnified Parties (as defined in the Partnership Agreement) from and
          against any losses, claims, damages, liabilities, costs or expenses to which any of them may become subject arising out of or based upon any
          false representation or warranty, or any breach of or failure to comply with any covenant or agreement, made by the Subscriber in this
          Subscription Agreement or in any other document furnished to the Partnership in connection with the Subscriber&apos;s acquisition of the
          Interest. The Subscriber will reimburse each Indemnified Party and the Partnership for their reasonable legal and other expenses (including
          the cost of any investigation and preparation) as they are incurred in connection with any action, proceeding or investigation arising out
          of or based upon the foregoing. The indemnity and reimbursement obligations of the Subscriber under this Section 10 shall be in addition to
          any liability which the Subscriber may otherwise have (including, without limitation, liability under the Partnership Agreement).
        </li>
        <li>
          <u>
            <strong>Disclosure of Information.</strong>
          </u>
          &nbsp; The Subscriber consents to the disclosure by the Manager, the General Partner, their Affiliates and the Partnership of the
          Subscriber&apos;s identity, investment in the Partnership and qualification to invest in the Partnership (e.g., the Subscriber&apos;s status
          as an Accredited Investor), as well as any relationship between the Subscriber and the General Partner or the Manager; (i) to existing and
          prospective investors in the Partnership and any other existing or future investment funds, accounts or programs sponsored or managed by the
          Manager or its Affiliates from time to time; (ii) to any bank or other party with whom the Partnership has or intends to conduct business;
          (iii) to any regulatory authority having jurisdiction over the Manager, the Partnership, the General Partner, any Limited Partner or any of
          their respective Affiliates or any regulatory authority that requests such information in connection with any Partnership activities; (iv)
          in connection with any litigation or other dispute or otherwise as necessary or appropriate to enforce the terms of the Partnership
          Agreement; (v) to any partners, members, managers, directors, officers, employees, agents, administrators, attorneys, accountants or other
          service providers of the Manager, the Partnership, the General Partner or any of their respective Affiliates; (vi) as required by any law,
          rule or regulation or in response to any subpoena or other legal process; and (vii) otherwise as the General Partner deems reasonably
          necessary for the conduct of the Partnership&apos;s business.
        </li>
        <li>
          <u>
            <strong>Subscription Not Transferable.</strong>
          </u>
          &nbsp; Neither this Subscription Agreement nor the rights or obligations of the Subscriber accruing pursuant to this Subscription Agreement
          shall be transferable without the General Partner&apos;s prior written consent, which consent may be withheld for any or no reason.
        </li>
        <li>
          <u>
            <strong>Binding Agreement.</strong>
          </u>
          &nbsp; This Subscription Agreement shall be binding upon the heirs, executors, administrators, successors and assigns of the Subscriber,
          subject to the requirements of Section 12, and, if accepted by the Partnership, shall be binding upon the Partnership&apos;s successors and
          assigns.
        </li>
        <li>
          <u>
            <strong>Survival.</strong>
          </u>
          &nbsp; All of the agreements, representations and warranties made by the Subscriber in this Subscription Agreement, including its
          attachments, shall survive the execution of the Partnership Agreement by the Subscriber.
        </li>
        <li>
          <u>
            <strong>Account Information.</strong>
          </u>
          &nbsp; The Subscriber elects to be paid all distributions and other amounts payable to the Subscriber by the Partnership in the form of wire
          transfer and to the account indicated on Attachment (A) hereto. The Partnership is authorized to pay such distributions in such form until
          five (5) business days after it has received from the Subscriber, in writing, new payment instructions. None of the Indemnified Parties
          shall have any liability for any distribution or payment paid in the manner and to the account elected by the Subscriber, or as subsequently
          modified in writing by the Subscriber.
        </li>
        <li>
          <u>
            <strong>Nominee or Custodian.</strong>
          </u>
          &nbsp; If the Subscriber is acting as nominee or custodian for another person in connection with the Interest, the undersigned has so
          indicated on Attachment (A). The acknowledgements, representations and warranties contained in Section 3 regarding the Subscriber are true
          and accurate with regard to the individual, entity or other person for which the undersigned is acting as nominee or custodian. Without
          limiting the generality of the foregoing, the representations and warranties regarding the status of the Subscriber in the attachments to
          this Subscription Agreement are true with respect to, and accurately describe, the individual, entity or other person for which the
          undersigned is acting as nominee or custodian. The person for which the undersigned is acting as nominee or custodian will not transfer or
          otherwise dispose of or distribute any part of its economic or beneficial interest in (or any other rights with respect to) the Interest
          without complying with all of the applicable provisions of the Partnership Agreement as if such person were a direct Limited Partner of the
          Partnership and were transferring a direct limited partnership interest in the Partnership. The undersigned agrees to provide such other
          information as the General Partner may reasonably request regarding the undersigned and person for which the undersigned is acting as
          nominee or custodian in order to determine the eligibility of the Subscriber to purchase the Interest.
        </li>
        <li>
          <u>
            <strong>FOIA Laws.</strong>
          </u>
          &nbsp; The Subscriber represents that unless otherwise indicated on Attachment (A) (as properly modified from time to time) hereto, he, she
          or it is not a &quot;FOIA Person,&quot; and agrees that he, she or it will immediately notify the Partnership in the event he, she or it is
          or otherwise becomes a FOIA Person at any time during the term of the Partnership. &quot;FOIA Person&quot; means (a) a person that is
          directly or indirectly subject to either Section 552(a) of Title 5, United States Code (commonly known as the &quot;Freedom of Information
          Act&quot;) or any similar federal, state, county or municipal public disclosure law, whether foreign or domestic; (b) a person that is
          subject, by regulation, contract or otherwise, to disclose Partnership information to a trading exchange or other market where interests in
          such Subscriber are sold or traded, whether foreign or domestic; (c) a pension fund or retirement system for a government entity, whether
          foreign or domestic; (d) a person who, by virtue of such person&apos;s (or any of its Affiliate&apos;s) current or proposed involvement in
          government office, is required to or will likely be required to disclose Partnership information to a governmental body, agency or committee
          (including, without limitation, any disclosures required in accordance with the Ethics in Government Act of 1978, as amended, and any rules
          and regulations of any executive, legislative or judiciary organization), whether foreign or domestic; (e) an agent, nominee, fiduciary,
          custodian or trustee for any person described in clauses (a) through (d) and (f) of this Section 17 if Partnership information provided to
          or disclosed to the Subscriber by the Partnership or the General Partner could at any time become available to such person described in
          clauses (a) through (d) or (f) of this Section 17; or (f) a person that is itself an investment fund or other entity that has any person
          described in clauses (a) through (e) of this Section 17 as a partner, member or other beneficial owner if Partnership information provided
          to or disclosed to the Subscriber by or on behalf of the Partnership or the General Partner could at any time become available to such
          person.
        </li>
        <li>
          <u>
            <strong>Waiver; Modification.</strong>
          </u>
          &nbsp; Neither this Subscription Agreement nor any provision hereof may be waived, modified, discharged or terminated except by an
          instrument in writing signed by the party against whom such waiver, modification, discharge or termination is sought to be enforced.
        </li>
        <li>
          <u>
            <strong>Headings.</strong>
          </u>
          &nbsp; Section and subsection headings used herein are for convenience of reference only, are not part of this Subscription Agreement and
          shall not be considered in interpreting this Subscription Agreement.
        </li>
        <li>
          <u>
            <strong>Integration.</strong>
          </u>
          &nbsp; This Subscription Agreement, the Partnership Agreement and any side letter agreement between the Partnership and the Subscriber
          constitute the entire agreement among the parties hereto pertaining to the subject matter hereof and supersede all prior and contemporaneous
          agreements and understandings of the parties in connection therewith. No covenant, representation or condition not expressed in this
          Subscription Agreement, the Partnership Agreement or such side letter agreement shall affect, or be effective to interpret, change or
          restrict, the express provisions of this Subscription Agreement.
        </li>
        <li>
          <u>
            <strong>Separability.</strong>
          </u>
          &nbsp; Each provision of this Subscription Agreement shall be considered separable and if for any reason any provision or provisions hereof
          are determined to be invalid and contrary to any existing or future law, such invalidity shall not impair the operation of or affect those
          portions of this Subscription Agreement which are valid and such invalid provision shall be deemed severed from the remainder of this
          Subscription Agreement and replaced with a valid provision as similar in intent as reasonably possible to the provision so severed.
        </li>
        <li>
          <u>
            <strong>Counterparts.</strong>
          </u>
          &nbsp; This Subscription Agreement may be executed in counterparts with the same effect as if the parties executing the counterparts had all
          executed one counterpart.
        </li>
        <li>
          <u>
            <strong> Governing Law.</strong>
          </u>
          &nbsp; This Subscription Agreement and all legal relations, claims or obligations arising out of this transaction shall be governed by and
          construed in accordance with the laws of the State of Delaware, without regard to conflicts of law provisions.{' '}
        </li>
        <li>
          <u>
            <strong>Notices.</strong>
          </u>
          &nbsp; Any notice, demand, request or other communication which may be required or contemplated herein shall be subject to Section 14.10 of
          the Partnership Agreement to the same extent as if such section was set forth herein.
        </li>
        <li>
          <u>
            <strong>Changes to Partnership Agreement.</strong>
          </u>
          &nbsp; The Subscriber acknowledges that the Partnership Agreement may be amended or modified (in addition to the amendments and
          modifications that the General Partner is permitted to make without the consent of the Limited Partners ) after the Subscriber has delivered
          signed documents to the General Partner and prior to the admission of the Subscriber as a limited partner. Unless the Subscriber has elected
          (by checking the appropriate box on its signature page) that this Section 25 not apply to the Subscriber, the Subscriber agrees that unless
          the Subscriber gives written notice to the General Partner within three (3) days of receiving any proposed changes to the form of
          Partnership Agreement previously delivered to the Subscriber electing to terminate the Subscriber&apos;s subscription, the Subscriber shall
          be deemed to have agreed to such changes in the Partnership Agreement reflecting those changes.
        </li>
      </ol>
      <p>
        IN WITNESS WHEREOF, the foregoing Subscription Agreement is hereby executed by the undersigned as of this &nbsp;
        <span id="herebyExecutedByTheUndersigned" className="vgs-input-container">
          herebyExecutedByTheUndersigned
        </span>
      </p>
      <br />
      <h5>SUBSCRIBER:</h5>
      <br />
      <table className="w-100">
        <thead>
          <tr>
            <th>
              <strong>For Natural Persons:</strong>
            </th>
            <th>
              <strong>For Entities:</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-3">
              <div className="border-bottom text-center py-3">
                <span id="subscriberNaturalPersonNameLimitedPartner" className="vgs-input-container">
                  subscriberNaturalPersonNameLimitedPartner
                </span>
              </div>
              <p className="text-center my-3">(Name of Limited Partner)</p>
            </td>
            <td className="px-3">
              <div className="border-bottom text-center py-3">
                <span id="subscriberEntityNameLimitedPartner" className="vgs-input-container">
                  subscriberEntityNameLimitedPartner
                </span>
              </div>
              <p className="text-center my-3">(Name of Limited Partner)</p>
            </td>
          </tr>
          <tr>
            <td className="px-3">
              <div className="border-bottom text-center py-3">
                <span id="subscriberNaturalPersonSignatureLimitedPartner" className="vgs-input-container">
                  subscriberNaturalPersonSignatureLimitedPartner
                </span>
              </div>
              <p className="text-center my-3">(Signature of Limited Partner)</p>
            </td>
            <td className="px-3">
              <div className="d-flex align-items-center border-bottom py-3">
                By:
                <span id="subscriberEntityBy" className="vgs-input-container ms-3">
                  subscriberEntityBy
                </span>
              </div>
              <p className="d-flex align-items-center py-3">
                Name:
                <span id="subscriberEntityByName" className="vgs-input-container ms-3">
                  subscriberEntityByName
                </span>
              </p>
              <p className="d-flex align-items-center">
                Title:
                <span id="subscriberEntityByTitle" className="vgs-input-container ms-3">
                  subscriberEntityByTitle
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td className="px-3">
              <div className="border-bottom text-center py-3">
                <span id="subscriberNaturalPersonAdditionalSignatureLimitedPartner" className="vgs-input-container">
                  subscriberNaturalPersonAdditionalSignatureLimitedPartner
                </span>
              </div>
              <p className="text-center my-3">(Additional Signature, If Applicable)</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        Consent by Subscriber to receive notices by electronic transmission: <Radio name="radio01" options={['Yes', 'No']} />
      </p>
      <p>
        <Checkbox name="checkbox001" className="form-check-input" /> Subscriber elects that Section 25 not apply to the Subscriber. Note: If the
        Subscriber checks this box, and the form of Partnership Agreement is revised after the Subscriber has submitted its subscription materials and
        before the Subscriber&apos;s subscription has been accepted, the Subscriber&apos;s subscription will not be accepted unless the Subscriber
        gives written notice to the General Partner approving the change in the Partnership Agreement.
      </p>
      <br />
      <h5 className="text-center">ACCEPTANCE</h5>
      <p>
        The foregoing Subscription Agreement is hereby accepted, as of the date set forth below, upon the terms and conditions set forth herein and in
        the Partnership Agreement.
      </p>
      <p>
        <strong>PARTNERSHIP:</strong>
      </p>
      <p>CABOT INDUSTRIAL CORE FUND III, L.P.</p>
      <p>By: Cabot Industrial Core Fund III Manager, Limited Partnership, its General Partner</p>
      <p>By: Cabot Industrial Core Fund III Manager GP, LLC, its General Partner</p>
      <p>By: Cabot Properties, Inc., its Manager</p>
      <p className="d-flex align-items-center">
        By:
        <span id="acceptancePartnershipBy" className="vgs-input-container ms-3">
          acceptancePartnershipBy
        </span>
      </p>
      <p className="d-flex align-items-center">
        Dated:
        <span id="acceptancePartnershipDated" className="vgs-input-container ms-3">
          acceptancePartnershipDated
        </span>
      </p>
      <br />
      <h5 className="text-center">ATTACHMENT (A)</h5>
      <br />
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan={2}>Subscriber Information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Name of Subscriber</div>
              <div>
                <span id="nameOfSubscriber" className="vgs-input-container my-2">
                  nameOfSubscriber
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Address of Subscriber</div>
              <div>
                <span id="addressOfSubscriber" className="vgs-input-container my-2">
                  addressOfSubscriber
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="fw-light text-small">Country of Residence / State of Incorporation</div>
              <div>
                <span id="countryOfResidence" className="vgs-input-container my-2">
                  countryOfResidence
                </span>
              </div>
            </td>
            <td>
              <div className="fw-light text-small">Taxpayer Identification Number or SS Number (U.S. citizens and residents only)</div>
              <div>
                <span id="taxpayerIdentificationNumber" className="vgs-input-container my-2">
                  taxpayerIdentificationNumber
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="fw-light text-small">Total Amount of Subscription (in $)</div>
              <div>
                <GInput type="number" name="totalAmount" required />
              </div>
            </td>
            <td>
              <div className="fw-light text-small">Date of end of U.S. Federal Income Tax Year (e.g., December 31)</div>
              <div>
                <span id="dateOfEndOfUSFederalIncomeTaxYear" className="vgs-input-container my-2">
                  dateOfEndOfUSFederalIncomeTaxYear
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Is Subscriber acting as Nominee or Custodian for another person?</div>
              <div>
                <Radio name="radio02" options={['Yes', 'No']} />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Is Subscriber Tax-Exempt under Section 501(a) of the Code?</div>
              <div>
                <Radio name="radio03" options={['Yes', 'No']} />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Is Subscriber a FOIA Person? (See Section 17 of the Subscription Agreement)</div>
              <div>
                <Radio name="radio04" options={['Yes', 'No']} />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">
                Is Subscriber formed or recapitalized for the purpose of investing in the Partnership? (See Section 3(l))
              </div>
              <div>
                <Radio name="radio05" options={['Yes', 'No']} />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Phone of Subscriber</div>
              <div>
                <span id="phoneOfSubscriber" className="vgs-input-container my-2">
                  phoneOfSubscriber
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Email of Subscriber</div>

              <span id="emailOfSubscriber" className="vgs-input-container my-2">
                emailOfSubscriber
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">
                <i>
                  Information pertaining to the Financial Institution from which the Subscriber&apos;s Capital Contributions will be paid to the
                  Partnership
                </i>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Account Name</div>
              <div>
                <span id="subscriberAccountName" className="vgs-input-container my-2">
                  subscriberAccountName
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Name and Address of Financial Institution</div>
              <div>
                <span id="subscriberNameAndAddressFinancialInstitution" className="vgs-input-container my-2">
                  subscriberNameAndAddressFinancialInstitution
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan={2}>Distribution Information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2} className="text-center bg-gray text-small">
              Please complete all information:
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="fw-bold">
              Wire Transfer Instructions:
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Bank Name and Address</div>
              <div>
                <span id="distributionInformationBankNameAddress" className="vgs-input-container my-2">
                  distributionInformationBankNameAddress
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Account Number</div>
              <div>
                <span id="distributionInformationBankAccountNumber" className="vgs-input-container my-2">
                  distributionInformationBankAccountNumber
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Account Name</div>
              <div>
                <span id="distributionInformationBankAccountName" className="vgs-input-container my-2">
                  distributionInformationBankAccountName
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Additional Information</div>
              <div>
                <span id="distributionInformationAdditionalInformation" className="vgs-input-container my-2">
                  distributionInformationAdditionalInformation
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan={2}>Designation of Correspondence</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2} className="text-center bg-gray text-small">
              Please designate the type of correspondence the following should receive:
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Radio name="radio06" options={['All Correspondence', 'Quarterly Reports', 'Capital Calls', 'Tax Schedule K-1']} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Name</div>
              <div>
                <span id="designationCorrespondenceName1" className="vgs-input-container my-2">
                  designationCorrespondenceName1
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Address</div>
              <div>
                <span id="designationCorrespondenceAddress1" className="vgs-input-container my-2">
                  designationCorrespondenceAddress1
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Phone</div>
              <div>
                <span id="designationCorrespondencePhone1" className="vgs-input-container my-2">
                  designationCorrespondencePhone1
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="text-center bg-gray text-small">
              Please designate the type of correspondence the following should receive:
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Radio name="radio07" options={['All Correspondence', 'Quarterly Reports', 'Capital Calls', 'Tax Schedule K-1']} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Name</div>
              <div>
                <span id="designationCorrespondenceName2" className="vgs-input-container my-2">
                  designationCorrespondenceName2
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Address</div>
              <div>
                <span id="designationCorrespondenceAddress2" className="vgs-input-container my-2">
                  designationCorrespondenceAddress2
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Phone</div>
              <div>
                <span id="designationCorrespondencePhone2" className="vgs-input-container my-2">
                  designationCorrespondencePhone2
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="text-center bg-gray text-small">
              Please designate the type of correspondence the following should receive:
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Radio name="radio08" options={['All Correspondence', 'Quarterly Reports', 'Capital Calls', 'Tax Schedule K-1']} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Name</div>
              <div>
                <span id="designationCorrespondenceName3" className="vgs-input-container my-2">
                  designationCorrespondenceName3
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Address</div>
              <div>
                <span id="designationCorrespondenceAddress3" className="vgs-input-container my-2">
                  designationCorrespondenceAddress3
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="fw-light text-small">Phone</div>
              <div>
                <span id="designationCorrespondencePhone3" className="vgs-input-container my-2">
                  designationCorrespondencePhone3
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan={2}> IRS Form 1065 item I</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <p>
                <Checkbox name="checkbox002" className="form-check-input" />
                &nbsp; Check here if the Subscriber is a disregarded entity (as defined in the Subscription Instructions), or grantor trust, in which
                case the Subscriber should answer the question below as if each reference to the &quot;Subscriber&quot; were replaced with a reference
                to the &quot;Subscriber&apos;s Owner&quot; (as defined in the Subscription Instructions) or the applicable grantor(s).
              </p>

              <p>What type of person or entity is the Subscriber for federal income tax purposes?</p>

              <p>
                <Checkbox name="checkbox003" className="form-check-input" />
                &nbsp; An individual
              </p>
              <p>
                <Checkbox name="checkbox004" className="form-check-input" />
                &nbsp; A corporation
              </p>
              <p>
                <Checkbox name="checkbox005" className="form-check-input" />
                &nbsp; An estate
              </p>
              <p>
                <Checkbox name="checkbox006" className="form-check-input" />
                &nbsp; A trust
              </p>
              <p>
                <Checkbox name="checkbox007" className="form-check-input" />
                &nbsp; A partnership
              </p>
              <p>
                <Checkbox name="checkbox008" className="form-check-input" />
                &nbsp; An exempt organization (See also Attachment (G))
              </p>
              <p>
                <Checkbox name="checkbox009" className="form-check-input" />
                &nbsp; A foreign government
              </p>

              <p>
                For the avoidance of doubt, if the Subscriber is a limited liability company (LLC), enter the LLC&apos;s classification for federal
                income tax purposes (that is, a corporation, partnership or disregarded entity (see above)).
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan={2}>Information for Form ADV Schedule D Section 7.B.(1) item 16</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <p>Is the Subscriber a &quot;fund-of-funds&quot;?</p>

              <p>
                <Radio name="radio09" options={['Yes', 'No']} />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan={2}>Information State Taxes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <p className="border-bottom">
                If Subscriber&apos;s state tax classification is different from its classification for federal income tax purposes as indicated above,
                please check the box below and explain the difference.
                <br /> <br />
                <div className="d-flex align-items-center pb-3">
                  <Checkbox name="checkbox010" className="form-check-input" />
                  &nbsp;
                  <span id="subscriberStateTaxClassificationDifferent" className="vgs-input-container">
                    subscriberStateTaxClassificationDifferent
                  </span>
                </div>
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p className="border-bottom">
                If Subscriber has a state tax residency or domicile please provide it here: (The Subscriber&apos;s state tax residency for trusts
                varies by state and may be based on several factors. Please consult your tax advisor.)
                <br /> <br />
                <span id="subscriberHasStateTaxResidency" className="vgs-input-container mb-3">
                  subscriberHasStateTaxResidency
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan={2}>Information for Form PF Section 1b item 16</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <p className="border-bottom">
                Please choose one of the descriptions below that best describes the Subscriber or its beneficial owner: (Italicized terms are defined
                below.)
                <br /> <br />
                <Checkbox name="checkbox011" className="form-check-input" />
                &nbsp; An individual that is a United States person (including his or her trusts) <br />
                <Checkbox name="checkbox012" className="form-check-input" />
                &nbsp; An individual that is not a United States person (including his or her trusts) <br />
                <Checkbox name="checkbox013" className="form-check-input" />
                &nbsp; A broker-dealer <br />
                <Checkbox name="checkbox014" className="form-check-input" />
                &nbsp; An insurance company <br />
                <Checkbox name="checkbox015" className="form-check-input" />
                &nbsp; An investment company registered with the U.S. Securities and Exchange Commission. <br />
                <Checkbox name="checkbox016" className="form-check-input" />
                &nbsp; A Private fund <br />
                <Checkbox name="checkbox017" className="form-check-input" />
                &nbsp; A non-profit <br />
                <Checkbox name="checkbox018" className="form-check-input" />
                &nbsp; A pension plan (excluding governmental pension plans)
                <br />
                <Checkbox name="checkbox019" className="form-check-input" />
                &nbsp; A banking or thrift institution (proprietary)
                <br />
                <Checkbox name="checkbox020" className="form-check-input" />
                &nbsp; A state or municipal government entity (excluding governmental pension plans)
                <br />
                <Checkbox name="checkbox021" className="form-check-input" />
                &nbsp; A state or municipal governmental pension plan
                <br />
                <Checkbox name="checkbox022" className="form-check-input" />
                &nbsp; A sovereign wealth fund or a foreign official institution
                <br />
                <Checkbox name="checkbox023" className="form-check-input" />
                &nbsp; An Investor that is not a United States person and about which the foregoing beneficial ownership information is not known and
                cannot reasonably be obtained because the beneficial interest is held through a chain involving one or more third-party intermediaries
                <br />
                <Checkbox name="checkbox024" className="form-check-input" />
                &nbsp; Other
                <br /> <br />
              </p>

              <table>
                <tr>
                  <td>
                    <p>Control</p>
                  </td>
                  <td>
                    <p className="flex-1 ps-3">
                      The power, directly or indirectly, to direct the management or policies of a person, whether through ownership of securities, by
                      contract, or otherwise. <br />
                      A person is presumed to control a corporation if the person: (i) directly or indirectly has the right to vote 25 percent or more
                      of a class of the corporation’s voting securities; <br /> or (ii) has the power to sell or direct the sale of 25 percent or more
                      of a class of the corporation’s voting securities. A person is presumed to control a partnership if the person has the right to
                      receive upon dissolution, or has contributed, 25 percent or more of the capital of the partnership. <br /> A person is presumed
                      to control a limited liability company (“LLC”) if the person: (i) directly or indirectly has the right to vote 25 percent or
                      more of a class of the interests of the LLC; (ii) has the right to receive upon dissolution, or has contributed, 25 percent or
                      more of the capital of the LLC; or (iii) is an elected manager of the LLC. <br /> A person is presumed to control a trust if the
                      person is a trustee or directs or manages (or who participates in directing or managing) the affairs of the trust.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Government Entity</p>
                  </td>
                  <td>
                    <p className="flex-1 ps-3">
                      Any state or political subdivision of a state, including (i) any agency, authority, or instrumentality of the state or political
                      subdivision; (ii) a plan or pool of assets controlled by the state or political subdivision or any agency, authority, or
                      instrumentality thereof; and (iii) any officer, agent, or employee of the state or political subdivision or any agency,
                      authority, or instrumentality thereof, acting in their official capacity.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Private fund</p>
                  </td>
                  <td>
                    <p className="flex-1 ps-3">
                      Any issuer that would be an investment company as defined in section 3 of the Investment Company Act of 1940 but for section
                      3(c)(1) or 3(c)(7) of that Act.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>United States person</p>
                  </td>
                  <td>
                    <p className="flex-1 ps-3">
                      Has the meaning provided in rule 203(m)-1 under the Investment Advisers Act of 1940, which includes any natural person that is
                      resident in the United States.
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h5 className="text-center">EMPLOYEE BENEFIT PLAN STATUS</h5>
      <br />
      <p>The Subscriber represents and warrants as follows (please check all boxes that apply):</p>
      <p>The Subscriber is purchasing the Interest with funds that constitute the assets of:</p>
      <table>
        <tr>
          <td>
            <Checkbox name="checkbox025" className="form-check-input" />
          </td>
          <td className="ps-3">
            an “employee benefit plan” as defined in Section 3(3) of the U.S. Employee Retirement Income Security Act of 1974, as amended (“ERISA”),
            that is subject to Title I of ERISA (including non-governmental qualified U.S. pension plans);
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox026" className="form-check-input" />
          </td>
          <td className="ps-3">
            an “employee benefit plan” as defined in Section 3(3) of ERISA that is not subject to either Title I of ERISA or Section 4975 of the U.S.
            Internal Revenue Code of 1986, as amended (the “Code”) (including a governmental plan, non-electing church plan or non-U.S. plan). The
            Subscriber hereby represents and warrants that (a) its investment in the Partnership: (i) does not violate and is not otherwise
            inconsistent with the terms of any legal document constituting or governing the employee benefit plan; (ii) has been duly authorized and
            approved by all necessary parties; and (iii) is in compliance with all applicable laws, rules and regulations and (b) neither the
            Partnership nor any person who manages the assets of the Partnership will be subject to any laws, rules or regulations applicable to such
            Subscriber solely as a result of the investment in the Partnership by such Subscriber;
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox027" className="form-check-input" />
          </td>
          <td className="ps-3">a plan that is subject to Code Section 4975 (including an individual retirement account or IRA);</td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox028" className="form-check-input" />
          </td>
          <td className="ps-3">
            an entity (including, if applicable, an insurance company general account) whose underlying assets include “plan assets” of one or more
            “employee benefit plans” that are subject to Title I of ERISA and/or one or more “plans” that are subject to Code Section 4975 by reason
            of the investment in such entity, directly or indirectly, by such employee benefit plans or plans. The Subscriber represents and warrants
            that the percentage of equity interests in the Subscriber held by such employee benefit plans or plans (the “BPI Percentage”) does not
            exceed, and is not expected to exceed, the percentage set forth below: <br />
            <span id="subscriberWarrantyPercentage" className="vgs-input-container">
              subscriberWarrantyPercentage
            </span>
            <br />
            If the Subscriber is such an entity and does not provide the foregoing percentage, such percentage shall be assumed to be 100%. If the BPI
            Percentage at any time exceeds, or is expected to exceed, the foregoing percentage, the Subscriber will promptly notify the General
            Partner of such circumstance.
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox029" className="form-check-input" />
          </td>
          <td className="ps-3">
            an entity (a) that is a group trust within the meaning of Revenue Ruling 81-100, a common or collective trust fund of a bank or an
            insurance company separate account and (b) that is subject to Title I of ERISA and/or Code Section 4975.
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox030" className="form-check-input" />
          </td>
          <td className="ps-3">The Subscriber is not purchasing the Interest with funds that constitute the assets of any of the above.</td>
        </tr>
      </table>
      <br />
      <p>
        If the Subscriber is an employee benefit plan subject to Title I of ERISA and/or Code Section 4975 (including an IRA) (a “Plan”), the
        Subscriber hereby represents and warrants that the person who directed the Plan’s investment in the Partnership (i) is responsible for the
        decision to invest in the Partnership; (ii) is qualified to make such investment decision and to the extent it deems necessary has consulted
        its own investment advisors and legal counsel regarding the investment in the Partnership; (iii) in making its decision to invest in the
        Partnership has not relied on any advice or recommendation of the Manager, the Partnership, the General Partner or any of their respective
        Affiliates; and (iv) will not engage in any, direct or indirect, non-exempt prohibited transaction described in ERISA Section 406 or in Code
        Section 4975 in connection with or as a result of an investment in the Partnership.
      </p>
      <p>
        If the Subscriber is a Plan, the Subscriber on behalf of the authorized fiduciary of the Plan (the “Fiduciary”) represents, acknowledges and
        agrees that:
      </p>
      <ol type="a">
        <li>
          the purchase of the Interests by the Subscriber is an arm’s length transaction related to an investment in securities or other investment
          property.
        </li>
        <li>
          the Fiduciary is capable of evaluating investment risks independently, both in general and with regard to the purchase of the Interests.
        </li>
        <li>
          the General Partner and the Manager have informed the Fiduciary (i) that none of the General Partner, the Manager, or any of their
          affiliates is undertaking to provide impartial investment advice or to give advice in a fiduciary capacity in connection with the offering
          or purchase of the Interests, and (ii) of the existence and nature of the General Partner’s and the Manager’s financial interests associated
          with the purchase of the Interest, including the fees and other distributions that the General Partner and/or Manager anticipate receiving
          from the Partnership on account of the purchase of the Interest.
        </li>
        <li>
          the Fiduciary is a fiduciary under ERISA or the Code, or both, with respect to the purchase of the Interest by the Subscriber, and is
          responsible for exercising independent judgment in evaluating such purchase of the Interest.
        </li>
        <li>
          each of the General Partner and the Manager has not acted (and will not act) as a fiduciary (as defined in Section 3(21) of ERISA, to the
          extent ERISA is applicable to the Subscriber) with respect to the decision to invest in the Partnership, to allocate assets of the
          Subscriber to the Partnership, or to continue such investment in the future.
        </li>
        <li>
          the General Partner and the Manager have no responsibility for the overall diversification of the investment portfolio of the Subscriber or
          for the prudence of investing in the Partnership in relation to the total portfolio of the Subscriber.
        </li>
      </ol>
      <br />
      <h5 className="text-center">ACCREDITED INVESTOR STATUS</h5>
      <br />
      <p>
        The Subscriber represents and warrants that he, she or it is an “accredited investor” (an “Accredited Investor”) as such term is defined in
        Rule 501(a) of Regulation D under the U.S. Securities Act of 1933, as amended (the “Securities Act”), for one or more of the reasons specified
        below (please check all boxes that apply):
      </p>
      <u>
        <i>For Natural Persons</i>
      </u>
      <br /> <br />
      <p>
        <Checkbox name="checkbox031" className="form-check-input" /> &nbsp; The Subscriber is a natural person and (please check all boxes that
        apply):
        <br /> <br />
        <p className="ps-4">
          <Checkbox name="checkbox032" className="form-check-input" /> &nbsp; has an individual net worth or a joint net worth with the Subscriber’s
          Spousal Equivalent1 in excess of $1,000,000 (determined by subtracting total liabilities from total assets)2;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox033" className="form-check-input" /> &nbsp; had an individual income in excess of $200,000 (or a joint income
          together with the Subscriber’s spouse or Spousal Equivalent in excess of $300,000) in each of the two most recently completed calendar
          years, and reasonably expects to have an individual income in excess of $200,000 (or a joint income together with the Subscriber’s spouse or
          Spousal Equivalent in excess of $300,000) in the current calendar year;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox034" className="form-check-input" /> &nbsp; holds in good standing one or more of the following professional
          certifications: General Securities Representative license (Series 7), Private Securities Offerings Representative license (Series 82), or
          Investment Adviser Representative license (Series 65); and/or
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox035" className="form-check-input" /> &nbsp; is a “family client,” as defined in Rule 202(a)(11)(G)-1 under the U.S.
          Investment Advisers Act of 1940, as amended (the “Advisers Act”), whose prospective investment in the Partnership is directed by that
          person’s Qualified Family Office (as defined below).
        </p>
      </p>
      <u>
        <i>For Entities</i>
      </u>
      <br /> <br />
      <p>
        <Checkbox name="checkbox036" className="form-check-input" /> &nbsp; The Subscriber is an entity and (please check all boxes that apply):
        <br /> <br />
        <p className="ps-4">
          <Checkbox name="checkbox037" className="form-check-input" /> &nbsp; is a corporation, partnership, limited liability company, Massachusetts
          or similar business trust or organization described in Section 501(c)(3) of the U.S. Internal Revenue Code of 1986, as amended, not formed
          for the specific purpose of acquiring interests in the Partnership that has total assets in excess of $5,000,000;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox038" className="form-check-input" /> &nbsp; is a bank as defined in Section 3(a)(2) of the Securities Act, a savings
          and loan association, or other institution defined in Section 3(a)(5)(A) of the Securities Act acting in either its individual or fiduciary
          capacity (this includes a trust for which a bank acts as trustee and exercises investment discretion with respect to the trust’s decision to
          invest in the Partnership);
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox039" className="form-check-input" /> &nbsp; is a broker dealer registered pursuant to Section 15 of the U.S.
          Securities Exchange Act of 1934, as amended (the “Exchange Act”);
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox040" className="form-check-input" /> &nbsp; is an investment adviser registered pursuant to Section 203 of the
          Advisers Act, or registered pursuant to the laws of a U.S. state;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox041" className="form-check-input" /> &nbsp; is an investment adviser relying on the exemption from registering with
          the U.S. Securities and Exchange Commission under Section 203(l) or (m) of the Advisers Act;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox042" className="form-check-input" /> &nbsp; is an insurance company as defined in Section 2(a)(13) of the Securities
          Act;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox043" className="form-check-input" /> &nbsp; is an investment company registered under the U.S. Investment Company
          Act of 1940, as amended (the “Investment Company Act”), or a business development company as defined in Section 2(a)(48) of the Investment
          Company Act;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox044" className="form-check-input" /> &nbsp; is a Small Business Investment Company licensed by the U.S. Small
          Business Administration under Section 301(c) or (d) of the U.S. Small Business Investment Act of 1958, as amended (“SBIA”);
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox045" className="form-check-input" /> &nbsp; is a Rural Business Investment Company as defined in Section 384A of the
          Consolidated Farm and Rural Development Act of 1972, as amended;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox046" className="form-check-input" /> &nbsp; is a plan established and maintained by a state, its political
          subdivisions, or an agency or instrumentality of a state or its political subdivisions, for the benefit of employees, having total assets in
          excess of $5,000,000;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox047" className="form-check-input" /> &nbsp; is an employee benefit plan within the meaning of the U.S. Employee
          Retirement Income Security Act of 1974, as amended (“ERISA”), (a) for which the investment decision to acquire an interest in the
          Partnership is being made by a plan fiduciary, as defined in Section 3(21) of ERISA, that is either a bank, savings and loan association,
          insurance company, or registered investment adviser, (b) which has total assets in excess of $5,000,000, or (c) which is self-directed, with
          the investment decisions made solely by persons who are Accredited Investors;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox048" className="form-check-input" /> &nbsp; is a private business development company as defined in Section
          202(a)(22) of the Advisers Act;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox049" className="form-check-input" /> &nbsp; is a trust not formed for the specific purpose of acquiring interests in
          the Partnership with total assets in excess of $5,000,000 and directed by a person who has such knowledge and experience in financial and
          business matters as to be capable of evaluating the merits and risks of investing in the Partnership;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox050" className="form-check-input" /> &nbsp; is a revocable trust (including a revocable trust formed for the
          specific purpose of acquiring an interest in the Partnership) and the grantor or settlor of such trust is an Accredited Investor;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox051" className="form-check-input" /> &nbsp; is a “family office” as defined in Rule 202(a)(11)(G)-1 under the
          Advisers Act, (a) with assets under management in excess of $5,000,000, (b) that was not formed for the specific purpose of acquiring
          interests in the Partnership, and (c) whose prospective investment in the Partnership is directed by a person who has such knowledge and
          experience in financial and business matters that such family office is capable of evaluating the merits and risks of an investment in the
          Partnership (such a family office, a “Qualified Family Office”);
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox052" className="form-check-input" /> &nbsp; is a “family client,” as defined in Rule 202(a)(11)(G)-1 under the
          Advisers Act, whose prospective investment in the Partnership is directed by its Qualified Family Office;
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox053" className="form-check-input" /> &nbsp; is an entity of a type not listed above that (i) was not formed for the
          specific purpose of acquiring interests in the Partnership and (ii) that owns “investments” (as defined in Rule 2a51-1(b) under the
          Investment Company Act) in excess of $5,000,000; and/or
        </p>
        <p className="ps-4">
          <Checkbox name="checkbox054" className="form-check-input" /> &nbsp; is an entity in which each equity owner is an Accredited Investor.3
        </p>
      </p>
      <br />
      <h5 className="text-center">QUALIFIED PURCHASER STATUS</h5>
      <br />
      <ol>
        <li>
          &quot;Qualified Purchaser&quot; means a person that is described in Section 1(a) through (g) below. Please mark each category applicable to
          the Subscriber (and if the Subscriber is an entity, please be sure to answer from the perspective of the entity itself). Please note that
          the Appendix to this Attachment (D) contains several important definitions applicable to this Attachment (D), including the term
          “Investments.” The Subscriber is:
          <ol type="a">
            <li>
              <Checkbox name="checkbox055" className="form-check-input" /> &nbsp; A natural person who owns not less than $5,000,000 in Investments.
              For this purpose, Investments owned by the Subscriber include all Investments that are the Subscriber’s separate property and any
              Investments held jointly with the Subscriber’s spouse, as community property or otherwise, but do not include Investments that are the
              separate property of the Subscriber’s spouse unless the Interest will be a joint investment of the Subscriber and the Subscriber’s
              spouse.
            </li>
            <li>
              <Checkbox name="checkbox056" className="form-check-input" /> &nbsp; A natural person who has discretionary investment authority with
              regard to at least $25,000,000 of Investments, including for this purpose solely the Subscriber’s own Investments and Investments of
              third parties that are themselves accurately described by one or more paragraphs of this Section 1 (other than paragraph (h)).
            </li>
            <li>
              <Checkbox name="checkbox057" className="form-check-input" /> &nbsp; A corporation, partnership, limited liability company, trust or
              other organization that: (i) was not organized or reorganized and is not operated for the specific purpose of acquiring the Partnership
              Interest or any other interest in the Partnership, and less than 40% of the assets of which will consist of interests in the Partnership
              (calculated as of the time of the Subscriber’s execution of this Subscription Agreement and the Subscriber’s admission to the
              Partnership); (ii) owns not less than $5,000,000 in Investments; and (iii) is owned directly or indirectly solely by or for two or more
              natural persons who are related as siblings or spouses (including former spouses), or direct lineal descendants by birth or adoption,
              spouses of such persons, the estates of such persons, or foundations, charitable organizations, or trusts established by or for the
              benefit of such persons.
            </li>
            <li>
              <Checkbox name="checkbox058" className="form-check-input" /> &nbsp; A trust: (i) that is not described in paragraph (c) of this Section
              1; (ii) that was not organized or reorganized and is not operated for the specific purpose of acquiring the Partnership Interest or any
              other interest in the Partnership, and less than 40% of the assets of which will consist of interests in the Partnership (calculated as
              of the time of the Subscriber’s execution of this Subscription Agreement and the Subscriber’s admission to the Partnership); and (iii)
              with respect to which each of the settlors and other contributors of assets, trustees, and other authorized decision makers is a person
              described in paragraph (a), (b), (c) or (e) of this Section 1.
            </li>
            <li>
              <Checkbox name="checkbox059" className="form-check-input" /> &nbsp; An entity that: (i) was not organized or reorganized and is not
              operated for the specific purpose of acquiring the Interest or any other interest in the Partnership, and less than 40% of the assets of
              which will consist of interests in the Partnership (calculated as of the time of the Subscriber’s execution of this Subscription
              Agreement and the Subscriber’s admission to the Partnership); and (ii) has discretionary investment authority with regard to at least
              $25,000,000 of Investments, whether for its own account or for the account of other persons that are themselves accurately described by
              one or more other paragraphs of this Section 1 (other than paragraph (h)).
            </li>
            <li>
              <Checkbox name="checkbox060" className="form-check-input" /> &nbsp; A Qualified Institutional Buyer within the meaning of Rule 2a51-1(g)
              under the Investment Company Act. Note that not all persons that are Qualified Institutional Buyers within the meaning of Rule 144A
              satisfy the definition in Rule 2a51-1(g) under the Investment Company Act. Dealers described in paragraph (a)(1)(ii) of Rule 144A, must
              own and invest on a discretionary basis at least $25 million in securities of issuers that are not affiliated persons of the Subscriber.
              The following plans are not Qualified Institutional Buyers within the meaning of Rule 2a51-1(g) under the Investment Company Act except
              with respect to investment decisions made solely by the fiduciary, trustee or sponsor of such plan: (i) a plan established and
              maintained by a state, its political subdivisions, or any agency or instrumentality of a state or its political subdivisions, for the
              benefit of its employees, (ii) an employee benefit plan within the meaning of ERISA, or (iii) a trust fund whose trustee is a bank or
              trust company and whose participants are exclusively persons described in (d) or (e) of paragraph (a)(1)(i) of Rule 144A (but not a
              trust fund that includes individual retirement accounts or H.R. 10 plans as participants).
            </li>
            <li>
              <Checkbox name="checkbox061" className="form-check-input" /> &nbsp; An entity, each and every beneficial owner of which is accurately
              described by one or more of the foregoing paragraphs of this Section 1 or is itself an entity each and every beneficial owner of which
              is accurately described by one or more of the foregoing paragraphs of this Section 1. If the Subscriber is a qualified purchaser solely
              for the reason described in this paragraph 1(g), a separate qualified purchaser questionnaire must be submitted for each beneficial
              owner of the Subscriber’s securities.
            </li>
            <li>
              <Checkbox name="checkbox062" className="form-check-input" /> &nbsp; NOT accurately described by any of the foregoing paragraphs of this
              Section 1.
            </li>
          </ol>
        </li>
        <li>
          If the Subscriber is a qualified purchaser solely for the reasons referenced in paragraphs 1(c) or 1(g), the Subscriber agrees to restrict
          direct and indirect transfers of beneficial interests in the Subscriber to, in the case of 1(c), qualified family members and, in the case
          of 1(g), qualified purchasers.
        </li>
        <li>
          If the Subscriber is a company formed on or before April 30, 1996 that relies on the exceptions provided for in Section 3(c)(1) or 3(c)(7)
          of the Investment Company Act to be exempt from registration as an investment company under the Investment Company Act, the Subscriber
          hereby represents and warrants that all consents required under the Investment Company Act to the Subscriber’s treatment as a qualified
          purchaser have been obtained.
        </li>
      </ol>
      <br />
      <h5 className="text-center">Appendix to Attachment (D)</h5>
      <br />
      <p className="text-center">Definitions</p>
      <ol>
        <li>
          Investments. <br />
          <ol type="a">
            <li>
              The term “Investments” shall mean: <br />
              <ol type="i">
                <li>
                  Securities, other than securities of an issuer that controls, is controlled by, or is under common control with, the Prospective
                  Qualified Purchaser (as defined below in paragraph 1(i)(vi)) that owns such securities, unless the issuer of such securities is:{' '}
                  <br />
                  <ol>
                    <li>An Investment Vehicle (as defined below in paragraph 1(i)(iv));</li>
                    <li>A Public Company (as defined below in paragraph 1(i)(vii)); or</li>
                    <li>
                      A Company with shareholders’ equity of not less than $50 million (determined in accordance with generally accepted accounting
                      principles) as reflected on the Company’s most recent financial statements, provided that such financial statements present the
                      information as of a date within 16 months preceding the date on which the Prospective Qualified Purchaser acquires the
                      securities of a Section 3(c)(7) Company (as defined below in paragraph 1(i)(ix));
                    </li>
                  </ol>
                </li>
                <li>Real estate held for investment purposes (as defined below in paragraph 1(b));</li>
                <li>
                  Commodity Interests (as defined below in paragraph 1(i)(i)) held for investment purposes (as defined below in paragraph 1(b));
                </li>
                <li>
                  Physical Commodities (as defined below in paragraph 1(i)(v)) held for investment purposes (as defined below in paragraph 1(b));
                </li>
                <li>
                  To the extent not securities, financial contracts (as such term is defined in section 3(c)(2)(B)(ii) of the Investment Company Act)
                  entered into for investment purposes (as defined below in paragraph 1(b));
                </li>
                <li>
                  In the case of a Prospective Qualified Purchaser that is a Section 3(c)(7) Company (as defined below in paragraph (i)(ix)), a
                  Company that would be an Investment Company but for the exclusion provided by Section 3(c)(1) of the Investment Company Act or a
                  commodity pool, any amounts payable to such Prospective Qualified Purchaser pursuant to a firm agreement or similar binding
                  commitment pursuant to which a person has agreed to acquire an interest in, or make capital contributions to, the Prospective
                  Qualified Purchaser upon the demand of the Prospective Qualified Purchaser; and
                </li>
                <li>
                  Cash and cash equivalents (including foreign currencies) held for investment purposes. For purposes of this section, cash and cash
                  equivalents include: <br />
                  <ol>
                    <li>
                      Bank deposits, certificates of deposit, bankers acceptances and similar bank instruments held for investment purposes; and
                    </li>
                    <li>The net cash surrender value of an insurance policy.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Investment Purposes. For purposes of defining “Investments”: <br />
              <ol type="i">
                <li>
                  Real estate shall not be considered to be held for investment purposes by a Prospective Qualified Purchaser (as defined below in
                  paragraph 1(i)(vi)) if it is used by the Prospective Qualified Purchaser or a Related Person (as defined below in paragraph
                  (i)(viii)) for personal purposes or as a place of business, or in connection with the conduct of the trade or business of the
                  Prospective Qualified Purchaser or a Related Person, provided that real estate owned by a Prospective Qualified Purchaser who is
                  engaged primarily in the business of investing, trading or developing real estate in connection with such business may be deemed to
                  be held for investment purposes. Residential real estate shall not be deemed to be used for such personal purposes if deductions
                  with respect to such real estate are not disallowed by Code Section 280A.
                </li>
                <li>
                  A Commodity Interest (as defined below in paragraph 1(i)(i)) or Physical Commodity (as defined below in paragraph 1(i)(v)) owned, or
                  a financial contract entered into, by the Prospective Qualified Purchaser who is engaged primarily in the business of investing,
                  reinvesting, or trading in Commodity Interests, Physical Commodities or financial contracts in connection with such business may be
                  deemed to be held for investment purposes.
                </li>
              </ol>
            </li>
            <li>
              Valuation. For purposes of determining whether a Prospective Qualified Purchaser (as defined below in paragraph 1(i)(vi)) is a Qualified
              Purchaser, the aggregate amount of Investments owned and invested on a discretionary basis by the Prospective Qualified Purchaser shall
              be the Investments’ fair market value on the most recent practicable date or their cost, provided that: <br />
              <ol type="i">
                <li>
                  In the case of Commodity Interests (as defined below in paragraph (i)(i)), the amount of Investments shall be the value of the
                  initial margin or option premium deposited in connection with such Commodity Interests; and
                </li>
                <li>
                  In each case, there shall be deducted from the amount of Investments owned by the Prospective Qualified Purchaser the amounts
                  specified in paragraphs (d) and (e) of this paragraph 1, as applicable.
                </li>
              </ol>
            </li>
            <li>
              Deductions. In determining whether any person is a Qualified Purchaser there shall be deducted from the amount of such person’s
              Investments the amount of any outstanding indebtedness incurred to acquire or for the purpose of acquiring the Investments owned by such
              person.
            </li>
            <li>
              Deductions: Family Companies. In determining whether a Family Company is a Qualified Purchaser, in addition to the amounts specified in
              paragraph (d) of this paragraph 1, there shall be deducted from the value of such Family Company’s Investments any outstanding
              indebtedness incurred by an owner of the Family Company to acquire such Investments.
            </li>
            <li>
              Joint Investments. In determining whether a natural person is a Qualified Purchaser, there may be included in the amount of such
              person’s Investments any Investments held jointly with such person’s spouse, or Investments in which such person shares with such
              person’s spouse a community property or similar shared ownership interest. In determining whether spouses who are making a joint
              investment in a Section 3(c)(7) Company (as defined below in paragraph 1(i)(ix)) are Qualified Purchasers, there may be included in the
              amount of each spouse’s Investments any Investments owned by the other spouse (whether or not such Investments are held jointly). In
              each case, there shall be deducted from the amount of any such Investments the amounts specified in paragraph 1(d) above incurred by
              each spouse.
            </li>
            <li>
              Investments by Subsidiaries. For purposes of determining the amount of Investments owned by a Company pursuant to this paragraph 1,
              there may be included Investments owned by majority-owned subsidiaries of the Company and Investments owned by a Company (“Parent
              Company”) of which the Company is a majority-owned subsidiary, or by a majority-owned subsidiary of the Company and other majority-owned
              subsidiaries of the Parent Company.
            </li>
            <li>
              Certain Retirement Plans and Trusts. In determining whether a natural person is a Qualified Purchaser, there may be included in the
              amount of such person’s Investments any Investments held in an individual retirement account or similar account the Investments of which
              are directed by and held for the benefit of such person. <br />
              <ol type="i">
                <li>
                  Miscellaneous Definitions Relating to Investments. <br />
                  <ol type="i">
                    <li>
                      The term “Commodity Interests” means commodity futures contracts, options on commodity futures contracts, and options on
                      Physical Commodities traded on or subject to the rules of: <br />
                      <ol>
                        <li>
                          Any contract market designated for trading such transactions under the U.S. Commodity Exchange Act, as amended, and the
                          rules thereunder; or
                        </li>
                        <li>
                          Any board of trade or exchange outside the United States, as contemplated in Part 30 of the rules under the Commodity
                          Exchange Act
                        </li>
                      </ol>
                    </li>
                    <li>The term “Company” means any corporation, partnership, limited liability company, trust or other organization.</li>
                    <li>
                      The term “Family Company” means any Company owned directly or indirectly by or for two or more natural persons who are related
                      as siblings or spouse (including former spouses), or direct lineal descendants by birth or adoption, spouses of such persons,
                      the estates of such persons, or foundations, charitable organizations, or trusts established by or for the benefit of such
                      persons.
                    </li>
                    <li>
                      The term “Investment Vehicle” means an investment company, a company that would be an investment company but for the exceptions
                      provided by Sections 3(c)(1) through 3(c)(9) of the Investment Company Act or the exemptions provided by Rules 3a-6 or 3a-7
                      promulgated pursuant to the Investment Company Act, or a commodity pool.
                    </li>
                    <li>
                      The term “Physical Commodity” means any physical commodity with respect to which a Commodity Interest is traded on a market
                      specified in the definition of Commodity Interests.
                    </li>
                    <li>The term “Prospective Qualified Purchaser” means a person seeking to purchase a security of a Section 3(c)(7) Company.</li>
                    <li>
                      The term “Public Company” means a Company that: <br />
                      <ol>
                        <li>Files reports pursuant to Section 13 or 15(d) of the Exchange Act; or</li>
                        <li>
                          Has a class of securities that are listed on a “designated offshore securities market” as such term is defined by Regulation
                          S under the Securities Act.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The term “Related Person” means a person who is related to a Prospective Qualified Purchaser as a sibling, spouse or former
                      spouse, or is a direct lineal descendant or ancestor by birth or adoption of the Prospective Qualified Purchaser, or is a spouse
                      of such descendant or ancestor, provided that, in the case of a Family Company, a Related Person includes any owner of the
                      Family Company and any person who is a Related Person of such owner.
                    </li>
                    <li>
                      The term “Section 3(c)(7) Company” means a Company that would be an Investment Company but for the exclusion provided by Section
                      3(c)(7) of the Investment Company Act.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Qualified Institutional Buyer. The term “Qualified Institutional Buyer” shall mean: <br />
          <ol type="a">
            <li>
              Any of the following entities, acting for its own account or the account of another Qualified Institutional Buyer or the account of a
              Qualified Purchaser, that in the aggregate owns and invests on a discretionary basis at least $100 million in securities of issuers that
              are not affiliated with the entity: <br />
              <ol type="i">
                <li>
                  Any insurance company as defined in Section 2(13) of the Securities Act; <br />
                  Note: A purchase by an insurance company for one or more of its separate accounts, as defined by Section 2(a)(37) of the Investment
                  Company Act which are neither registered under Section 8 of the Investment Company Act nor required to be so registered, shall be
                  deemed to be a purchase for the account of such insurance company.
                </li>
                <li>
                  Any investment company registered under the Investment Company Act or any business development company as defined in Section
                  2(a)(48) of that Act;
                </li>
                <li>
                  Any Small Business Investment Company licensed by the United States Small Business Administration under Section 301(c) or (d) of the
                  SBIA or any Rural Business Investment Company as defined in Section 384A of the Consolidated Farm and Rural Development Act;
                </li>
                <li>
                  Any plan established and maintained by a state, its political subdivisions, or any agency or instrumentality of a state or its
                  political subdivisions for the benefit of its employees; provided that such plan will not be deemed to be acting for its own account
                  if investment decisions with respect to such plan are made by beneficiaries of such plan, except with respect to investment
                  decisions made solely by the fiduciary, trustee or sponsor of such plan;
                </li>
                <li>Any employee benefit plan within the meaning of Title I of ERISA;</li>
                <li>
                  Any trust fund whose trustee is a bank or trust company and whose participants are exclusively plans of the types identified in
                  subparagraph (iv) or (v) of this definition of Qualified Institutional Buyer, except trust funds that include as participants
                  individual retirement accounts or H.R. 10 plans; and except further that such a trust fund that holds the assets of such a plan will
                  not be deemed to be acting for its own account if investment decisions with respect to such plan are made by the beneficiaries of
                  such plan, except with respect to investment decisions made solely by the fiduciary, trustee or sponsor of such plan;
                </li>
                <li>Any business development company as defined in Section 202(a)(22) of the Advisers Act;</li>
                <li>
                  Any organization described in Code Section 501(c)(3) , corporation (other than a bank as defined in Section 3(a)(2) of the
                  Securities Act or a savings and loan association or other institution referenced in Section 3(a)(5)(A) of the Securities Act or a
                  foreign bank or savings and loan association or equivalent institution), partnership, limited liability company, or Massachusetts or
                  similar business trust;
                </li>
                <li>Any investment adviser registered under the Advisers Act; and</li>
                <li>
                  Any institutional accredited investor, as defined in Rule 501(a) under the Securities Act, of a type not listed in paragraphs
                  (a)(1)(i)(A) through (I) or paragraphs (a)(1)(ii) through (vi) of Rule 501(a) 4, which may be formed for the purpose of acquiring
                  interests in the Partnership.
                </li>
              </ol>
            </li>
            <li>
              Any dealer registered pursuant to Section 15 of the Exchange Act, acting for its own account or the accounts of other Qualified
              Institutional Buyers, that in the aggregate owns and invests on a discretionary basis at least $25 million of securities of issuers that
              are not affiliated with the dealer, provided, that securities constituting the whole or a part of an unsold allotment to or subscription
              by a dealer as a participant in a public offering shall not be deemed to be owned by such dealer.
            </li>
            <li>
              Any dealer registered pursuant to Section 15 of the Exchange Act acting in a riskless principal transaction on behalf of a Any dealer
              registered pursuant to Section 15 of the Exchange Act acting in a riskless principal transaction on behalf of a Qualified Institutional
              Buyer. Qualified Institutional Buyer.
              <br />
              Note: A registered dealer may act as agent, on a non-discretionary basis, in a transaction with a Qualified Institutional Buyer without
              itself having to be a Qualified Institutional Buyer.
            </li>
            <li>
              Any investment company registered under the Investment Company Act, acting for its own account or for the accounts of other Qualified
              Institutional Buyers, that is a part of a family of investment companies which own in the aggregate at least $100 million in securities
              of issuers, other than issuers that are affiliated with the investment company or are part of such family of investment companies.
              “Family of investment companies” means any two or more investment companies registered under the Investment Company Act, except for a
              unit investment trust whose assets consist solely of shares of one or more registered investment companies, that have the same
              investment adviser (or, in the case of unit investment trusts, the same depositor), provided, that, for purposes of this section: <br />
              <ol type="i">
                <li>
                  Each series of a series company (as defined in Rule 18f-2 under the Investment Company Act) shall be deemed to be a separate
                  investment company; and
                </li>
                <li>
                  Investment companies shall be deemed to have the same adviser (or depositor) if their advisers (or depositors) are majority-owned
                  subsidiaries of the same parent, or if one investment company’s adviser (or depositor) is a majority-owned subsidiary of the other
                  investment company’s adviser (or depositor).
                </li>
              </ol>
            </li>
            <li>
              Any entity, all of the equity owners of which are Qualified Institutional Buyers, acting for its own account or the accounts of other
              Qualified Institutional Buyers.
            </li>
            <li>
              Any bank as defined in Section 3(a)(2) of the Securities Act, any savings and loan association or other institution as referenced in
              Section 3(a)(5)(A) of the Securities Act, or any foreign bank or savings and loan association or equivalent institution, acting for its
              own account or the accounts of other Qualified Institutional Buyers, that in the aggregate owns and invests on a discretionary basis at
              least $100 million in securities of issuers that are not affiliated with it and that has an audited net worth of at least $25 million as
              demonstrated in its latest annual financial statements, as of a date not more than 16 months preceding the date of purchase of an
              Interest in the case of a United States bank or savings and loan association, and not more than 18 months preceding such date of
              purchase of an Interest for a foreign bank or savings and loan association or equivalent institution.
            </li>
            <li>
              n determining the aggregate amount of securities owned and invested on a discretionary basis by an entity, the following instruments and
              interests shall be excluded: bank deposit notes and certificates of deposit; loan participations; repurchase agreements; securities
              owned but subject to a repurchase agreement, and currency, interest rate and commodity swaps.
            </li>
            <li>
              The aggregate value of securities owned and invested on a discretionary basis by an entity shall be the cost of such securities, except
              where the entity reports its securities holdings in its financial statements on the basis of their market value, and no current
              information with respect to the cost of those securities has been published. In the latter event, the securities may be valued at market
              price for purposes of this definition of “Qualified Institutional Buyer.”
            </li>
            <li>
              In determining the aggregate amount of securities owned by an entity and invested on a discretionary basis, securities owned by
              subsidiaries of the entity that are consolidated with the entity in its financial statements prepared in accordance with generally
              accepted accounting principles may be included if the investments of such subsidiaries are managed under the direction of the entity,
              except that, unless the entity is a reporting company under Section 13 or 15(d) of the Exchange Act, securities owned by such
              subsidiaries may not be included if the entity itself is a majority-owned subsidiary that would be included in the consolidated
              financial statements of another enterprise.
            </li>
            <li>
              For purposes of this definition of Qualified Institutional Buyer, “riskless principal transaction” means a transaction in which a dealer
              buys a security from any person and makes a simultaneous offsetting sale of such security to a Qualified Institutional Buyer, including
              another dealer acting as riskless principal for a Qualified Institutional Buyer.
            </li>
          </ol>
        </li>
      </ol>
      <br />
      <h5 className="text-center">ATTACHMENT (E)</h5>
      <br />
      <h5 className="text-center">TAX OWNER</h5>
      <br />
      <p>If the Subscriber is an entity, please check the box that applies:</p>
      <table>
        <tr>
          <td>
            <Checkbox name="checkbox063" className="form-check-input" />
          </td>
          <td>
            The Subscriber is not a Disregarded Entity (as defined in the Subscription Instructions) and is not a grantor trust for U.S. federal
            income tax purposes.
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox064" className="form-check-input" />
          </td>
          <td>
            The Subscriber is a Disregarded Entity or is a grantor trust for U.S. federal income tax purposes. The Subscriber acknowledges and agrees
            that the provisions of Article 7 (“Transfers of Limited Partnership Interests”) of the Partnership Agreement will apply, in addition to
            the Subscriber, to each of the following as applicable: <br />
            <ol type="a">
              <li>
                (i) the Subscriber’s Owner (as defined in the Subscription Instructions), (ii) each grantor of other owner of such grantor trust (each
                such person described in clause (i) or (ii ) above, a “Tax Owner”), and (iii) each intermediate entity as if each such owner were a
                Limited Partner under the Partnership Agreement and
              </li>
              <li>
                to any transaction or other event pursuant to which a Tax Owner ceases to be treated as the owner of the Subscriber’s Interest for
                U.S. federal income tax purposes.
              </li>
            </ol>
          </td>
        </tr>
      </table>
      <br />
      <h5 className="text-center">ATTACHMENT (F)</h5>
      <br />
      <h5 className="text-center">
        TAX STATUS FORM -- INFORMATION REGARDING BENEFICIAL <br /> OWNERSHIP FOR U.S. FEDERAL INCOME TAX PURPOSES
      </h5>
      <br />
      <h5 className="text-center">Instructions</h5>
      <br />
      <p>
        This Attachment (F) summarizes certain U.S. federal income tax restrictions applicable to any subsidiary of the Partnership that elects to be
        a real estate investment trust under the Code (a “Subsidiary REIT”) and includes a Tax Status Form for the Subscriber and the Subscriber’s tax
        advisor to review and complete with regard to the tax status and Beneficial Ownership of the Subscriber and the Subscriber’s investment in the
        Partnership.
      </p>
      <p>
        <strong>Closely Held Analysis</strong>
      </p>
      <p>
        A real estate investment trust (“REIT”) cannot maintain its REIT status if it is “closely held.” A REIT will be treated as closely held if,
        during the last half of any taxable year (other than its first taxable year), five or fewer investors classified as “individuals” are treated
        as the Beneficial Owners of more than 50% of the value of the equity of the REIT. In determining whether a Subsidiary REIT is closely held
        under these rules, each partner of the Partnership will be treated as owning its proportionate share of the beneficial interests of the
        Subsidiary REIT held by the Partnership. Moreover, in the case of a subscriber that is an entity (an “Entity Subscriber”), the Beneficial
        Owners of the Entity Subscriber may, in turn, be considered Beneficial Owners of the Subsidiary REIT. Although the Partnership expects that
        the number of subscribers and the tax status of the Entity Subscribers will enable a Subsidiary REIT to avoid being treated as closely held,
        the Partnership needs to understand the tax status of each subscriber in order to confirm that a Subsidiary REIT will not be closely held.
      </p>
      <div>
        <p>
          The following is a description of the rules defining the terms “Individual” and “Beneficial Ownership” as used in the Tax Status Form and a
          brief summary of how such rules may apply to most typical Subscribers. The term “Individual” means an individual within the meaning of Code
          Section 542(a)(2), as modified by Code Section 856(h)(3). Natural persons and the following organizations are treated as Individuals for
          these purposes: <br />
        </p>

        <ul>
          <li>
            A trust created by an employer for the exclusive benefit of its employees which is described in Code Section 401(a) (a “Qualified Trust”);
          </li>
          <li>A pension trust under Code Section 501(c)(17) (i.e., a trust that provides for payment of supplemental unemployment compensation);</li>
          <li>A private foundation under Code Section 509(a); and</li>
          <li>
            A portion of a trust permanently set aside or used for charitable purposes as described in Code Section 642(c) (i.e., charitable trusts
            for estate planning purposes).
          </li>
        </ul>
      </div>
      <p>
        Even though a Qualified Trust is treated as an Individual for purposes of Code Section 542(a)(2), under Code Section 856(h)(3), a look-through
        rule generally applies pursuant to which stock held by a Qualified Trust is treated as held directly by its beneficiaries in proportion to
        their actuarial interests in the Qualified Trust. However, if a Subsidiary REIT relies on such look-through rule in order to avoid a
        closely-held determination, the Subsidiary REIT could be a “pension-held REIT.” In general, entities other than those described above are not
        treated as Individuals.
      </p>
      <p>
        The term “Beneficial Ownership” means ownership of an equity interest by an entity or Individual for purposes of Code Section 542(a)(2),
        whether the equity interest is held directly or indirectly (including by a nominee or through other entities), taking into account the
        constructive ownership rules of Code Section 544, as modified by Code Section 856(h)(1)(B), and treating any such equity interest as “stock”
        for purposes of applying such Code Sections. In general, under these rules, beneficial interests of a Subsidiary REIT held, directly or
        indirectly, by or for (i) a corporation, partnership, estate, or trust shall be considered as owned proportionately by its shareholders,
        partners or beneficiaries, and (ii) a natural person’s spouse, brothers and sisters (whether by the whole or half blood), ancestors and lineal
        descendants are treated as owned by that person. In addition, if any entity or Individual has an option to acquire beneficial interests of a
        Subsidiary REIT (or an option to acquire such an option or one of a series of such options), such beneficial interests of the Subsidiary REIT
        shall be treated as owned by such entity or Individual.
      </p>
      <p>
        Under these rules, each subscriber will be treated as Beneficially Owning its proportionate share of the stock of a Subsidiary REIT held by
        the Partnership. Moreover, in the case of a subscriber that is not an Individual, each Beneficial Owner of the subscriber will be treated as
        Beneficially Owning its proportionate share of the Subsidiary REIT beneficial interests held, directly or indirectly, by the subscriber. As
        used in these Tax Status Instructions and in the Tax Status Form, the terms “Beneficial Owner,” “Beneficially Own,” “Beneficially Owns,”
        “Beneficially Owned” and “Beneficially Owning” have correlative meanings.
      </p>
      <p>
        <strong>Domestic Control Analysis</strong>
      </p>
      <p>
        If a foreign investor recognizes gain from the sale or disposition of securities or other capital assets that constitute United States real
        property interests (“USRPIs”), the foreign investor generally is subject to tax in the United States under the Foreign Investment in U.S. Real
        Property Tax Act of 1980, as amended. Absent an exception, stock of a Subsidiary REIT held, directly or indirectly, by a foreign investor
        generally would constitute a USRPI. However, stock of the Subsidiary REIT will not constitute a USRPI if the Subsidiary REIT is a
        “domestically controlled qualified investment entity.” A REIT may qualify as a domestically controlled qualified investment entity if at all
        times during a specified testing period, less than 50% in value of its outstanding shares are held directly or indirectly by foreign persons.
        In order to ascertain a Subsidiary REIT’s status as a domestically controlled qualified investment entity, the Partnership may from time to
        time collect from subscribers certain information regarding direct and indirect foreign ownership.
      </p>
      <p>Please consult with a tax advisor as necessary to answer the questions below.</p>
      <p>
        <strong>Part I: Subscriber Tax Status</strong>
      </p>
      <p>
        <Checkbox name="checkbox065" className="form-check-input" /> Check here if the Subscriber is a Disregarded Entity (as defined in the
        Subscription Instructions), in which case the Subscriber should complete Parts I - III as if each reference to the “Subscriber” were replaced
        with a reference to the “Subscriber’s Owner” (as defined in the Subscription Instructions).
      </p>
      <p>
        In order to assist the Partnership in determining the Beneficial Ownership of a Subsidiary REIT, please check the category below applicable to
        the Subscriber’s status. If the Subscriber is not classified in any of the categories set forth in (a) through (j) of Part I below, then the
        Subscriber must complete paragraphs (a) and (b) of Part II below.
      </p>
      <ol type="a">
        <li>
          <Checkbox name="checkbox066" className="form-check-input" /> The Subscriber is a natural person and either: <br />
          <ol type="i">
            <li>
              <Checkbox name="checkbox067" className="form-check-input" /> no spouse, brother or sister (whether by whole or half blood), ancestor or
              lineal descendant (any such person, a “Related Person”) of the Subscriber Beneficially Owns an interest in the Partnership; or
            </li>
            <li>he names of all Related Persons are listed in Part III of this Tax Status Form.</li>
          </ol>
        </li>
        <li>
          <Checkbox name="checkbox068" className="form-check-input" /> The Subscriber is a trust described in Code Section 401(a) and exempt from tax
          under Code Section 501(a) and is not a governmental plan within the meaning of Code Section 414(d) and no beneficiary of the Subscriber
          holds an actuarial interest in the Subscriber in excess of 1%.
        </li>
        <li>
          {' '}
          <Checkbox name="checkbox069" className="form-check-input" /> The Subscriber is a private foundation within the meaning of Code Section
          509(a).
        </li>
        <li>
          {' '}
          <Checkbox name="checkbox070" className="form-check-input" /> The Subscriber is described in Code Section 501(c)(17) (a trust providing for
          payment of supplemental unemployment compensation benefits).
        </li>
        <li>
          <Checkbox name="checkbox071" className="form-check-input" /> The Subscriber is a portion of a trust permanently set aside for a charitable
          or other purpose as described in Code Section 642(c).
        </li>
        <li>
          <Checkbox name="checkbox072" className="form-check-input" /> The Subscriber is exempt from tax under Code Section 501(c)(3) and is not a
          private foundation within the meaning of Code Section 509(a).
        </li>
        <li>
          <Checkbox name="checkbox073" className="form-check-input" /> The Subscriber is a governmental plan within the meaning of Code Section 414(d)
          and either: <br />
          <ol type="i">
            <li>
              <Checkbox name="checkbox074" className="form-check-input" /> the Subscriber is a trust described in Code Section 401(a) and is exempt
              from tax under Code Section 501(a), and no beneficiary holds an actuarial interest in the Subscriber in excess of 1%; or
            </li>
            <li>
              <Checkbox name="checkbox075" className="form-check-input" /> the Subscriber is not a trust described in Code Section 401(a) and no
              beneficiary holds an actuarial interest in the Subscriber in excess of 1%.
            </li>
          </ol>
        </li>
        <li>
          {' '}
          <Checkbox name="checkbox076" className="form-check-input" /> The Subscriber is a voluntary employees’ beneficiary association described in
          Code Section 501(c)(9) and no beneficiary holds an interest in the Subscriber in excess of 1%.
        </li>
        <li>
          {' '}
          <Checkbox name="checkbox077" className="form-check-input" /> The Subscriber is a “foreign government” for purposes of Code Section 892, as
          defined in Treasury Regulations Section 1.892- 2T(a)(1), and no Individual will Beneficially Own more than 1% of the Subscriber’s Interest
          in the Partnership.
        </li>
        <li>
          <Checkbox name="checkbox078" className="form-check-input" /> The Subscriber is a corporation, limited liability company or partnership not
          otherwise described in paragraphs (a) through (i) and either: <br />
          <ol type="i">
            <li>
              <Checkbox name="checkbox079" className="form-check-input" /> no Individual will Beneficially Own more than 1% of the Subscriber’s
              Interest in the Partnership; or
            </li>
            <li>
              <Checkbox name="checkbox080" className="form-check-input" /> an Individual will Beneficially Own more than 1% of the Subscriber’s
              Interest in the Partnership, but no Individual will Beneficially Own more than 10% of the Subscriber’s Interest in the Partnership.
            </li>
          </ol>
        </li>
        <li>
          <Checkbox name="checkbox081" className="form-check-input" /> The Subscriber is a trust or estate not otherwise described in paragraphs (a)
          through (i), and each beneficiary of the Subscriber (a “Beneficiary”) and, if the Subscriber is a Grantor Trust, each grantor or other owner
          of the Subscriber (a “Grantor”), is a natural person, and either <br />
          <ol type="i">
            <li>
              <Checkbox name="checkbox082" className="form-check-input" /> no Beneficiary or Grantor is a Related Person with respect to another
              subscriber, beneficiary of another subscriber or grantor of another subscriber; or
            </li>
            <li>
              <Checkbox name="checkbox083" className="form-check-input" /> a Beneficiary or Grantor is a Related Person with respect to another
              subscriber, beneficiary of another subscriber or grantor of another subscriber, and Part II of this Tax Status Form discloses the name
              of any such person, such person’s status as subscriber, beneficiary or grantor and such person’s relationship to the Subscriber, the
              Beneficiary and/or the Grantor (as applicable).
            </li>
          </ol>
        </li>
      </ol>
      <p>
        <strong>Part II: Subscriber’s Tax Status Not Described in Part I</strong>
      </p>
      <p>
        If the Subscriber is not classified in any of the categories set forth in (a) through (k) of Part I above, or if the Subscriber indicated that
        paragraph (k)(ii) of Part I applies to the Subscriber, then the Subscriber must complete paragraphs (a) and (b) of this Part II below. In
        addition, the Subscriber must provide any additional information that the Partnership or the General Partner may request in order to ascertain
        whether more than 50% of the value of the equity of a Subsidiary REIT may be held by five or fewer Individuals or whether a Subsidiary REIT
        may constitute a pension-held REIT.
      </p>
      <ol type="a">
        <li>
          Please describe the Subscriber’s tax status under the Code (e.g., corporation, partnership, estate, trust, Grantor Trust, etc.):
          <div className="border-bottom">
            <span id="subscriberSTaxStatusUnderTheCode" className="vgs-input-container my-3">
              subscriberSTaxStatusUnderTheCode
            </span>
          </div>
        </li>
        <li>
          Please describe below or attach a statement identifying: (i) the number of Beneficial Owners of the Subscriber; (ii) the tax status of each
          such Beneficial Owner under the Code; (iii) the relative interest of each such Beneficial Owner in the Subscriber (including, in the case of
          a partnership, the range of relative interests that each such Beneficial Owner may have in the Subscriber over the term of the Partnership,
          taking into account possible carried interests); (iv) whether any Beneficial Owners in the Subscriber use the same employer identification
          number for U.S. federal tax purposes; (v) the relationships, if any, among the Subscriber’s Beneficial Owners; and (vi) if the Subscriber
          has indicated that paragraph (k)(ii) of Part I applies to the Subscriber, the information required by such paragraph.
          <div className="border-bottom">
            <span id="describeBelowStatementSeveral" className="vgs-input-container my-3">
              describeBelowStatementSeveral
            </span>
          </div>
        </li>
      </ol>
      <p>
        <strong>Part III: Affiliates</strong>
      </p>
      <ol type="a">
        <li>
          To the best of the Subscriber’s knowledge, is the Subscriber (or any person who is treated as a Beneficial Owner of the Subscriber)
          affiliated with or related to any other investor in the Partnership? <br />
          <Radio name="radio10" options={['Yes', 'No']} />
          <br /> <br />
          <p>If “Yes,” please identify any such other investor and provide details of the affiliation:</p>
          <div className="d-flex align-items-center justify-content-between border-bottom">
            <span id="affiliateName1" className="vgs-input-container mx-2 my-3">
              affiliateName1
            </span>
            <span id="affiliateAffiliation1" className="vgs-input-container mx-2 my-3">
              affiliateAffiliation1
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-between border-bottom">
            <span id="affiliateName2" className="vgs-input-container mx-2 my-3">
              affiliateName2
            </span>
            <span id="affiliateAffiliation2" className="vgs-input-container mx-2 my-3">
              affiliateAffiliation2
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-between border-bottom">
            <span id="affiliateName3" className="vgs-input-container mx-2 my-3">
              affiliateName3
            </span>
            <span id="affiliateAffiliation3" className="vgs-input-container mx-2 my-3">
              affiliateAffiliation3
            </span>
          </div>
          <p>(If the space provided is insufficient, please refer to and attach a separate list in response.)</p>
        </li>
        <li>
          In the case of a Subscriber that is a trust described in Code Section 401(a) and exempt from tax under Code Section 501(a), the Subscriber
          certifies that no “disqualified person” (as defined in Code Section 4975(e)(2), without regard to subparagraphs (B) and (I) thereof) with
          respect to such Subscriber holds, directly or indirectly, any interest in the Partnership. <br />
          <Radio name="radio11" options={['Yes', 'No', 'Not Applicable']} />
          <br /> <br />
        </li>
      </ol>
      <p>
        <strong>Part IV: Foreign Ownership</strong>
      </p>
      <p>
        <strong>
          In order to assist the Partnership in determining its direct or indirect foreign ownership, the Subscriber must complete this Part IV unless
          otherwise agreed in writing by the General Partner.
        </strong>
      </p>
      <p>For purposes of this Part IV:</p>
      <p>“Disregarded Entity” means an entity that is disregarded as separate from its owner for U.S. federal income tax purposes.</p>
      <p>“Domestic Grantor Trust” means any Grantor Trust that is a Domestic Trust.</p>
      <p>“Domestic Look-Through Entity” means any U.S. Person that is not a Domestic Person.</p>
      <p>
        “Domestic Person” means (i) an ERISA Plan, (ii) a U.S. C-Corporation, (iii) a U.S. Charitable Organization, (iv) a U.S. Individual, (v) a U.S.
        Governmental Plan, (vi) any of a REIT, a RIC, an S-Corporation, a U.S. Partnership or Domestic Trust with, in the case of each such entity
        listed in this item (vi), a Foreign Ownership Percentage of 0%.
      </p>
      <p>
        “Domestic Trust” means any trust that (i) is subject to the primary supervision of a court within the United States, and one or more U.S.
        Persons have the authority to control all substantial decisions of the trust, or (ii) has a valid election in effect under applicable Treasury
        Regulations to be treated as a U.S. Person.
      </p>
      <p>
        “ERISA Plan” means a legal person organized under the laws of the United States or of any State that is established and maintained to provide
        pensions or other similar benefits in connection with employment pursuant to a plan described in Code Section 401(a) and that is exempt from
        tax under Code Section 501(a), but not including any U.S. Governmental Plan.
      </p>
      <p>
        “Foreign Estate” means any estate the income of which, from sources outside of the United States that is not effectively connected with the
        conduct of a trade or business within the United States, is not includible in the gross income of such estate for U.S. federal income tax
        purposes.
      </p>
      <p>
        “Foreign Ownership Percentage” means, with respect to the Subscriber as of the date of this Subscription Agreement, the portion of the
        Subscriber’s Interest that is held, by Foreign Persons directly or indirectly through any one or more U.S. Persons other than an ERISA Plan, a
        U.S. C-Corporation, a U.S. Charitable Organization or a U.S. Governmental Plan.
      </p>
      <p>
        “Foreign Person” means a “foreign person” for purposes of Code Section 897(h)(4)(B) and includes (i) any nonresident alien individual, (ii)
        any corporation or partnership that is not created or organized in the United States or under the law of the United States or of any state
        (unless, in the case of a partnership, applicable Treasury Regulations provide otherwise), (iii) any Foreign Estate, and (iv) any trust other
        than a Domestic Trust.
      </p>
      <p>
        “Grantor Trust” means a trust as to which the grantor or other person is treated as the owner of any portion of the trust under Code Sections
        671-679.
      </p>
      <p>
        “Grantor Trust Beneficiary” means any person who is treated for U.S. federal income tax purposes as the beneficiary of any portion of a
        Grantor Trust.
      </p>
      <p>
        “Grantor Trust Owner” means any person who is treated for U.S. federal income tax purposes as the owner of any portion of a Grantor Trust.
      </p>
      <p>“REIT” means any entity treated as a real estate investment trust under Code Sections 856-859.</p>
      <p>“RIC” means any entity treated as a regulated investment company under Code Sections 851-855.</p>
      <p>“S-Corporation” means any entity treated as an S-Corporation under Subchapter S of the Code.</p>
      <p>
        “Subscriber Certificate” means a certificate and agreement, duly executed by the Subscriber and, if applicable, the Subscriber’s Owner, in
        form and substance satisfactory to the General Partner, in its sole discretion, containing such representations, covenants and restrictions
        relating to the Subscriber and its direct and indirect owners as the General Partner may request confirming, (i) in the case of a Subscriber
        or, if applicable, the Subscriber’s Owner that is a Domestic Person, that the Subscriber or, if applicable, the Subscriber’s Owner will
        continue to be a Domestic Person at all times that the Subscriber holds an Interest in the Partnership, or (ii) in the case of a Subscriber
        or, if applicable, the Subscriber’s Owner that is a Domestic Look-Through Entity, that the Subscriber or, if applicable, the Subscriber’s
        Owner, will continue to be a U.S. Person and its Foreign Ownership Percentage will not exceed the Subscriber’s or, if applicable, the
        Subscriber’s Owner’s Foreign Ownership Percentage specified in this Attachment (F), in each case at all times that the Subscriber holds an
        Interest in the Partnership.
      </p>
      <p>
        “Subscriber’s Owner” means, if the Subscriber is a Disregarded Entity, the first direct or indirect owner of the Subscriber that is not a
        Disregarded Entity.
      </p>
      <p>
        “Transferee Certificate” means a certificate and agreement, duly executed by the transferee (and, if the transferee is a Disregarded Entity,
        the first direct or indirect owner of the transferee that is not a Disregarded Entity (the “transferee’s owner”)), in form and substance
        satisfactory to the General Partner, in its sole discretion, containing such representations, covenants and restrictions relating to the
        transferee and its direct and indirect owners as the General Partner may request confirming (i) in the case of a transfer by a Subscriber or,
        if applicable, Subscriber’s Owner that is a Domestic Person, that the transferee or, if applicable, the transferee’s owner, is and will be a
        Domestic Person at all times that the transferee holds an Interest in the Partnership, or (ii) in the case of a transfer by a Subscriber that
        is a Domestic Look-Through Entity, that the transferee or, if applicable, the transferee’s owner is and will be a U.S. Person and its Foreign
        Ownership Percentage will not exceed the transferring Subscriber’s or, if applicable, the transferring Subscriber’s Owner’s Foreign Ownership
        Percentage specified in this Attachment (F), in each case at all times that the transferee holds an Interest in the Partnership.
      </p>
      <p>
        “U.S. C-Corporation” means any entity created or organized in the United States or under the law of the United States or of any State and
        which is classified as a fully taxable Subchapter C corporation for U.S. federal income tax purposes.
      </p>
      <p>
        “U.S. Charitable Organization” means a corporation, foundation or other organization organized under the laws of the United States or any
        State or the District of Columbia that is described in Code Section 501(c)(3) and that is exempt from tax under Code Section 501(a).
      </p>
      <p>
        “U.S. Governmental Plan” means any pension trust or fund of any governing body of the United States or any State, or any political subdivision
        or local authority of the United States or any State, or any Person that is wholly owned, directly or indirectly, by any of the foregoing.
      </p>
      <p>“U.S. Individual” means an individual that is a U.S. Person.</p>
      <p>“U.S. Partnership” means a U.S. Person that is classified as a partnership for United States federal income tax purposes.</p>
      <p>
        “U.S. Person” means a “United States person” as defined by Code Section 7701(a)(30) and includes (i) a citizen or resident of the United
        States, (ii) a corporation or partnership created or organized in the United States or under the law of the United States or of any State
        (unless, in the case of a partnership, the Secretary provides otherwise by regulations), (iii) any estate other than a Foreign Estate, and
        (iv) a Domestic Trust.
      </p>
      <p>
        The Subscriber represents that it has checked the appropriate box(es) in (a) through (l) below that describe the Subscriber, and has completed
        any blanks in the paragraphs it checks below.
      </p>
      <ol>
        <li>
          Subscriber’s Domestic or Foreign Status:
          <p>
            <strong>
              If the Subscriber is a Disregarded Entity, then the Subscriber should complete this Part IV as if each reference to the “Subscriber”
              were replaced with a reference to the “Subscriber’s Owner.”
            </strong>
          </p>
          <ol type="a">
            <li>
              <Checkbox name="checkbox084" className="form-check-input" /> The Subscriber is a U.S. Individual.
            </li>
            <li>
              <Checkbox name="checkbox085" className="form-check-input" /> The Subscriber is a U.S. C-Corporation and its Foreign Ownership Percentage
              is zero.
            </li>
            <li>
              <Checkbox name="checkbox086" className="form-check-input" /> The Subscriber is a U.S. Charitable Organization.
            </li>
            <li>
              <Checkbox name="checkbox087" className="form-check-input" />
              The Subscriber is an ERISA Plan.
            </li>
            <li>
              <Checkbox name="checkbox088" className="form-check-input" /> The Subscriber is a U.S. Governmental Plan.
            </li>
            <li>
              <Checkbox name="checkbox089" className="form-check-input" /> The Subscriber is an S-Corporation and its Foreign Ownership Percentage is
              zero.
            </li>
            <li>
              <Checkbox name="checkbox090" className="form-check-input" /> The Subscriber is a Domestic Grantor Trust of which each Grantor Trust
              Owner and each Grantor Trust Beneficiary is a U.S. Individual.
            </li>
            <li>
              <Checkbox name="checkbox091" className="form-check-input" /> The Subscriber is U.S. Person and confirms that all of the following are
              true: <br />
              <ol type="i">
                <li>The Subscriber is a U.S. Person that is not described in clauses (a) - (g) above;</li>
                <li>
                  The Subscriber’s Foreign Ownership Percentage does not exceed zero (unless the following blank is completed, in which event, the
                  Subscriber’s Foreign Ownership Percentage does not exceed ___%); and
                </li>
                <li>
                  The Subscriber’s U.S. federal income tax classification is as indicated in the box checked below (only check one box): <br />
                  <p>
                    <Checkbox name="checkbox092" className="form-check-input" /> public REIT
                  </p>
                  <p>
                    <Checkbox name="checkbox093" className="form-check-input" /> private REIT
                  </p>
                  <p>
                    <Checkbox name="checkbox094" className="form-check-input" /> C-Corporation
                  </p>
                  <p>
                    <Checkbox name="checkbox095" className="form-check-input" /> S-Corporation
                  </p>
                  <p>
                    <Checkbox name="checkbox096" className="form-check-input" /> RIC
                  </p>
                  <p>
                    <Checkbox name="checkbox097" className="form-check-input" /> U.S. Partnership
                  </p>
                  <p>
                    <Checkbox name="checkbox098" className="form-check-input" /> Domestic Trust
                  </p>
                  <p>
                    <Checkbox name="checkbox099" className="form-check-input" /> Other – please indicate the U.S. federal income tax classification of
                    the Subscriber and provide such additional information related thereto as the Partnership may request.
                    <span id="federalIncomeTaxClassificationOfSubscriber" className="vgs-input-container mt-3">
                      federalIncomeTaxClassificationOfSubscriber
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <Checkbox name="checkbox100" className="form-check-input" />
              The Subscriber is a Foreign Person.
            </li>
            <li>
              <Checkbox name="checkbox101" className="form-check-input" /> The Subscriber:
              <ol type="i">
                <li>is not described in any of the above clauses (a) - (i) above; and</li>
                <li>
                  has signed an instrument satisfactory to the General Partner that (A) adequately describes the Subscriber and its direct and
                  indirect owners for the purposes herein, (B) sets forth the Subscriber’s Foreign Ownership Percentage, and (C) contains such
                  covenants relating to direct and indirect transfers of the Subscriber’s Interest in the Partnership as the Partnership may determine
                  are necessary or appropriate to permit the Partnership to ascertain and confirm any Subsidiary REIT’s status as a “domestically
                  controlled qualified investment entity” within the meaning of Code Section 897(h)(4).
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Agreements Relating to Ownership.</strong>
          </p>
          <p>Unless otherwise agreed in writing by the Partnership, the Subscriber further agrees as follows:</p>
          <ol type="a">
            <li>
              Except to the extent the requirements of clause (c) or (d), as applicable, of this Section 2 are satisfied, neither the Subscriber nor
              the Subscriber’s Owner shall take any action (or fail to take any action) that would cause the information provided by the Subscriber in
              Section 1 to cease to be true, correct and complete at all times during which the Subscriber holds an Interest in the Partnership. The
              Subscriber shall notify the Partnership promptly upon learning that any representation or covenant made in this Part IV has ceased to be
              or, in the future, may cease to be, true, accurate and complete.
            </li>
            <li>
              The Subscriber shall provide (and shall cause Subscriber’s Owner to provide, if applicable) to the Partnership such information as the
              Partnership may, from time to time, request relating to the direct and indirect ownership of the Subscriber as the Partnership may
              determine is necessary or appropriate to permit the Partnership to ascertain and confirm any Subsidiary REIT’s status as a “domestically
              controlled qualified investment entity” within the meaning of Code Section 897(h)(4) and to comply with requirements of any taxing
              authority or governmental authority or to determine such compliance.
            </li>
            <li>
              If the Subscriber checked any of clauses (a) through (h) of Section 1, in addition to the restrictions on transfer in the Partnership
              Agreement, neither the Subscriber nor the Subscriber’s Owner shall transfer, directly or indirectly (nor shall it permit the indirect
              transfer of) all or any portion of the Subscriber’s Interest in the Partnership except to a transferee that, prior to any such transfer,
              delivers to the Partnership a duly executed Transferee Certificate.
            </li>
            <li>
              If the Subscriber checked any of clauses (a) through (h) of Section 1, prior to taking any action (or failing to take any action) that
              is reasonably likely to cause the information provided by the Subscriber in Section 1 to fail to be true, correct and complete at all
              times during which the Subscriber or the Subscriber’s Owner, as applicable, holds an Interest in the Partnership, the Subscriber shall
              furnish the Partnership with a duly executed Subscriber Certificate.
            </li>
          </ol>
        </li>
      </ol>
      <p>
        <strong>Part V: Acknowledgements and Covenants</strong>
      </p>
      <p>
        The Subscriber shall provide to the General Partner such information as the General Partner may request, in good faith, from time to time, in
        order to determine a Subsidiary REIT’s status as a REIT , as a “domestically controlled qualified investment entity” within the meaning of
        Code Section 897(h)(4), or as a REIT that is not classified as a “pension-held REIT” within the meaning of Code Section 856(h)(3)(D), and to
        comply with requirements of any taxing authority or governmental authority or to determine such compliance.
      </p>
      <p>
        On behalf of itself, and, if applicable, the Subscriber’s Owner and Grantor Trust Owner, the Subscriber acknowledges that the Partnership
        Agreement contains restrictions on the direct or indirect transfer of the Subscriber’s interest in the Partnership in order to protect a
        Subsidiary REIT’s status as a REIT, as a “domestically controlled qualified investment entity” within the meaning of Code Section 897(h)(4),
        and as a REIT that is not classified as a “pension-held REIT” within the meaning of Code Section 856(h)(3)(D), and agrees to comply with such
        restrictions.
      </p>
      <p>
        The Subscriber covenants that it will notify the General Partner in writing promptly upon the Subscriber learning that any information
        provided to the Partnership on this Tax Status Form has ceased to be or, in the future, may cease to be, accurate and complete and shall
        furnish the General Partner with such updated information as may be necessary in order to ensure that the Subscriber’s responses to all
        portions of this Tax Status Form are, at all times, accurate and complete. The Subscriber further acknowledges that, notwithstanding its
        provision of updated certifications, the General Partner may nevertheless determine that U.S. federal taxes may need to be withheld with
        respect to Subscribers that are determined to be Foreign Persons, including in respect of gain from the sale or liquidation of the Subsidiary
        REITs or gain on the sale of assets by a Subsidiary REIT, in which case the Partnership shall be entitled to withhold with respect to
        allocations and distributions attributable to such Subscribers’ interest in the Partnership.
      </p>
      <br />
      <h5>
        <u>ATTACHMENT (G)</u>
      </h5>
      <br />
      <h5>
        <u>UBTI REPORTING INFORMATION</u>
      </h5>
      <br />
      <p>
        If the Subscriber is an entity, please check all boxes that apply regarding “unrelated business taxable income”, or UBTI, as such term is used
        in Code Sections 512 (a)(1) and 512. For purposes of this Attachment (G), if the Subscriber is a Disregarded Entity, then the Subscriber
        should complete this Attachment (G) as if each reference to the “Subscriber” were replaced with a reference to the first direct or indirect
        beneficial owner of the Subscriber that is not a Disregarded Entity.
      </p>
      <table>
        <tr>
          <td>
            <Checkbox name="checkbox102" className="form-check-input" />
          </td>
          <td>
            The Subscriber is a “qualified organization” within the meaning of Code Section 514(c)(9)(C) (a “Qualified Organization”). If the
            Subscriber is an organization described in Code Section 501(c)(25), it acknowledges that acquisition of an interest in the Partnership may
            result in loss of its exemption from U.S. federal income taxation.
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox103" className="form-check-input" />
          </td>
          <td>
            The Subscriber is an organization exempt from tax under Code Section 501(a) that is not a Qualified Organization. The Subscriber
            acknowledges that, if it is subject to taxation on its unrelated business taxable income, it will not be eligible for the exception from
            acquisition indebtedness provided under Code Section 514(c)(9) for certain real property acquired by a Qualified Organization.
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox104" className="form-check-input" />
          </td>
          <td>The Subscriber is subject to taxation on its unrelated business taxable income.</td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox105" className="form-check-input" />
          </td>
          <td>
            The Subscriber is not a Qualified Organization and a direct or indirect investor in the Subscriber (other than through an entity treated
            as a C corporation) is a Qualified Organization.
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox106" className="form-check-input" />
          </td>
          <td>
            The Subscriber is not a Qualified Organization and a direct or indirect investor in the Subscriber (other than through an entity treated
            as a C corporation) is an organization exempt from tax under Code Section 501(a) that is not a Qualified Organization. The Subscriber
            acknowledges that, if such direct or indirect investor in the Subscriber is subject to taxation on its unrelated business taxable income,
            such direct or indirect investor in the Subscriber will not be eligible for the exception from acquisition indebtedness provided under
            Section Code 514(c)(9) for certain real property acquired by a Qualified Organization.
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox107" className="form-check-input" />
          </td>
          <td>The Subscriber is not one of the above-described organizations.</td>
        </tr>
      </table>
      <br />
      <h5>
        <u>ATTACHMENT (H)</u>
      </h5>
      <br />
      <h5>
        <u>DOMESTIC GRANTOR TRUST CERTIFICATION</u>
      </h5>
      <br />
      <p>
        For purposes of this Attachment (H), if the Subscriber is a Disregarded Entity, then the Subscriber should complete this Attachment(H) as if
        each reference to the “Subscriber” were replaced with a reference to the first direct or indirect beneficial owner of the Subscriber that is
        not a Disregarded Entity.
      </p>
      <p>
        <strong>Please complete the following, only if the Subscriber is a Domestic Grantor Trust.</strong>
      </p>
      <p>The Subscriber is a “Domestic Grantor Trust” if it:</p>
      <ol>
        <li>
          is a trust as to which the grantor or other person is treated as the owner of any portion of the trust under Code Sections 671-679, and
        </li>
        <li>is a “United States person” as defined in Code Section 7701(a)(30).</li>
      </ol>
      <p>
        <strong>If the Subscriber is a Domestic Grantor Trust please check the applicable box below:</strong>
      </p>
      <ol type="i">
        <li>
          <Checkbox name="checkbox108" className="form-check-input me-2" />
          The Subscriber is wholly owned by a grantor or another person for U.S. federal income tax purposes.
          <br />
          <br />
          OR <br />
          <br />
        </li>
        <li>
          <Checkbox name="checkbox109" className="form-check-input me-2" /> The Subscriber is not described in clause (i) above. Please identify below
          the portion(s) of the Subscriber that is treated as owned by a grantor or another person under subpart E of subchapter J of the Code:
          <span id="portionOfSubscriberTreatedAsOwner" className="vgs-input-container mt-3">
            portionOfSubscriberTreatedAsOwner
          </span>
        </li>
      </ol>
      <br />
      <h5>
        <u>ATTACHMENT (I)</u>
      </h5>
      <br />
      <h5>
        <u>FIRPTA CERTIFICATE – AFFIDAVIT OF QFPF STATUS</u>
      </h5>
      <br />
      <p>
        If Subscriber is completing this Attachment (I) by reason of it being a qualified foreign pension fund as defined in Code Section 897(l) (a
        “QFPF”) or being an entity wholly-owned by a QFPF, please complete Part A of this Attachment (I) – “FIRPTA Certificate – Affidavit of QFPF
        Status.”
      </p>
      <p>If Subscriber is not a QFPF, Subscriber does not need to complete this Attachment (I).</p>
      <br />
      <h5>PART A</h5>
      <br />
      <h5>FIRPTA CERTIFICATE – AFFIDAVIT OF QFPF STATUS</h5>
      <br />
      <p>
        Under Section 897 of the Internal Revenue Code of 1986, as amended (the “Code”), gain or loss from the disposition of a U.S. real property
        interest and certain distributions from a real estate investment trust (a “REIT”) attributable to the REIT’s dispositions of U.S. real
        property interests are generally treated as gain or loss that is effectively connected with a trade or business within the United States. Code
        Section 1445 generally requires withholding with respect to certain distributions and transfers with respect to U.S. real property interests.
        Code Section 1446 generally requires withholding with respect to partnership allocations of gain to a foreign partner that is treated as
        effectively connected with a trade or business within the United States, including by reason of Code Section 897.
      </p>
      <p>
        Under Code Section 897(l) and Code Section 1445(f), neither a qualified foreign pension fund (a “QFPF”) nor an entity wholly owned by a QFPF
        (a “QFPF Entity”) is treated as a foreign person for purposes of Code Section 897 or Code Section 1445, respectively.
      </p>
      <p>
        For U.S. tax purposes (including Code Section 1445), the owner of a disregarded entity (which has legal title to a U.S. real property interest
        under local law) will be the distributee of the distribution or the transferor of the property and not the disregarded entity.
      </p>
      <p>
        To inform Cabot Industrial Core Fund III, L.P. that withholding of tax is not required with respect to the undersigned Subscriber (referred to
        in this Attachment (I) as the “Investor”) under Code Section 1445 or Code Section 1446 with respect to a distribution from a REIT attributable
        to the REIT’s disposition of a U.S. real property interest or gain from the disposition of a U.S. real property interest, the undersigned
        hereby certifies, as of the date of this document (and continuing until and unless the Investor notifies Cabot Industrial Core Fund III, L.P.
        otherwise), the following on behalf of Investor:
      </p>
      <ol>
        <li>
          Investor is (check applicable box): <br />
          <ol type="a">
            <li>
              <Checkbox name="checkbox110" className="form-check-input me-2" />
              not a foreign person for purposes of Code Section 897 and Code Section 1445(f) by reason of being a QFPF under Code Section 897(l); or
            </li>
            <li>
              <Checkbox name="checkbox111" className="form-check-input me-2" />
              not a foreign person for purposes of Code Section 897 and Code Section 1445(f) by reason of being a QFPF Entity.
            </li>
          </ol>
        </li>
        <li>If box 1(a) above is checked, Investor certifies that it qualifies as a “qualified foreign pension fund” under Code Section 897(l).</li>
        <li>
          If box 1(b) above is checked, Investor certifies that its sole owner qualifies as a “qualified foreign pension fund” under Code Section
          897(l).
        </li>
        <li>Investor is not a disregarded entity as defined in Treasury Regulations Section 1.1445-2(b)(2)(iii).</li>
        <li>
          Investor’s U.S. taxpayer identification number (“TIN”) (if available) and/or Foreign TIN, as applicable is{' '}
          <span id="investorsUSTaxpayerIdentificationNumber" className="vgs-input-container">
            investorsUSTaxpayerIdentificationNumber
          </span>
        </li>
        <li>
          Investor’s office address is{' '}
          <span id="investorsOfficeAddress" className="vgs-input-container">
            investorsOfficeAddress
          </span>
        </li>
      </ol>
      <p>
        Investor understands that this certification may be disclosed to the Internal Revenue Service by the transferee and that any false statement
        contained herein could be punished by fine, imprisonment, or both.
      </p>
      <p>
        Under penalties of perjury the undersigned declares that he/she has examined this certification and to the best of his/her knowledge and
        belief it is true, correct and complete. The undersigned further declares that he/she has the authority to sign this document on behalf of
        Investor (and, if box 1(b) above is checked, Investor’s sole owner) and that the undersigned is a responsible officer / general manager /
        trustee / executor within the meaning of Treasury Regulations Section 1.1445- 2(b)(2)(i).
      </p>
      <p>IN WITNESS WHEREOF, Investor has caused this certificate to be executed as of the date set forth below.</p>
      <p>Name of Investor:</p>
      <span id="nameOfInvestor" className="vgs-input-container my-2">
        nameOfInvestor
      </span>
      <div className="d-flex align-items-center">
        By:
        <span id="investorBy" className="vgs-input-container ms-3 my-2">
          investorBy
        </span>
      </div>
      <div className="d-flex align-items-center">
        Name:
        <span id="investorByName" className="vgs-input-container ms-3 my-2">
          investorByName
        </span>
      </div>
      <div className="d-flex align-items-center">
        Title:
        <span id="investorByTitle" className="vgs-input-container ms-3 my-2">
          investorByTitle
        </span>
      </div>
      <div className="d-flex align-items-center">
        Dated:
        <span id="investorByDated" className="vgs-input-container ms-3 my-2">
          investorByDated
        </span>
      </div>
      <br />
      <h5>
        <u>ATTACHMENT (J)</u>
      </h5>
      <br />
      <h5>
        <u>RULE 506(D) AND RULE 506(E) COMPLIANCE</u>
      </h5>
      <br />
      <p>
        For purposes of this Attachment (J), “Beneficial Owner” means any person who for purposes of Rule 506(d) and Rule 506(e) of the Securities Act
        beneficially owns or will beneficially own the Subscriber’s interest in the Partnership.
      </p>
      <p>Please check all boxes that apply:</p>
      <ol>
        <li>
          During the past ten years, has the Subscriber or any Beneficial Owner been convicted of any felony or misdemeanor (i) in connection with the
          purchase or sale of any security; (ii) arising out of the making of any false filing with the U.S. Securities and Exchange Commission (the
          “SEC”); or (iii) arising out of the conduct of an underwriter, broker, dealer, municipal securities dealer, investment adviser, or paid
          solicitor of purchasers of securities? <br />
          <Radio name="radio12" options={['Yes', 'No', 'Not Applicable']} />
        </li>
        <li>
          Is the Subscriber or any Beneficial Owner subject to any court injunction or restraining order entered during the past five years that
          currently restrains or enjoins the Subscriber or any Beneficial Owner from engaging or continuing to engage in any conduct or practice (i)
          in connection with the purchase or sale of any security; (ii) involving the making of any false filing with the SEC; or (iii) arising out of
          the conduct of an underwriter, broker, dealer, municipal securities dealer, investment adviser, or paid solicitor of purchasers of
          securities? <br />
          <Radio name="radio13" options={['Yes', 'No', 'Not Applicable']} />
        </li>
        <li>
          Is the Subscriber or any Beneficial Owner subject to any final order * of any governmental commission, authority, agency or officer **
          related to any securities, insurance, or banking matter that either
          <ol type="a">
            <li>
              currently bars the Subscriber or any Beneficial Owner from (i) associating with an entity regulated by such commission, authority,
              agency or officer; (ii) engaging in the business of securities, insurance or banking; or (iii) engaging in savings association or credit
              union activities; or
            </li>
            <li>
              was entered within the past ten years based on a violation of any law or regulation that prohibits fraudulent, manipulative, or
              deceptive conduct? <br />
              <Radio name="radio14" options={['Yes', 'No']} />
              <br /> <br />
              <table>
                <tr>
                  <td>*</td>
                  <td>
                    A “final order” is defined under Rule 501(g) as a written directive or declaratory statement issued by a federal or state agency
                    described in Rule 506(d)(1)(iii) under applicable statutory authority that provides for notice and an opportunity for a hearing,
                    and that constitutes a final disposition or action by such federal or state agency.
                  </td>
                </tr>
                <tr>
                  <td>**</td>
                  <td>
                    You may limit your response to final orders of: (i) state securities commissions (or state agencies/officers that perform a
                    similar function); (ii) state authorities that supervise or examine banks, savings associations, or credit unions; (iii) state
                    insurance commissions (or state agencies/officers that perform a similar function); (iv) federal banking agencies; (v) the U.S.
                    Commodity Futures Trading Commission; or (vi) the U.S. National Credit Union Administration.
                  </td>
                </tr>
              </table>
            </li>
          </ol>
        </li>
        <li>
          Is the Subscriber or any Beneficial Owner subject to any SEC disciplinary order*** that currently (i) suspends or revokes the Subscriber’s
          or any Beneficial Owner’s registration as a broker, dealer, municipal securities dealer, or investment adviser; (ii) places limitations on
          the Subscriber’s or any Beneficial Owner’s activities, functions, or operations; or (iii) bars the Subscriber or any Beneficial Owner from
          being associated with any particular entity or class of entities or from participating in the offering of any penny stock? <br />
          <Radio name="radio15" options={['Yes', 'No']} />
          <br /> <br />
          <table>
            <tr>
              <td>***</td>
              <td>
                A “final order” is defined under Rule 501(g) as a written directive or declaratory statement issued by a federal or state agency
                described in Rule 506(d)(1)(iii) under applicable statutory authority that provides for notice and an opportunity for a hearing, and
                that constitutes a final disposition or action by such federal or state agency.
              </td>
            </tr>
          </table>
        </li>
        <li>
          Is the Subscriber or any Beneficial Owner subject to any SEC cease and desist order entered within the past five years that currently
          requires the Subscriber or any Beneficial Owner to cease and desist from committing or causing a violation or future violation of (i) any
          knowledge-based anti-fraud provision of the U.S. federal securities laws**** or (ii) Section 5 of the Securities Act? <br />
          <Radio name="radio16" options={['Yes', 'No']} />
          <br /> <br />
          <table>
            <tr>
              <td>****</td>
              <td>
                Including(butnotlimitedto)Section17(a)(1)oftheSecurities Act, Section 10(b) of the Exchange Act and Rule 10b-5 thereunder, Section
                15(c)(1) of the Exchange Act, and Section 206(1) of the Advisers Act, or any other rule or regulation thereunder.
              </td>
            </tr>
          </table>
        </li>
        <li>
          Have the Subscriber or any Beneficial Owner been suspended or expelled from membership in, or suspended or barred from association with a
          member of, a registered national securities exchange or a registered national or affiliated securities association? <br />
          <Radio name="radio17" options={['Yes', 'No']} />
        </li>
        <li>
          Have the Subscriber or any Beneficial Owner registered a securities offering with the SEC, made an offering under Regulation A or been named
          as an underwriter in any registration statement or Regulation A offering statement filed with the SEC that during the past five years was
          the subject of a refusal order, stop order, or order suspending the Regulation A exemption, or is currently the subject of an investigation
          or proceeding to determine whether a stop order or suspension order should be issued? <br />
          <Radio name="radio18" options={['Yes', 'No']} />
        </li>
        <li>
          Is the Subscriber or any Beneficial Owner subject to a U.S. Postal Service false representation order entered within the past five years?
          <br />
          <Radio name="radio19" options={['Yes', 'No']} />
        </li>
        <li>
          Is the Subscriber or any Beneficial Owner currently subject to a temporary restraining order or preliminary injunction with respect to
          conduct alleged by the U.S. Postal Service to constitute a scheme or device for obtaining money or property through the mail by means of
          false representations? <br />
          <Radio name="radio20" options={['Yes', 'No']} />
          <p>
            <strong>
              <i>
                If you answered “yes” to any of the preceding questions, please contact the Partnership to discuss the relevant facts and discuss
                whether a supplemental submission will be required.
              </i>
            </strong>
          </p>
        </li>
        <li>
          Has the Subscriber made, or will the Subscriber make a supplemental submission to provide additional information relating to the questions
          on this Attachment? <br />
          <Radio name="radio21" options={['Yes', 'No']} />
        </li>
      </ol>
      <br />
      <h5>
        <u>ATTACHMENT (K)</u>
      </h5>
      <br />
      <h5>
        <u>CABOT PROPERTIES L.P.</u>
      </h5>
      <br />
      <h5>
        <u>PRIVACY POLICY</u>
      </h5>
      <br />
      <p>
        This Privacy Policy is provided on behalf of the funds, accounts and other clients we advise from time to time. Protecting our investors’
        nonpublic personal information is an important priority for us. Our privacy policy is designed to support this objective. We may collect
        nonpublic personal information about investors who invest in our funds for personal, family or householder purposes from the following
        sources:
      </p>
      <ul>
        <li>Information we receive from investors on subscription documents, partnership agreements or other forms; and</li>
        <li>Information about investors’ transactions with us or their investments in our funds.</li>
      </ul>
      <p>
        We do not disclose nonpublic personal information about current or former investors or clients to anyone, except as permitted by law. We may
        share nonpublic personal information with our affiliated advisory organizations. We may disclose this information to third parties that
        perform services for us. For example, we may forward this information to our accountants to complete financial statements and maintain
        accounting records, or we may forward this information to a bank or financial institution that extends credit to the fund or funds in which
        you invest.
      </p>
      <p>
        We also may disclose the following nonpublic personal information about current or former investors or clients to companies that perform
        marketing services on our behalf or to other financial institutions, such as placement agents, with whom we have joint marketing agreements in
        connection with offering new and existing funds:
      </p>
      <ul>
        <li>
          Information we receive from investors on subscription documents, partnership agreements or other forms, such as an investor’s name, contact
          information and financial profile.
        </li>
      </ul>
      <p>
        We restrict access to nonpublic personal information about our investors to those employees and service providers that use that information in
        connection with the offering, formation and operation of new and existing funds or managing and servicing investor accounts. We maintain
        physical, electronic, and procedural safeguards that comply with federal guidelines to protect nonpublic personal information.
      </p>
      <br />
      <h5>
        <u>ATTACHMENT (L)</u>
      </h5>
      <br />
      <h5>
        <u>Qualified Client Status</u>
      </h5>
      <br />
      <p>The Subscriber represents and warrants as follows (please check all applicable boxes):</p>
      <table>
        <tr>
          <td>
            <Checkbox name="checkbox112" className="form-check-input me-2" />
          </td>
          <td>
            The Subscriber is a natural person who satisfies one or more of the following criteria: (a) is a Qualified Purchaser (as defined in
            Section 2(51)(A) of the Investment Company Act of 1940); and/or (b) will have at least $1,100,000 under the management of the General
            Partner; and/or (c) has a net worth (together with assets held jointly with a spouse) of more than $2,200,000 (excluding the value of the
            primary residence of such natural person and the related amount of indebtedness secured by such primary residence up to its fair market
            value, except that if the amount of such indebtedness outstanding at the time of entering into this Subscription Agreement exceeds the
            amount of such indebtedness outstanding 60 days before such time, other than as a result of the acquisition of the primary residence, the
            amount of such excess shall be included as a liability in the determination of such natural person’s net worth).
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox113" className="form-check-input me-2" />
          </td>
          <td>
            The Subscriber is a company* that is not (i) a private investment company that would be an investment company under the Investment Company
            Act but for the exception in Section 3(c)(1) of the Investment Company Act, (ii) an investment company registered under the Investment
            Company Act; or (iii) a business development company (as defined in Section 202(a)(22) of the Advisers Act) (each, a “Look- Through
            Entity”) and satisfies one or more of the following criteria: (a) is a Qualified Purchaser (as defined in Section 2(51)(A) of the
            Investment Company Act of 1940); and/or (b) will have at least $1,100,000 under the management of the General Partner; and/or (c) has a
            net worth of more than $2,200,000.
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox114" className="form-check-input me-2" />
          </td>
          <td>
            The Subscriber is a company that is a Look-Through Entity, and each equity owner of the Subscriber (a “Subscriber Equity Owner”) (a) is a
            Qualified Client by virtue of the fact that such Subscriber Equity Owner is described in paragraphs (1) or (2) of this Attachment (L)
            (including by virtue of an indirect amount under the management of the General Partner, through the Partnership and/or another entity, of
            at least $1,100,000), and (b) is not itself a Look-Through Entity. If any Subscriber Equity Owner is itself a Look-Through Entity, then
            each equity owner thereof that is not a Look-Through Entity and each equity owner of any equity owner thereof that is a Look-Through
            Entity (looking through each successive tiers of Look-Through Entities until no direct or indirect equity owner is a Look-Through Entity)
            is a Qualified Client by virtue of the fact that each such equity owner is described in in paragraphs (1) or (2) of this Attachment (L)
            (including by virtue of an indirect amount under the management of the General Partner, through the Partnership and/or another entity, of
            at least $1,100,000).
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox115" className="form-check-input me-2" />
          </td>
          <td>None of the foregoing categories of this Attachment (L) apply to the Subscriber.</td>
        </tr>
      </table>
      <br />
      <h5>
        <u>ATTACHMENT (M)</u>
      </h5>
      <br />
      <h5>
        <u>Non-U.S. Legal Entity Status</u>
      </h5>
      <br />
      <p>
        If the Subscriber is a legal entity located outside of the United States, the Subscriber represents and warrants as follows (please check all
        applicable boxes). The General Partner may require additional documentation or representations from the Subscriber.
      </p>
      <p>
        <strong>
          <i>Australia</i>
        </strong>
      </p>
      <table>
        <tr>
          <td>
            <Checkbox name="checkbox116" className="form-check-input me-2" />
          </td>
          <td>
            <p>If the Subscriber is an investor in Australia, the Subscriber represents and warrants that:</p>
            <ol type="a">
              <li>the Subscriber is ‘wholesale client’ for the purposes of the Corporations Act 2001 (Cth);</li>
              <li>
                if the Subscriber on-sells the Interest to investors in Australia without a product disclosure statement within 12 months of the
                issuance of the Interest, the Subscriber will only on-sell the Interest to investors in Australia who are ‘wholesale clients’ for the
                purposes of the Corporations Act 2001 (Cth);
              </li>
              <li>
                the Subscriber acknowledges and agrees that the Memorandum is not a disclosure document or a product disclosure document for the
                purposes of the Corporations Act 2001 (Cth) and does not contain all the information that a prospectus or a product disclosure
                statement is required to contain; and
              </li>
              <li>
                the Subscriber acknowledges and agrees that the Memorandum has not been and will not be lodged with the Australian Securities and
                Investments Commission (“ASIC”) and the distribution of the Memorandum in Australia has not been authorised by ASIC or any other
                regulatory authority in Australia.
              </li>
            </ol>
          </td>
        </tr>
      </table>
      <p>
        <strong>
          <i>Hong Kong</i>
        </strong>
      </p>
      <p>If the Subscriber is an investor in Hong Kong, the Subscriber confirms that it qualifies under at least one of the following categories:</p>
      <table>
        <tr>
          <td>
            <Checkbox name="checkbox117" className="form-check-input me-2" />
          </td>
          <td>
            <p>
              The Subscriber is a trust corporation having been entrusted under the trust or trusts of which it acts as a trustee with total assets of
              not less than HK$40 million or its equivalent in any non-Hong Kong currency:
            </p>
            <ol type="a">
              <li>
                as stated in the most recent audited financial statement prepared (i) in respect of the trust corporation; and (ii) within 16 months
                before the relevant date;
              </li>
              <li>
                as ascertained by referring to one or more audited financial statements, each being the most recent audited financial statement,
                prepared (i) in respect of the trust or any of the trusts; and (ii) within 16 months before the relevant date; or
              </li>
              <li>
                as ascertained by referring to one or more custodian statements issued to the trust corporation (i) in respect of the trust or any of
                the trusts; and (ii) within 12 months before the relevant date.
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox118" className="form-check-input me-2" />
          </td>
          <td>
            <p>
              The Subscriber is an individual, either alone or with any of his associates on a joint account, having a portfolio of not less than HK$8
              million or its equivalent in any non-Hong Kong currency:
            </p>
            <ol type="a">
              <li>
                as stated in a certificate issued by an auditor or a certified public accountant of the individual within 12 months before the
                relevant date; or
              </li>
              <li>
                as ascertained by referring to one or more custodian statements issued to the individual (either alone or with the associate) within
                12 months before the relevant date.
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox119" className="form-check-input me-2" />
          </td>
          <td>
            <p>The Subscriber is a corporation or partnership having:</p>
            <ol type="a">
              <li>a portfolio of not less than HK $8 million or its equivalent in any non- Hong Kong currency;</li>
              <li>
                total assets of not less than HK$40 million or its equivalent in any non- Hong Kong currency, as ascertained by referring to the most
                recent audited financial statement prepared (i) in respect of the corporation or partnership (as the case may be); and (ii) within 16
                months before the relevant date; or
              </li>
              <li>
                one or more custodian statements issued to the corporation or partnership (as the case may be) within 12 months before the relevant
                date.
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox120" className="form-check-input me-2" />
          </td>
          <td>
            <p>
              The Subscriber is a corporation, the sole business of which is to hold investments and which is wholly owned by an individual who,
              either alone or with any of his or her associates on a joint account, falls within the description above relating to an individual.
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <Checkbox name="checkbox121" className="form-check-input me-2" />
          </td>
          <td>
            <p>
              The Subscriber otherwise qualifies as a “professional investor” within the meaning of the Hong Kong Securities and Futures Ordinance.
            </p>
          </td>
        </tr>
      </table>
      <p>
        <strong>
          <i>Israel</i>
        </strong>
      </p>
      <table>
        <tr>
          <td>
            <Checkbox name="checkbox122" className="form-check-input me-2" />
          </td>
          <td>
            <p>
              If the Subscriber is resident or otherwise subject to the laws of Israel, the Subscriber represents and warrants that it is: (a) an
              investor of the type listed in the First Schedule to Israel’s Securities Law, 1968; and (b) aware of the implications of being treated
              as such an investor and consents to such treatment.
            </p>
          </td>
        </tr>
      </table>
      <p>
        <strong>
          <i>Japan</i>
        </strong>
      </p>
      <table>
        <tr>
          <td>
            <Checkbox name="checkbox123" className="form-check-input me-2" />
          </td>
          <td>
            <p>
              If the Subscriber is a legal entity established under Japanese law, the Subscriber represents and warrants that it is (i) a Qualified
              Institutional Investor (“QII”) (tekikaku kikan toshika) as defined in Article 10, Paragraph 1 of Cabinet Office Ordinance Concerning
              Definitions provided in Article 2 of the Financial Instruments and Exchange Act of Japan (the “FIEA”), and (ii) not a person who falls
              within any of the categories set forth in items (a) through (c) of Article 63, Paragraph 1, Item 1 of the FIEA (“Non-Disqualified
              Investor”). The Subscriber further covenants and agrees that: (i) it will continue to maintain its status as a QII and Non-Disqualified
              Investor as long as it holds any interests in the Partnership; (ii) it shall not sell, exchange, assign, mortgage, hypothecate, pledge
              or otherwise transfer its interest in the Partnership to any party other than QII and Non- Disqualified Investor; and (iii) it will
              ensure that any transferee of the Interests will agree to the covenants set forth in this section. The Subscriber acknowledges that no
              securities registration statement for a public offering has been made or will be made with respect to the Interests in Japan on the
              ground that such solicitation of the Interests constitutes “shoninzu-muke kanyu” as described in Article 23-13, Paragraph 4 of the FIEA,
              and that the Interests are financial instruments as defined under Article 2, Paragraph 2, Item 6 of the FIEA. The Subscriber further
              acknowledges that the assets of the Partnership will be segregated in accordance with Article 63, Paragraph 11, Article 40-3 and Article
              42-4 of the FIEA.
            </p>
          </td>
        </tr>
      </table>
      <p>
        <strong>
          <i>Singapore</i>
        </strong>
      </p>
      <table>
        <tr>
          <td>
            <Checkbox name="checkbox124" className="form-check-input me-2" />
          </td>
          <td>
            <p>
              If the Subscriber is a resident of Singapore, the Subscriber hereby acknowledges and agrees that the Interests are being offered to the
              Subscriber pursuant to an exemption in Section 304 of the Securities and Futures Act, Chapter 289 of Singapore (the “SFA”). Accordingly,
              the Subscriber represents, warrants, acknowledges and agrees that it is an institutional investor within the meaning of Section 304 of
              the SFA. Further, the Subscriber not to:
            </p>
            <ol type="a">
              <li>
                circulate or distribute the Memorandum and any other document or material in connection with the offer or sale, or invitation for
                subscription or purchase, of Interests; or
              </li>
              <li>
                offer or sell, or make the subject of an invitation for subscription or purchase, its Interests, whether directly or indirectly, to
                persons in Singapore other than: <br />s
                <ol type="i">
                  <li>to an institutional investor under Section 304 of the SFA,</li>
                  <li>
                    to persons under Section 305 of the SFA (where the conditions in relation to an offer under Section 305 of the SFA are complied
                    with), or
                  </li>
                  <li>otherwise pursuant to, and in accordance with the conditions of, any other applicable provisions of the SFA.</li>
                </ol>
              </li>
            </ol>
          </td>
        </tr>
      </table>
      <p>
        <strong>
          <i>Shouth Korea</i>
        </strong>
      </p>
      <table>
        <tr>
          <td>
            <Checkbox name="checkbox125" className="form-check-input me-2" />
          </td>
          <td>
            <p>
              If the Subscriber is a legal entity located in South Korea, a citizen or resident of South Korea or this Subscription Agreement was
              received by the Subscriber in South Korea, the Subscriber represents and warrants that it is a “qualified professional investor” as the
              term is defined under the Financial Investment Services and Capital Markets Act of Korea.
            </p>
          </td>
        </tr>
      </table>
      <p>
        <strong>
          <i>None of the Above</i>
        </strong>
      </p>
      <table>
        <tr>
          <td>
            <Checkbox name="checkbox126" className="form-check-input" />
          </td>
          <td>
            <p>The Subscriber is a legal entity located outside of the United States, but none of the above apply to the Subscriber.*</p>
            <p>
              * If the Subscriber checked the box immediately above, please indicate the jurisdiction in which the Subscriber is located
              <span id="subscriberJurisdiction" className="vgs-input-container">
                subscriberJurisdiction
              </span>
            </p>
            <p>
              Depending on the jurisdiction indicated, the Partnership may require additional documentation or representations from the Subscriber.
            </p>
          </td>
        </tr>
      </table>
    </FundCabotValueDocumentContainer>
  );
}
