import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { ObjectSchema, ValidationError } from 'yup';

export interface IValidateResult<T> {
  data: T;
  resetForm: () => void;
  initialData: any;
}

type GFormProps = {
  children: ReactNode;
  validationSchema?: ObjectSchema<any>;
  onValidate: (props: IValidateResult<any>) => void;
  initialData?: any;
  className?: string;
};

export default function GForm({ children, validationSchema, onValidate, initialData, className }: GFormProps) {
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    formRef.current?.setData(initialData);
  }, [initialData]);

  function resetForm() {
    formRef?.current?.reset();
  }

  const handleSubmit = useCallback(
    async (data = {}) => {
      try {
        formRef?.current?.setErrors({});

        console.log(data);

        if (validationSchema) {
          await validationSchema.validate(data, {
            abortEarly: false
          });
        }

        onValidate({ data, initialData, resetForm });
      } catch (err) {
        const validationErrors: { [key: string]: string } = {};

        if (err instanceof ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path as string] = error.message;
          });

          formRef?.current?.setErrors(validationErrors);
        }
      }
    },
    [initialData]
  );

  return (
    <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit} className={className} autoComplete="off">
      {children}
    </Form>
  );
}
