import { IconButton, Tooltip } from '@material-ui/core';
import { IFund } from 'models/fund.model';
import { useApp } from 'providers/app.provider';
import React, { useCallback, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import useFundService from 'services/fund.service';
import GForm, { IValidateResult } from 'shared/controls/GForm';
import GInput from 'shared/controls/GInput';
import GLoading from 'shared/controls/GLoading';
import Title from 'shared/styled/Title';
import styled from 'styled-components';
import AppTheme, { IAppTheme } from 'themes/app-theme.config';
import * as yup from 'yup';

const InviteSubscribersFormContainer = styled(GForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  height: 100%;
  padding: 4rem;

  .email-list {
    width: 400px;
    padding: 10px 20px;
    border: 1px solid ${({ theme }: IAppTheme) => theme.colors.line};
    border-radius: 5px;

    &-title {
      ${({ theme }: IAppTheme) => theme.mixins.desktopLinkSmall};
      font-weight: bold;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 10px 0 0 0;

      li {
        padding: 3px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        transition: color 0.3s ease;

        &:hover {
          color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};

          .remove-button {
            opacity: 1;
          }
        }

        .remove-button {
          opacity: 0;
          transition: opacity 0.3s ease;
        }
      }
    }
  }

  .include-input {
    width: 400px;
  }

  .btn-actions {
    display: flex;
  }

  .btn {
    padding: 12px 70px;
    width: 250px;

    &.btn-info {
      color: white;
    }
  }
`;

const schema = yup.object().shape({
  email: yup.string().email('Email not valid.').required('You should provide a valid email.')
});

type InviteSubscribersFormProps = {
  fund: IFund;
};

export default function InviteSubscribersForm({ fund }: InviteSubscribersFormProps) {
  const FundsService = useFundService();

  const { closeDialog, showSnackbar } = useApp();

  const [emails, setEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const includeEmail = useCallback(
    ({ data, resetForm }: IValidateResult<{ email: string }>) => {
      if (!emails.includes(data.email)) {
        setEmails((prev) => [...prev, data.email].sort());
      }

      resetForm();
    },
    [emails]
  );

  const removeEmail = useCallback(
    (index: number) => {
      emails.splice(index, 1);
      setEmails([...emails]);
    },
    [emails]
  );

  function inviteSubscribers() {
    if (emails.length) {
      setLoading(true);
      FundsService.inviteSubscribers(emails, fund)
        .finally(() => setLoading(false))
        .then(() => {
          showSnackbar?.({
            variant: 'success',
            message: 'The invitations were successfully sent'
          });

          closeDialog?.();
        })
        .catch(() =>
          showSnackbar?.({
            variant: 'error',
            message: 'The invitation could not be sent. Please, try again!'
          })
        );
    }
  }

  return (
    <InviteSubscribersFormContainer onValidate={includeEmail} validationSchema={schema}>
      <Title className="mb-5">Invite New Subscribers</Title>

      {loading && <GLoading />}

      <div className="d-flex mb-4">
        <strong className="me-2">Fund:</strong>
        <span>{fund.name}</span>
      </div>

      <div className="email-list mb-4">
        <div className="email-list-title">Email List to be Sent</div>

        <ul>
          {!emails.length && <li>The list is empty.</li>}
          {!!emails.length &&
            emails.map((email, index) => (
              <li key={email}>
                <span>{email}</span>
                <Tooltip title="Remove Email">
                  <IconButton className="remove-button" size="small" onClick={() => removeEmail(index)}>
                    <IoCloseCircleOutline color={AppTheme.colors.danger} />
                  </IconButton>
                </Tooltip>
              </li>
            ))}
        </ul>
      </div>

      <GInput type="text" name="email" placeholder="Provide a valid email" className="include-input mb-5" label="Subscriber Email" />

      <button type="submit" className="btn btn-info mb-3">
        Include
      </button>

      <button type="button" onClick={() => inviteSubscribers()} className="btn btn-primary mb-3" disabled={!emails.length}>
        Send to All
      </button>

      <button type="button" className="btn btn-light" onClick={() => closeDialog?.()}>
        Cancel
      </button>
    </InviteSubscribersFormContainer>
  );
}
