import { Menu, MenuItem } from '@material-ui/core';
import { ResponsiveLine } from '@nivo/line';
import React from 'react';
import { BiMailSend } from 'react-icons/bi';
import { IoIosArrowDown } from 'react-icons/io';
import { IoCashOutline, IoCheckmarkDoneSharp, IoEllipsisHorizontalSharp } from 'react-icons/io5';
import useFundService from 'services/fund.service';
import useSubscriptionService from 'services/subscription.service';
import GLoading from 'shared/controls/GLoading';
import { numberWithCommas } from 'shared/utils/functions';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

import { colors, groupBy, months } from './data.config';

const Container = styled.div`
  overflow-y: auto;
  padding: 3rem;
  background-color: #f7f8fa;

  .section-blocks {
    display: flex;
    justify-content: space-between;

    &-block {
      width: 300px;
      height: 150px;
      background-color: ${({ theme }: IAppTheme) => theme.colors.offWhite};
      border-radius: 5px;
      padding: 1.8rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .block-circle {
        background-color: ${({ theme }: IAppTheme) => theme.colors.secondaryColor};
        max-width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .chart-container {
    background-color: ${({ theme }: IAppTheme) => theme.colors.offWhite};
    padding: 30px;
    border-radius: 5px;
  }
`;

export enum FilterPeriodEnum {
  Week = 'week',
  Month = 'month',
  Year = 'year'
}

const filterPeriodOptions = [
  { key: FilterPeriodEnum.Week, label: 'Over last week' },
  { key: FilterPeriodEnum.Month, label: 'Over last month' },
  { key: FilterPeriodEnum.Year, label: 'Over last year' }
];

export default function DashboardPage() {
  const FundService = useFundService();
  const SubscriptionService = useSubscriptionService();

  const [dashboardData, setDashboardData] = React.useState<any>([]);
  const [chartData, setChartData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [periodMenu, setPeriodMenu] = React.useState<Element | null>(null);
  const [periodSelected, setPeriodSelected] = React.useState(FilterPeriodEnum.Week);

  React.useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      setLoading(true);
      await fetchDashboardData();
      await fetchChartData();
    } finally {
      setLoading(false);
    }
  };

  const fetchDashboardData = React.useCallback(async () => {
    const dashboardDataResponse = await SubscriptionService.getDashboardData(periodSelected);
    setDashboardData(dashboardDataResponse.data);
  }, [periodSelected]);

  const fetchChartData = React.useCallback(async () => {
    const chartData = await FundService.getChartData();
    setChartData(chartData.data);
  }, [periodSelected]);

  const data = React.useMemo(() => {
    const groupedData = groupBy(chartData, 'name');

    return Object.keys(groupedData).map((key, index) => {
      const fundValues = groupedData[key];
      let cumulativeValue = 0;
      return {
        id: key,
        color: colors[index],
        data: months.map((month) => {
          const found = fundValues.find((value: any) => value.month === month);

          if (found) {
            cumulativeValue += Number(found?.monthly_sum || 0);
          }

          return { x: month, y: cumulativeValue };
        })
      };
    });
  }, [chartData]);

  const selectPeriod = React.useCallback((period: FilterPeriodEnum) => {
    setPeriodMenu(null);
    setPeriodSelected(period);
  }, []);

  const filterDataByPeriodMenu = React.useMemo(() => {
    return (
      <Menu
        anchorEl={periodMenu}
        open={!!periodMenu}
        onClose={() => setPeriodMenu(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        {filterPeriodOptions.map((option) => (
          <MenuItem key={option.key} onClick={() => selectPeriod(option.key)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    );
  }, [periodMenu]);

  const chart = React.useMemo(
    () => (
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 40, bottom: 50, left: 150 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        enableSlices="x"
        enableGridX={false}
        colors={colors}
        enablePoints={false}
        yFormat=" >-.2f"
        curve="basis"
        theme={{
          labels: { text: { fontFamily: 'Poppins' } },
          axis: { ticks: { text: { fontFamily: 'Poppins', fontSize: 16 } } },
          legends: { text: { fontFamily: 'Poppins' } }
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '2021',
          legendOffset: 46,
          legendPosition: 'middle'
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legend: 'Commitment (USD)',
          legendOffset: -120,
          legendPosition: 'middle',
          format: function (value) {
            return numberWithCommas(value);
          }
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh
        legends={[
          {
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: -50,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 170,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    ),
    [data]
  );

  return (
    <>
      <Container>
        <div className="d-flex align-items-center justify-content-between mb-5">
          <h3 className="fw-bold">Dashboard</h3>
          <button type="button" className="btn fw-bold text-primary" onClick={(event) => setPeriodMenu(event.currentTarget)}>
            Over last {periodSelected} <IoIosArrowDown />
          </button>
          {filterDataByPeriodMenu}
        </div>

        {loading && <GLoading />}

        <div className="section-blocks mb-5">
          <div className="section-blocks-block">
            <div className="d-flex">
              <div className="block-circle">
                <IoCashOutline size={25} />
              </div>
              <div className="ms-3">
                <div className="fw-bold fs-3 text-primary"> ${numberWithCommas(dashboardData.totalAmount)}</div>
                <div className="text-dark fs-6">Total Amount of Commitments</div>
              </div>
            </div>
          </div>

          <div className="section-blocks-block">
            <div className="d-flex">
              <div className="block-circle">
                <BiMailSend size={25} />
              </div>
              <div className="ms-3">
                <div className="fw-bold fs-3 text-primary">{dashboardData.invitationsAmount}</div>
                <div className="text-dark">Total Amount of Invitations Sent</div>
              </div>
            </div>
          </div>

          <div className="section-blocks-block">
            <div className="d-flex">
              <div className="block-circle">
                <IoCheckmarkDoneSharp size={25} />
              </div>
              <div className="ms-3">
                <div className="fw-bold fs-3 text-primary">{dashboardData.acceptedSubscriptions}</div>
                <div className="text-dark">Completed Subscriptions</div>
              </div>
            </div>
          </div>

          <div className="section-blocks-block">
            <div className="d-flex">
              <div className="block-circle">
                <IoEllipsisHorizontalSharp size={25} />
              </div>
              <div className="ms-3">
                <div className="fw-bold fs-3 text-primary">{dashboardData.pendingSubscriptions}</div>
                <div className="text-dark">Total Amount of Pending Subscriptions</div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: '100%', height: 550 }} className="chart-container position-relative">
          {data.length ? chart : <span className="center-absolute">No Data Found</span>}
        </div>
      </Container>
    </>
  );
}
