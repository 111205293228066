import { IFundDocument } from 'models/fund-document.model';
import { IFund } from 'models/fund.model';
import { DocumentTemplates } from 'pages/fund-document/documents/documents.config';
import { OpenModeEnum, useApp } from 'providers/app.provider';
import React, { useCallback, useEffect, useState } from 'react';
import useFundDocumentService from 'services/fund-documents.service';
import useFundService from 'services/fund.service';
import GForm, { IValidateResult } from 'shared/controls/GForm';
import GInput from 'shared/controls/GInput';
import GInputSelect from 'shared/controls/GInputSelect';
import GLoading from 'shared/controls/GLoading';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';
import * as yup from 'yup';

const AddFundDocumentFormContainer = styled(GForm)`
  position: relative;
  width: 500px;
  padding: 2rem;

  .document-modal {
    &-title {
      font-weight: 600;
      color: ${({ theme }: IAppTheme) => theme.colors.offBlack};
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 1rem;
      }
    }
  }
`;

const validationSchema = yup.object().shape({
  name: yup.string().required('The document name is required'),
  fund: yup.object().nullable().required('The associated fund is required')
});

type FundDocumentFormDialogProps = {
  openMode: OpenModeEnum;
  fundDocument?: IFundDocument;
};

export default function FundDocumentFormDialog({ openMode, fundDocument }: FundDocumentFormDialogProps) {
  let isMounted = false;

  const FundService = useFundService();
  const FundDocumentService = useFundDocumentService();

  const { closeDialog, showSnackbar } = useApp();

  const [funds, setFunds] = useState<IFund[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    isMounted = true;

    FundService.all().then((res) => isMounted && setFunds(res.data.filter((fund) => !!fund.active)));

    return () => {
      isMounted = false;
    };
  }, []);

  const createOrUpdateFundDocumentTemplate = useCallback(
    ({ data, initialData }: IValidateResult<IFundDocument>) => {
      setLoading(true);

      const payload = { ...data, templateCode: data.templateCode.name };

      const request = openMode === OpenModeEnum.New ? FundDocumentService.create(payload) : FundDocumentService.update(initialData.id, payload);

      request
        .then(() => {
          showSnackbar?.({
            variant: 'success',
            message: openMode === OpenModeEnum.New ? 'Document successfully created!' : 'Document successfully updated!'
          });
          closeDialog?.();
        })
        .catch(() =>
          showSnackbar?.({
            variant: 'error',
            message: 'Something went wrong! Try again.'
          })
        )
        .finally(() => isMounted && setLoading(false));
    },
    [openMode, closeDialog]
  );

  return (
    <AddFundDocumentFormContainer
      {...{
        onValidate: createOrUpdateFundDocumentTemplate,
        initialData: { ...fundDocument, openMode, FundDocumentService },
        validationSchema
      }}>
      <h5 className="document-modal-title">{openMode === OpenModeEnum.New ? 'Add New Subscription Document' : 'Edit Subscription Document'}</h5>

      {loading && <GLoading />}

      <GInput name="name" label="Document Name" className="mb-4" />

      <GInputSelect
        name="fund"
        label="This document will be applied to fund:"
        className="mb-4"
        options={funds}
        getOptionValue={(option: IFund) => option.id.toString()}
        getOptionLabel={(option: IFund) => option.name}
      />

      <GInputSelect name="templateCode" valueKey="name" label="Document Template" className="mb-5" options={DocumentTemplates} />

      <div className="document-modal-actions">
        <button type="button" className="btn btn-light" onClick={() => closeDialog?.()}>
          Cancel
        </button>

        <button type="submit" className="btn btn-primary">
          {openMode === OpenModeEnum.New ? 'Create document' : 'Save changes'}
        </button>
      </div>
    </AddFundDocumentFormContainer>
  );
}
