import { useApp } from 'providers/app.provider';
import { IFund } from 'models/fund.model';
import React, { useCallback, useState } from 'react';
import useFundService from 'services/fund.service';
import GForm, { IValidateResult } from 'shared/controls/GForm';
import GInput from 'shared/controls/GInput';
import GLoading from 'shared/controls/GLoading';
import Title from 'shared/styled/Title';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const AddFundFormContainer = styled(GForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 4rem;

  .btn-actions {
    display: flex;
  }
`;

const Label = styled.h1`
  ${({ theme }: IAppTheme) => theme.mixins.desktopTextMedium}
  color: ${({ theme }: IAppTheme) => theme.colors.body};
`;

export enum FundFormMode {
  New,
  Edit
}

type FundFormProps = {
  fund?: IFund;
  mode: FundFormMode;
};

export default function FundForm({ fund, mode }: FundFormProps) {
  const { closeDialog, showSnackbar } = useApp();

  const FundService = useFundService();

  const [loading, setLoading] = useState(false);

  const createFund = useCallback(
    ({ data }: IValidateResult<IFund>) => {
      setLoading(true);

      switch (mode) {
        case FundFormMode.New:
          FundService.create(data)
            .then(() => {
              showSnackbar?.({
                variant: 'success',
                message: 'The fund was successfully created!'
              });

              closeDialog?.();
            })
            .finally(() => setLoading(false));
          break;

        case FundFormMode.Edit:
          if (fund) {
            FundService.update(fund.id, data)
              .then(() => {
                showSnackbar?.({
                  variant: 'success',
                  message: 'The fund was successfully update!'
                });

                closeDialog?.();
              })
              .finally(() => setLoading(false));
          }
          break;

        default:
          break;
      }
    },
    [fund, mode]
  );

  const title = React.useMemo(() => {
    switch (mode) {
      case FundFormMode.Edit:
        return <Title className="mb-5">Update Fund Details</Title>;

      case FundFormMode.New:
        return <Title className="mb-5">Create New Fund</Title>;

      default:
        return '';
    }
  }, [mode]);

  return (
    <AddFundFormContainer onValidate={createFund} initialData={fund}>
      {title}

      <Label>Provide a name for the fund</Label>
      <GInput name="name" placeholder="Fund Name" style={{ width: 400 }} className="mb-5" />

      {loading && <GLoading />}

      <div className="btn-actions">
        <button type="submit" className="btn btn-primary px-5 py-3 me-3">
          Save
        </button>
        <button type="button" className="btn btn-light px-5 py-3" onClick={() => closeDialog?.()}>
          Cancel
        </button>
      </div>
    </AddFundFormContainer>
  );
}
