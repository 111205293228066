import $ from 'jquery';
import { ISubscription, SubscriptionStatus } from 'models/subscription.model';
import moment from 'moment';
import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useSubscriptionService from 'services/subscription.service';
import GForm, { IValidateResult } from 'shared/controls/GForm';
import Textarea from 'shared/controls/Textarea';
import styled from 'styled-components';

const HeaderTable = styled.div`
  tr td {
    padding: 3px 20px 3px 0px;
  }
`;

type SubscriptionUserHeaderProps = {
  subscription: ISubscription;
  issues: string[];
};

export default function SubscriptionUserHeader({ subscription, issues }: SubscriptionUserHeaderProps) {
  const { showSnackbar } = useApp();
  const { goBack } = useHistory();
  const { pathname } = useLocation();

  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const SubscriptionService = useSubscriptionService();

  const onFormSubmit = useCallback(
    ({ data, initialData }: IValidateResult<any>) => {
      ($ as any).blockUI({ message: '<div>Submitting your request, please wait...</div>' });

      SubscriptionService.requestAdjustments(subscription.id as number, { issues: JSON.stringify(initialData), message: data.message })
        .finally(() => ($ as any).unblockUI())
        .then(() => {
          showSnackbar?.({ variant: 'success', message: 'The adjustment request was sent!' });
          goBack();
        })
        .catch(() => showSnackbar?.({ variant: 'error', message: 'The action could not be executed. Please, try again!' }));
    },
    [subscription, selectedIds]
  );

  const approveSubscription = useCallback(() => {
    if (subscription.id) {
      SubscriptionService.approve(subscription.id)
        .then(() => {
          showSnackbar?.({ variant: 'success', message: 'Subscription approved!' });
          goBack();
        })
        .catch(() => showSnackbar?.({ variant: 'error', message: 'The action could not be executed. Please, try again!' }));
    }
  }, [subscription]);

  $(() => {
    $('.vgs-input-container').css('cursor', 'pointer');
    $(document).off('click', '.vgs-input-container');

    if (pathname.includes(AppPagesEnum.SubscriptionDetails)) {
      $(document).on('click', '.vgs-input-container', (event) => {
        const newId = $(event.target).attr('id');

        if (newId) {
          const element = $(`#${newId}`);
          const index = selectedIds.findIndex((id) => newId === id);

          if (index < 0) {
            selectedIds.push(newId);
            element.css('color', 'red');
          } else {
            selectedIds.splice(index, 1);
            element.css('color', 'blue');
          }

          setSelectedIds([...selectedIds]);
        }
      });
    }
  });

  React.useEffect(() => {
    setSelectedIds(issues);
  }, [issues]);

  const subscriptionStatus = React.useMemo(() => {
    if (subscription?.status === SubscriptionStatus.CREATED) {
      return <span className="badge bg-primary mx-2">Initiated</span>;
    }

    if (subscription?.status === SubscriptionStatus.IN_PROGRESS) {
      return <span className="badge bg-primary mx-2">Under Evaluation</span>;
    }

    if (subscription?.status === SubscriptionStatus.WITH_ISSUES) {
      return <span className="badge bg-danger mx-2">With Issues</span>;
    }

    return <span className="badge bg-success mx-2">Completed</span>;
  }, [subscription]);

  return (
    <div>
      <HeaderTable>
        <tr>
          <td>
            <strong>Subscriber Name:</strong>
          </td>
          <td>{subscription?.subscriber.name}</td>
        </tr>
        <tr>
          <td>
            <strong>Subscription Amount:</strong>
          </td>
          <td>${subscription?.totalAmount.toFixed(2)}</td>
        </tr>
        <tr>
          <td>
            <strong>Subscription Status:</strong>
          </td>
          <td>{subscriptionStatus}</td>
        </tr>
      </HeaderTable>

      <br />

      <p>
        <small>Subscription held in {moment(subscription?.createdAt).format('MM-DD-YYYY HH:mm')}</small>
      </p>

      <br />

      {subscription?.status !== SubscriptionStatus.COMPLETED && (
        <GForm onValidate={onFormSubmit} initialData={selectedIds}>
          <label className="mb-2">
            <strong>Message to the Subscriber:</strong>{' '}
          </label>
          <div className="pe-3">
            <Textarea name="message" rows={8} className="form-control mb-3" />
          </div>

          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary ms-3" disabled={!selectedIds.length}>
              Request Adjustments
            </button>
            <button type="button" className="btn btn-warning text-white ms-3" onClick={() => setSelectedIds([])} disabled={!selectedIds.length}>
              Clear Selection
            </button>
            <button type="button" className="btn btn-success text-white mx-3" onClick={approveSubscription}>
              Approve Subscription
            </button>
          </div>

          <div className="selected-fields">
            <p className="mx-3">
              Selected Fields: <span className="badge rounded-pill bg-primary">{selectedIds.length}</span>
            </p>
            <button type="submit" className="btn btn-primary ms-3" disabled={!selectedIds.length}>
              Request Adjustments
            </button>
            <button type="button" className="btn btn-warning text-white mx-3" onClick={() => setSelectedIds([])} disabled={!selectedIds.length}>
              Clear Selection
            </button>
          </div>
        </GForm>
      )}

      <hr />
    </div>
  );
}
