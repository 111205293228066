import React, { useMemo } from 'react';
import GLoading from 'shared/controls/GLoading';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const TableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;

  .table-register {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-bottom: 1px solid ${({ theme }: IAppTheme) => theme.colors.input};
    transition: color 0.3s ease, border-color 0.3s ease;

    &:hover {
      cursor: pointer;
      color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
      border-color: ${({ theme }: IAppTheme) => theme.colors.primaryColor};
    }

    &-text {
      padding: 1.8rem 3rem 1.8rem 0;
      ${({ theme }: IAppTheme) => theme.mixins.desktopTextSmall};

      &.emphasis {
        font-weight: 600;
      }
    }

    &-actions {
      padding: 0 2rem;
      display: flex;
      flex: 1;
      ${({ theme }: IAppTheme) => theme.mixins.desktopTextSmall};

      &.emphasis {
        font-weight: 600;
      }
    }
  }
`;

export interface GTableV2RowStructure {
  key: string;
  type?: 'text';
  strong?: boolean;
  getValue?: (item: any) => void;
}

type GTableV2Props = {
  data: any[];
  loading?: boolean;
  rowsStructure: GTableV2RowStructure[];
  onClickRow?: (item: any) => void;
  useRowSelection?: boolean;
};

export default function GTableV2({ data, loading, rowsStructure, onClickRow, useRowSelection }: GTableV2Props) {
  const tableElement = useMemo(
    () =>
      data?.map((item) => (
        <div key={item.id} className="table-register" aria-hidden>
          {rowsStructure.map((structure) => {
            return (
              <span
                key={structure.key}
                onClick={() => onClickRow?.(item)}
                className={`table-register-text ${structure.strong && 'emphasis'}`}
                aria-hidden>
                {structure.getValue ? structure.getValue(item) : item[structure.key]}
              </span>
            );
          })}
        </div>
      )),
    [data, rowsStructure, useRowSelection]
  );

  const tableRender = data.length ? tableElement : <h6 className="d-flex justify-content-center text-dark p-5">No records found!</h6>;

  return <TableContainer>{loading ? <GLoading /> : tableRender}</TableContainer>;
}
