import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProps, useSnackbar } from 'notistack';
import AppPagesEnum from 'pages/pages.enum';
import AuthProvider from 'providers/auth.provider';
import React from 'react';
import { useLocation } from 'react-router-dom';
import GModal from 'shared/controls/GModal';
import GQuestionDialog, { IQuestionDialogContext } from 'shared/controls/GQuestionDialog';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import AppTheme from 'themes/app-theme.config';
import MaterialTheme from 'themes/material-theme.config';

interface SnackData {
  variant: 'success' | 'error' | 'info' | 'warning';
  problem?: 'NETWORK_ERROR';
  message?: string;
}

export enum OpenModeEnum {
  New,
  Edit
}

type IModalContent = React.ReactElement<any> | null;

type IAppContext = {
  showSnackbar(snackData: SnackData): void;
  currentPage: AppPagesEnum;
  openDialog(content: React.ReactElement<any>): void;
  closeDialog(): void;
  modalContent: IModalContent;
  openQuestionDialog(context: IQuestionDialogContext): Promise<boolean>;
  documentToken?: string;
  newUserToken?: string;
  requestAdjustmentsValue?: number;
  subscriptionUpdatedValue?: number;
  clearDocumentData: () => void;
  sidebarExpanded: boolean;
  toggleSidebar: () => void;
};

const AppContext = React.createContext<Partial<IAppContext>>({});

export function useApp() {
  return React.useContext(AppContext);
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type IAppProviderProps = {
  children: React.ReactNode;
};

export default function AppProvider({ children }: IAppProviderProps) {
  const documentDataLSKey = 'fundDocumentData';

  const query = useQuery();
  const { pathname, search } = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = React.useState(AppPagesEnum.Dashboard);
  const [modalContent, setModalContent] = React.useState<IModalContent>(null);
  const [sidebarExpanded, setSidebarExpanded] = React.useState(true);

  const [documentToken, setDocumentToken] = React.useState<string>();
  const [newUserToken, setNewUserToken] = React.useState<string>();
  const [requestAdjustmentsValue, setRequestAdjustmentsValue] = React.useState<number>();
  const [subscriptionUpdatedValue, setSubscriptionUpdatedValue] = React.useState<number>();

  React.useEffect(() => {
    recoverDocumentData();
  }, []);

  React.useEffect(() => {
    setCurrentPage(pathname as AppPagesEnum);
    registerFundDocumentData();
  }, [pathname, search]);

  function registerFundDocumentData() {
    const documentTokenData = query.get('documentToken');
    const newUserTokenData = query.get('newUserToken');
    const requestAdjustments = query.get('requestAdjustments');
    const subscriptionUpdated = query.get('subscriptionUpdated');

    if (documentTokenData) {
      localStorage.setItem(documentDataLSKey, documentTokenData);
      recoverDocumentData();
    }

    if (newUserTokenData) {
      setNewUserToken(newUserTokenData);
    }

    if (requestAdjustments && !!requestAdjustments.length) {
      setRequestAdjustmentsValue(Number(requestAdjustments));
    }

    if (subscriptionUpdated && !!subscriptionUpdated.length) {
      setSubscriptionUpdatedValue(Number(subscriptionUpdated));
    }
  }

  function toggleSidebar() {
    setSidebarExpanded(!sidebarExpanded);
  }

  function recoverDocumentData() {
    const data = localStorage.getItem(documentDataLSKey);

    if (data?.length) {
      setDocumentToken(data);
    }
  }

  function clearDocumentData() {
    localStorage.removeItem(documentDataLSKey);
  }

  function showSnackbar(snackData: SnackData) {
    const { variant, problem, message } = snackData;

    if (enqueueSnackbar) {
      const showSnackbar = (msg?: string) => {
        const sb: any = enqueueSnackbar(msg, {
          open: true,
          variant,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          onClick: () => closeSnackbar && closeSnackbar(sb)
        } as SnackbarProps);
      };

      switch (problem) {
        case 'NETWORK_ERROR':
          showSnackbar('Network Error!');
          break;

        default:
          if (!message && variant === 'error') {
            showSnackbar('Sorry! The action could not be executed.');
            return;
          }

          showSnackbar(message);
          break;
      }
    }
  }

  function openDialog(content: React.ReactElement<any>) {
    setModalContent(content);
  }

  function closeDialog() {
    setModalContent(null);
  }

  function openQuestionDialog(context: IQuestionDialogContext): Promise<boolean> {
    return new Promise((resolve) => {
      const onAnswer = (answer: boolean) => {
        setModalContent(null);
        resolve(answer);
      };

      setModalContent(<GQuestionDialog {...{ onAnswer, context }} />);
    });
  }

  const value = {
    showSnackbar,
    currentPage,
    openDialog,
    modalContent,
    closeDialog,
    openQuestionDialog,
    documentToken,
    newUserToken,
    clearDocumentData,
    requestAdjustmentsValue,
    subscriptionUpdatedValue,
    sidebarExpanded,
    toggleSidebar
  };

  return (
    <AppContext.Provider {...{ value }}>
      <SCThemeProvider theme={AppTheme}>
        <ThemeProvider theme={MaterialTheme}>
          <AuthProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <GModal />
              {children}
            </MuiPickersUtilsProvider>
          </AuthProvider>
        </ThemeProvider>
      </SCThemeProvider>
    </AppContext.Provider>
  );
}
