import { FundInvitation, IFund } from 'models/fund.model';

import useHttpService from './http.service';

export default function useFundService() {
  const url = 'funds';

  const { instance, ...genericMethods } = useHttpService<IFund>(url);

  function inviteSubscribers(emails: string[], fund: IFund) {
    return instance.post(`${url}/invite-subscribers`, { emails, fund });
  }

  function exportToExcel() {
    return instance({
      method: 'GET',
      url: `${url}/export-to-excel`,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    });
  }

  function exportSubscriptionsToExcel(fundId: number) {
    return instance({
      method: 'GET',
      url: `${url}/export-subscriptions-to-excel/${fundId}`,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    });
  }

  function close(fundId: number) {
    return instance.put(`${url}/close/${fundId}`);
  }

  function getChartData() {
    return instance.get(`${url}/funds-chart-data`);
  }

  function getInvitationsSent(fundId: number) {
    return instance.get<FundInvitation[]>(`${url}/invitations-sent/${fundId}`);
  }

  return { ...genericMethods, inviteSubscribers, exportToExcel, exportSubscriptionsToExcel, close, getChartData, getInvitationsSent };
}
