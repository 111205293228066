import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const GLoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0.8;
  backdrop-filter: blur(5px);
  border-radius: 8px;
  left: 0;
  top: 0;
  z-index: 5;
`;

export default function GLoading() {
  return (
    <GLoadingContainer>
      <CircularProgress />
    </GLoadingContainer>
  );
}
