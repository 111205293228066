import './App.scss';

import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import { useAuth, UserRole } from 'providers/auth.provider';
import React, { useEffect, useMemo } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import AppRoutes from 'routes/app.routes';
import AppHeader from 'shared/sections/app-header/AppHeader';
import NavigationBar from 'shared/sections/NavigationBar';

function App() {
  const { push } = useHistory();
  const { currentUser } = useAuth();
  const { documentToken, requestAdjustmentsValue, subscriptionUpdatedValue } = useApp();

  const redirectTo = useMemo(() => {
    if (documentToken) {
      return AppPagesEnum.FundDocumentAnswer;
    }

    if (requestAdjustmentsValue) {
      return AppPagesEnum.FundDocumentAnswer;
    }

    if (subscriptionUpdatedValue) {
      return AppPagesEnum.SubscriptionDetails;
    }

    const isSubscriber = currentUser?.role === UserRole.Subscriber;

    return isSubscriber ? AppPagesEnum.MySubscriptions : AppPagesEnum.Dashboard;
  }, [documentToken, requestAdjustmentsValue, subscriptionUpdatedValue, currentUser?.role]);

  useEffect(() => {
    if (redirectTo) {
      push(redirectTo);
    }
  }, [redirectTo, push]);

  return (
    <div className="App-container">
      <AppHeader />

      <div className="App-container-content">
        <NavigationBar />

        <div className="App-container-content-body">
          <Switch>
            {AppRoutes()}
            <Redirect path="*" exact to={AppPagesEnum.Dashboard} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default App;
