import { IFund } from 'models/fund.model';
import { useApp } from 'providers/app.provider';
import React from 'react';
import useFundService from 'services/fund.service';
import GLoading from 'shared/controls/GLoading';
import Title from 'shared/styled/Title';
import styled from 'styled-components';

const CloseFundModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  height: 100%;
  padding: 4rem;

  .btn-actions {
    display: flex;
  }

  .btn {
    padding: 12px 70px;
    width: 250px;
  }
`;

type CloseFundModalProps = {
  fund: IFund;
};

export default function CloseFundModal({ fund }: CloseFundModalProps) {
  const { closeDialog, showSnackbar } = useApp();

  const FundService = useFundService();

  const [loading, setLoading] = React.useState(false);

  const closeFund = React.useCallback(() => {
    setLoading(true);
    FundService.close(fund.id)
      .finally(() => setLoading(false))
      .then(() => {
        showSnackbar?.({ variant: 'success', message: 'The fund was successfully closed' });
        closeDialog?.();
      })
      .catch(() => showSnackbar?.({ variant: 'error', message: 'The action could not be executed. Please, try again!' }));
  }, [fund]);

  return (
    <CloseFundModalContainer>
      <Title className="mb-5">Close Fund?</Title>

      {loading && <GLoading />}

      <div className="d-flex mb-5">
        <span>Are you sure you want to close this Fund?</span>
      </div>

      <button type="button" className="btn btn-danger mb-3" onClick={() => closeFund()}>
        Yes, close
      </button>

      <button type="button" className="btn btn-light" onClick={() => closeDialog?.()}>
        No
      </button>
    </CloseFundModalContainer>
  );
}
