import { Button } from '@material-ui/core';
import { IFund } from 'models/fund.model';
import { ISubscription, SubscriptionStatus } from 'models/subscription.model';
import moment from 'moment';
import FundForm, { FundFormMode } from 'pages/funds/components/FundForm';
import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import React, { useCallback } from 'react';
import { BiMailSend } from 'react-icons/bi';
import { FiEdit3, FiPlayCircle } from 'react-icons/fi';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { RiArrowRightSLine, RiCloseCircleLine, RiErrorWarningLine, RiFileExcel2Line, RiSurveyLine } from 'react-icons/ri';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useFundService from 'services/fund.service';
import useSubscriptionService from 'services/subscription.service';
import GLoading from 'shared/controls/GLoading';
import GTableTabs from 'shared/controls/GTableTabs';
import GTableV2, { GTableV2RowStructure } from 'shared/controls/GTableV2';
import ActionsBar, { IAction } from 'shared/sections/ActionsBar';
import TopBarActions from 'shared/sections/TopBarActions';
import Title from 'shared/styled/Title';
import TopBarLabelContainer from 'shared/styled/TopBarLabelContainer';
import { numberWithCommas } from 'shared/utils/functions';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';
import CloseFundModal from './components/CloseFundModal';
import InviteSubscribersForm from './components/InviteSubscribersForm';
import StatusBlocks from './components/StatusBlocks';

const FundDetailsContainer = styled.div`
  display: flex;
  height: 100%;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .fund-details-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .status-container {
      position: relative;
      padding: 2rem;
    }
  }
`;

const tableTabs = [
  {
    key: SubscriptionStatus.CREATED,
    icon: <FiPlayCircle size={20} />,
    label: 'Initiated'
  },
  {
    key: SubscriptionStatus.IN_PROGRESS,
    icon: <FiPlayCircle size={20} />,
    label: 'In Progress'
  },
  {
    key: SubscriptionStatus.WITH_ISSUES,
    icon: <RiErrorWarningLine size={20} />,
    label: 'With Issues'
  },
  {
    key: SubscriptionStatus.COMPLETED,
    icon: <IoCheckmarkCircleOutline size={20} />,
    label: 'Completed'
  }
];

export default function FundDetailsPage() {
  const { state } = useLocation<{ fundId: number }>();
  const { push } = useHistory();
  const { openDialog, modalContent } = useApp();

  const FundService = useFundService();
  const SubscriptionService = useSubscriptionService();

  const [tabSelected, changeTab] = React.useState(SubscriptionStatus.IN_PROGRESS);
  const [loadingData, setLoadingData] = React.useState(false);
  const [fund, setFund] = React.useState<IFund>();

  React.useEffect(() => {
    fetchData();
  }, [state.fundId, modalContent]);

  const fetchData = useCallback(() => {
    setLoadingData(true);
    FundService.one(state.fundId)
      .then((res) => setFund(res.data))
      .finally(() => setLoadingData(false));
  }, []);

  const exportSubscriptionsDataToExcel = React.useCallback(
    (fundId: number) => {
      setLoadingData(true);
      SubscriptionService.exportSubscriptionsDataToExcel(fundId)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'subscriptions_data.xlsx');
          link.click();
        })
        .finally(() => setLoadingData(false));
    },
    [FundService]
  );

  const sideBarActions = React.useMemo(() => {
    const actions: IAction[] = [];

    if (fund) {
      if (fund?.active) {
        actions.push({
          id: 0,
          icon: <FiEdit3 size={32} />,
          executeAction: () => openDialog?.(<FundForm {...{ mode: FundFormMode.Edit, fund }} />),
          title: 'Edit fund'
        });
      }

      actions.push({
        id: 1,
        icon: <RiFileExcel2Line size={32} />,
        executeAction: () => exportSubscriptionsDataToExcel(fund.id),
        title: 'Export subscriptions data to excel'
      });
    }

    const document = fund?.documents.find((document) => !!document.active);

    if (document) {
      actions.push({
        id: 2,
        icon: <RiSurveyLine size={32} />,
        executeAction: () =>
          push({
            pathname: AppPagesEnum.FundDocumentTemplate,
            state: { documentId: document.id }
          }),
        title: 'View subscription document'
      });
    }

    return actions;
  }, [fund, exportSubscriptionsDataToExcel]);

  const topBarLabel = React.useMemo(() => {
    return (
      <TopBarLabelContainer>
        <Link to={AppPagesEnum.Funds}>Funds</Link>
        <RiArrowRightSLine size={20} />
        <span className="current-item">{fund?.name}</span>
      </TopBarLabelContainer>
    );
  }, [fund]);

  const tableRowsStructure: GTableV2RowStructure[] = [
    {
      key: 'name',
      getValue: (subscription: ISubscription) => <strong>{subscription.subscriber.name}</strong>
    },
    {
      key: 'commitment',
      getValue: (subscription: ISubscription) => (
        <span>
          Commitment: <strong>${numberWithCommas(subscription.totalAmount ?? 0)}</strong>
        </span>
      )
    },
    {
      key: 'status',
      getValue: (subscription: ISubscription) => {
        if (subscription.status === SubscriptionStatus.CREATED) {
          return (
            <h6 className="m-0">
              <span className="badge bg-primary">Initiated</span>
            </h6>
          );
        }
        if (subscription.status === SubscriptionStatus.IN_PROGRESS) {
          return (
            <h6 className="m-0">
              <span className="badge bg-primary">Under Evaluation</span>
            </h6>
          );
        }
        if (subscription.status === SubscriptionStatus.WITH_ISSUES) {
          return (
            <h6 className="m-0">
              <span className="badge bg-warning">With Issues</span>
            </h6>
          );
        }
        return (
          <h6 className="m-0">
            <span className="badge bg-success">Complete</span>
          </h6>
        );
      }
    },
    {
      key: 'createdAt',
      getValue: (subscription: ISubscription) => (
        <span>
          <strong>Created at: {moment(subscription.createdAt).format('MM/DD/YYYY hh:mm')}</strong>
        </span>
      )
    }
  ];

  const activeTabSubscriptions = React.useMemo(() => {
    const subscriptions = fund?.subscriptions || [];
    return subscriptions.filter((subscription) => subscription.status === tabSelected);
  }, [fund, tabSelected]);

  const seeSubscription = React.useCallback((subscription: ISubscription) => {
    push({
      pathname: AppPagesEnum.SubscriptionDetails,
      state: { subscriptionId: subscription.id, page: AppPagesEnum.FundDetails }
    });
  }, []);

  const tableProps = {
    data: activeTabSubscriptions,
    rowsStructure: tableRowsStructure,
    loading: loadingData,
    onClickRow: seeSubscription
  };

  return (
    <FundDetailsContainer>
      <div className="fund-details-page-content">
        <TopBarActions {...{ label: topBarLabel, fetch: fetchData }} />

        <div className="status-container">
          <div className="d-flex justify-content-between">
            <Title>{fund?.name} Details</Title>

            <div className="d-flex align-items-start">
              {!!fund?.documents?.length && !!fund?.active && (
                <Button
                  color="primary"
                  startIcon={<BiMailSend />}
                  style={{ fontWeight: 600 }}
                  className="me-3 px-3"
                  onClick={() => fund && openDialog?.(<InviteSubscribersForm {...{ fund }} />)}
                  disableElevation>
                  Invite Subscribers
                </Button>
              )}

              {!!fund?.active && (
                <Button
                  color="secondary"
                  startIcon={<RiCloseCircleLine />}
                  style={{ fontWeight: 600 }}
                  onClick={() => fund && openDialog?.(<CloseFundModal {...{ fund }} />)}
                  className="px-3"
                  disableElevation>
                  Close Fund
                </Button>
              )}
            </div>
          </div>

          {loadingData && <GLoading />}

          {!loadingData && fund && <StatusBlocks {...{ fund }} />}
        </div>

        <hr className="m-0" />

        <TopBarActions label={`Subscriptions (${fund?.subscriptions.length})`} />
        <GTableTabs {...{ tabs: tableTabs, changeTab, tabSelected }} />
        <GTableV2 {...tableProps} useRowSelection />
      </div>
      <ActionsBar actions={sideBarActions} />
    </FundDetailsContainer>
  );
}
