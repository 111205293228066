import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

const MaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#002a48'
    },
    secondary: {
      main: '#990000'
    }
  },
  typography: {
    fontFamily: 'Poppins'
  }
});

export default MaterialTheme;
