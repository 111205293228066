import { IFundDocument } from 'models/fund-document.model';
import AppPagesEnum from 'pages/pages.enum';
import { OpenModeEnum, useApp } from 'providers/app.provider';
import React, { useCallback, useEffect, useState } from 'react';
import { IoAdd } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import useFundDocumentService from 'services/fund-documents.service';
import GTable, { RowStructure, TableActionsEnum } from 'shared/controls/GTable';
import ActionsBar, { IAction } from 'shared/sections/ActionsBar';
import TopBarActions from 'shared/sections/TopBarActions';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

import FundDocumentFormDialog from './pages/fund-document-template/components/FundDocumentFormDialog';

const FundDocumentListPageContainer = styled.div`
  display: flex;
  height: 100%;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .page-content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const tableHeaders = ['Id', 'Document Name', 'Fund Name', 'Active'];

const tableRowsStructure: RowStructure[] = [
  { key: 'id' },
  { key: 'name' },
  {
    key: 'fund.name',
    useComponent: (document: IFundDocument) => (
      <h6>
        <span className="badge bg-primary">{document.fund?.name}</span>
      </h6>
    )
  },
  {
    key: 'active',
    useComponent: (document: IFundDocument) => (
      <h6>
        <span className={`badge bg-${document.active ? 'primary' : 'secondary'}`}>{document.active ? 'Yes' : 'No'}</span>
      </h6>
    )
  }
];

export default function FundDocumentListPage() {
  const FundDocumentService = useFundDocumentService();

  const { push } = useHistory();
  const { openDialog, modalContent } = useApp();

  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<IFundDocument[]>([]);

  const fetch = React.useCallback(() => {
    setLoading(true);
    FundDocumentService.all()
      .then((res) => setDocuments(res.data))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetch();
  }, [modalContent, fetch]);

  const sideBarActions: IAction[] = [
    {
      id: 0,
      icon: <IoAdd size={32} />,
      executeAction: () => openDialog?.(<FundDocumentFormDialog openMode={OpenModeEnum.New} />)
    }
  ];

  const actionExecuted = useCallback((document: IFundDocument, actionType: TableActionsEnum) => {
    if (actionType === TableActionsEnum.RowClick) {
      push({
        pathname: AppPagesEnum.FundDocumentTemplate,
        state: { documentId: document.id }
      });
    }
  }, []);

  const tableProps = {
    headers: tableHeaders,
    rows: tableRowsStructure,
    data: documents,
    onExecuteAction: actionExecuted,
    loading
  };

  return (
    <FundDocumentListPageContainer>
      <div className="page-content">
        <TopBarActions label={`Subscription Documents (${documents.length})`} {...{ fetch }} />
        <div className="px-3">
          <GTable {...tableProps} />
        </div>
      </div>

      <ActionsBar actions={sideBarActions} />
    </FundDocumentListPageContainer>
  );
}
