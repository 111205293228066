import './LoginPage.scss';

import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import { useAuth } from 'providers/auth.provider';
import React, { useCallback, useState } from 'react';
import { FiInfo, FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import GForm, { IValidateResult } from 'shared/controls/GForm';
import GInput from 'shared/controls/GInput';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';
import * as yup from 'yup';

const Alert = styled.div`
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: flex;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  justify-content: space-between;
  align-items: center;
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
`;

const LoginPageContainer = styled(GForm)`
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}
`;

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Provide a valid email'),
  password: yup.string().required('Password is required')
});

interface ILoginData {
  email: string;
  password: string;
}

export default function LoginPage() {
  const { push } = useHistory();
  const { signIn } = useAuth();
  const { showSnackbar } = useApp();

  const [loading, setLoading] = useState(false);
  const [showUnauthorized, setShowUnauthorized] = useState(false);
  const [inputPasswordType, setInputPasswordType] = useState<React.HTMLInputTypeAttribute>('password');

  const onSubmit = useCallback(
    async ({ data }: IValidateResult<ILoginData>) => {
      try {
        setShowUnauthorized(false);

        if (signIn) {
          setLoading(true);
          const { email, password } = data;
          await signIn(email, password);
        }
      } catch (error: any) {
        showSnackbar?.({ variant: 'error', message: error?.response?.data?.message });
      } finally {
        setLoading(false);
      }
    },
    [signIn, setShowUnauthorized, setLoading, showSnackbar]
  );

  const wait = React.useMemo(
    () => (
      <div className="d-flex align-items-center justify-content-center">
        <span className="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true" />
        Wait...
      </div>
    ),
    []
  );

  const togglePasswordVisibility = useCallback(() => {
    setInputPasswordType((prev) => (prev === 'text' ? 'password' : 'text'));
  }, []);

  return (
    <LoginPageContainer onValidate={onSubmit} validationSchema={validationSchema} className="LoginPage">
      <h2 className="form-title mb-5">Sign In {showUnauthorized && 'TRUE'}</h2>

      {showUnauthorized && (
        <Alert className="fadeIn" role="alert">
          <div className="d-flex align-items-center">
            <span className="me-2">
              <FiInfo size="16" />
            </span>
            <div>Invalid email or password.</div>
          </div>

          <button type="button" className="btn p-0" data-dismiss="alert" aria-label="Close" onClick={() => setShowUnauthorized(false)}>
            <FiX size="16" />
          </button>
        </Alert>
      )}

      <div className="mt-5">
        <GInput type="email" name="email" className="mb-4" label="Email" placeholder="name@domain.com" autoComplete="off" />

        <GInput type={inputPasswordType} name="password" className="mb-2" label="Password" placeholder="at least 8 characters" autoComplete="off" />

        <div className="d-flex justify-content-between">
          <div className="form-check mb-4">
            <input className="form-check-input" type="checkbox" checked={inputPasswordType === 'text'} onChange={togglePasswordVisibility} />
            <label className="form-check-label">Show password</label>
          </div>

          <button type="button" className="forgot-your-password mb-5" onClick={() => push(AppPagesEnum.ForgotPassword)}>
            Forgot password?
          </button>
        </div>

        {/* <div className="form-check mb-4">
          <input className="form-check-input" type="checkbox" value="" />
          <label className="form-check-label">Remember me</label>
        </div> */}

        <button type="submit" className="btn btn-primary py-2 w-100 text-white" disabled={loading}>
          {loading ? wait : 'Login'}
        </button>
      </div>
    </LoginPageContainer>
  );
}
