import { IUser } from 'models/user.model';
import { useApp } from 'providers/app.provider';
import { UserRole } from 'providers/auth.provider';
import React, { useMemo } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import useUserService from 'services/user.service';
import GForm, { IValidateResult } from 'shared/controls/GForm';
import GInput from 'shared/controls/GInput';
import GInputSelect from 'shared/controls/GInputSelect';
import GLoading from 'shared/controls/GLoading';
import TopBarActions from 'shared/sections/TopBarActions';
import TopBarLabelContainer from 'shared/styled/TopBarLabelContainer';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';
import * as yup from 'yup';

const UserAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .page-content {
    padding: 3rem 1rem;

    .user-info {
      display: flex;

      &-picture {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 5rem;

        .change-picture-label {
          ${({ theme }: IAppTheme) => theme.mixins.desktopLinkSmall};
          font-weight: bold;
        }
      }

      &-form {
        flex: 1;

        .form-title {
          ${({ theme }: IAppTheme) => theme.mixins.desktopLinkSmall};
          font-weight: bold;
        }
      }
    }
  }
`;

const generalValidationSchema = yup.object().shape({
  name: yup.string().required('You should provide your complete name')
});

const passwordValidationSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords do not match')
});

export default function UserAccountPage() {
  const { state } = useLocation<{ user: IUser }>();
  const { showSnackbar } = useApp();

  const UserService = useUserService();

  const [loading, setLoading] = React.useState(false);

  const topBarLabel = useMemo(() => {
    return (
      <TopBarLabelContainer>
        <span>Users</span>
        <RiArrowRightSLine size={20} />
        <span className="current-item">User Account Settings</span>
      </TopBarLabelContainer>
    );
  }, [state?.user]);

  const updateUserData = React.useCallback(
    ({ data }: IValidateResult<IUser>) => {
      const { name, password } = data;

      if (state.user) {
        setLoading(true);
        UserService.update(state.user.id, { name, password } as any)
          .finally(() => setLoading(false))
          .then(() => showSnackbar?.({ variant: 'success', message: 'The user data was successfully updated.' }))
          .catch(() => showSnackbar?.({ variant: 'error', message: 'The action could not be executed. Please, try again!' }));
      }
    },
    [state.user]
  );

  const roleOptions = React.useMemo(() => {
    return [UserRole.Admin, UserRole.General].map((role) => ({ name: role.toLocaleLowerCase(), label: role }));
  }, []);

  return (
    <UserAccountContainer>
      {loading && <GLoading />}
      <TopBarActions label={topBarLabel} />
      <div className="page-content">
        <div className="user-info">
          <div className="user-info-form">
            <GForm initialData={state.user} onValidate={updateUserData} validationSchema={generalValidationSchema}>
              <div className="form-title">General Settings</div>
              <hr />
              <div className="row py-3">
                <div className="col-md-4">
                  <GInput name="name" label="Full Name" placeholder="User full name" className="mb-4" />
                </div>
                <div className="col-md-4">
                  <GInput name="email" label="Email" placeholder="User email" className="mb-4" readOnly />
                </div>
                <div className="col-md-4" />
                <div className="col-md-4">
                  <GInputSelect name="role" label="Role" valueKey="name" options={roleOptions} className="mb-4 text-capitalize" isDisabled />
                </div>
                <div className="col-md-8" />
                <div className="col-md-4">
                  <button type="submit" className="btn btn-primary py-2 mb-4">
                    Save changes
                  </button>
                </div>
              </div>
            </GForm>

            <div className="form-title">Security Settings</div>
            <hr />

            <GForm onValidate={updateUserData} validationSchema={passwordValidationSchema}>
              <div className="row py-3">
                <div className="col-md-4">
                  <GInput type="password" name="password" label="New Password" className="mb-4" />
                </div>
                <div className="col-md-4">
                  <GInput type="password" name="confirmPassword" label="Repeat Password" className="mb-4" />
                </div>
                <div className="col-md-4" />
                <div className="col-md-8 d-flex">
                  <button type="submit" className="btn btn-primary py-2 me-4">
                    Update password
                  </button>

                  <button type="submit" className="btn btn-dark py-2">
                    Reset password
                  </button>
                </div>
              </div>
            </GForm>

            <br />

            <div className="form-title">Advanced Settings</div>
            <hr />

            <button type="submit" className="btn btn-danger my-3 py-2">
              Remove account
            </button>
          </div>
        </div>
      </div>
    </UserAccountContainer>
  );
}
