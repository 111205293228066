import { IUser } from 'models/user.model';
import React, { useCallback } from 'react';

import GForm, { IValidateResult } from 'shared/controls/GForm';
import GInput from 'shared/controls/GInput';
import GInputCheckbox from 'shared/controls/GInputCheckbox';
import * as yup from 'yup';

import { useSubscriberSignUpContext } from '../SignUpSubscriberPage';

export default function FormStepTwo() {
  const { updateUserData, back } = useSubscriberSignUpContext();

  const onSubmit = useCallback(({ data }: IValidateResult<IUser>) => {
    updateUserData?.(data);
  }, []);

  const validationSchema = yup.object().shape({
    companyName: yup.string().required('Company name is required')
  });

  return (
    <GForm onValidate={onSubmit} validationSchema={validationSchema}>
      <GInput name="companyName" className="mb-4" label="Company Name" placeholder="Enter company name" />

      <GInput name="phoneNumber" className="mb-4" label="Telephone Number" placeholder="Enter telephone number" />

      <GInput name="averageShipments" className="mb-4" label="Specify your average number of shipments" placeholder="Select" />

      <GInputCheckbox name="agreedTermsOfUse" label="I have read and agree to the terms of use" />

      <GInputCheckbox name="wantReceiveNewsletter" label="I would like to receive the newsletter" className="mb-5" />

      <div className="row">
        <div className="col-md-6">
          <button type="button" className="btn btn-secondary py-2 w-100 text-white" onClick={() => back?.()}>
            Back
          </button>
        </div>

        <div className="col-md-6">
          <button type="submit" className="btn btn-primary py-2 w-100 text-white">
            Create Account
          </button>
        </div>
      </div>
    </GForm>
  );
}
