import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import styled from 'styled-components';

const GInputCheckboxContainer = styled.div`
  width: 28px;
  height: 28px;

  .round {
    position: relative;
  }

  .round label {
    background-color: #eff0f6;
    border-color: #d9dbe9;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 6px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg) translateX(-10%) translateY(-110%);
    width: 12px;
  }

  .round input[type='checkbox'] {
    visibility: hidden;
  }

  .round input[type='checkbox']:checked + label {
    background-color: #002a48;
    border-color: #002a48;
  }

  .round input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
`;

export interface InputAutocompleteProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  disableForm?: boolean;
}

export default function GInputCheckbox(props: InputAutocompleteProps) {
  const { name, className, label, disableForm, ...rest } = props;

  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name as string);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked'
    });
  }, [fieldName, registerField]);

  return (
    <GInputCheckboxContainer className={className}>
      <div className="round">
        <input ref={inputRef} id={name} type="checkbox" {...rest} />
        <label htmlFor={name}>{label}</label>
      </div>
    </GInputCheckboxContainer>
  );
}
