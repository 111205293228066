import $ from 'jquery';
import { IFundDocument } from 'models/fund-document.model';
import { ISubscription } from 'models/subscription.model';
import { DocumentTemplateCodeEnum, GetDocumentTemplateByCode } from 'pages/fund-document/documents/documents.config';
import AppPagesEnum from 'pages/pages.enum';
import React from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import useFundDocumentService from 'services/fund-documents.service';
import useSubscriptionService from 'services/subscription.service';
import GForm from 'shared/controls/GForm';
import GLoading from 'shared/controls/GLoading';
import TopBarActions from 'shared/sections/TopBarActions';
import TopBarLabelContainer from 'shared/styled/TopBarLabelContainer';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

import SubscriberHeader from './components/SubscriberHeader';
import SubscriptionUserHeader from './components/SubscriptionUserHeader';

const SubscriptionInfoPageContainer = styled.div`
  display: flex;
  height: 100%;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .subscription-info-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    hr {
      width: 100%;
      border: none;
      border-bottom: 2px solid rgba(0, 0, 0, 0.11);
      margin: 1rem 0;
      color: white;
    }

    .selected-fields {
      position: fixed;
      bottom: 0;
      background-color: white;
      padding: 20px 10px 10px;
      border: 2px solid rgba(0, 0, 0, 0.11);
      border-bottom: none;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      border-radius: 10px 10px 0 0;
      left: 50%;
      transform: translateX(-30%);
    }

    .subscription-section {
      &-title {
        font-size: 16px;
        font-weight: 600;
        color: #404040;
      }

      &-table {
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;

        tr {
          &:nth-child(odd) {
            background: rgba(24, 61, 98, 0.04);
          }

          td {
            padding: 7px 10px;
            vertical-align: baseline;

            &:last-child {
              border-radius: 0px 2px 2px 0px;
              font-weight: 300;
              text-align: right;
            }

            &:first-child {
              border-radius: 2px 0 0 2px;
              font-weight: 500;
              text-align: left;
            }
          }
        }
      }
    }

    .status-container {
      display: flex;
      flex-direction: column;
      padding: 2rem 2rem 0;
      overflow-y: hidden;
    }

    .heading-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #404040;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      background-color: #e6f5f8;
      padding: 7px 10px;
      border-radius: 6px;
    }
  }
`;

export default function SubscriptionPage() {
  const { state } = useLocation<{ subscriptionId: number; page: AppPagesEnum }>();

  const SubscriptionService = useSubscriptionService();
  const FundDocumentService = useFundDocumentService();

  const [subscription, setSubscription] = React.useState<ISubscription>();
  const [fundDocument, setFundDocument] = React.useState<IFundDocument>();
  const [answers, setAnswers] = React.useState<any>();
  const [issues, setIssues] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);

  $(() => {
    const containerInputs = $('.vgs-input-container');
    containerInputs.empty();
    containerInputs.append('EMPTY');
    containerInputs.css('color', 'blue');

    $('input').prop('disabled', true);

    if (answers) {
      const keys = Object.keys(answers);

      keys.forEach((key) => {
        const element = $(`#${key}`);

        if (answers[key] && !!answers[key].length) {
          element.empty();
          element.append(answers[key]);
          element.css('color', 'green');
        }
      });
    }

    issues?.forEach((issue) => {
      $(`#${issue}`).css('color', 'red');
    });
  });

  React.useEffect(() => {
    if (state?.subscriptionId) {
      setLoading(true);
      SubscriptionService.one(state.subscriptionId)
        .then((res) => {
          setSubscription(res.data);
          setAnswers(res?.data?.answers);
          setIssues([...JSON.parse(res?.data?.issues ?? '[]')]);

          if (res.data.fund) {
            FundDocumentService.oneByFund(res?.data?.fund?.id)
              .finally(() => setLoading(false))
              .then((res) => setFundDocument(res.data));
          }
        })
        .catch(() => setLoading(false));
    }
  }, [state.subscriptionId]);

  const topBarLabel = React.useMemo(() => {
    if (state.page === AppPagesEnum.FundDetails) {
      return (
        <TopBarLabelContainer>
          <span>Funds</span>
          <RiArrowRightSLine size={20} />
          <span>Fund Details</span>
          <RiArrowRightSLine size={20} />
          <span className="current-item">{subscription?.fund?.name} Subscription</span>
        </TopBarLabelContainer>
      );
    }

    return (
      <TopBarLabelContainer>
        <span>My Subscriptions</span>
        <RiArrowRightSLine size={20} />
        <span className="current-item">{subscription?.fund?.name} Subscription</span>
      </TopBarLabelContainer>
    );
  }, [state.page, subscription]);

  const userHeader = React.useMemo(() => {
    if (state.page === AppPagesEnum.FundDetails) {
      return subscription ? <SubscriptionUserHeader {...{ subscription, issues }} /> : <></>;
    }

    if (state.page === AppPagesEnum.SubscriptionDetails) {
      return subscription ? <SubscriberHeader {...{ subscription, issues }} /> : <></>;
    }

    return <></>;
  }, [state.page, subscription, issues]);

  return (
    <SubscriptionInfoPageContainer>
      {loading && <GLoading />}
      <div className="subscription-info-page-content">
        <TopBarActions label={topBarLabel} />

        <div className="my-4">
          <p className="heading-title">{subscription?.fund?.name} Subscription</p>

          <hr />

          {userHeader}

          <GForm onValidate={() => {}} initialData={answers}>
            {GetDocumentTemplateByCode(fundDocument?.templateCode as DocumentTemplateCodeEnum)}
          </GForm>
        </div>
      </div>
    </SubscriptionInfoPageContainer>
  );
}
