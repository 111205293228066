import { IUser } from 'models/user.model';
import React, { ReactElement } from 'react';
import { IconType } from 'react-icons';
import { IoWarningOutline } from 'react-icons/io5';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import styled from 'styled-components';
import AppTheme, { IAppTheme } from 'themes/app-theme.config';

const StatusBlocksContainer = styled.div`
  display: flex;
  margin-bottom: 3rem;
`;

const StatusBlockContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: ${({ theme }: IAppTheme) => theme.colors.input};
  padding: 1.5rem 1.5rem 1.5rem 2rem;
  margin-right: 2rem;

  .status-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${({ theme }: IAppTheme) => theme.colors.offBlack};

    &-title {
      ${({ theme }: IAppTheme) => theme.mixins.desktopLinkSmall};
      font-weight: bold;
      white-space: nowrap;
    }

    &-value {
      ${({ theme }: IAppTheme) => theme.mixins.desktopDisplayXSmall};
      font-weight: bold;
      white-space: nowrap;
    }

    &-label {
      ${({ theme }: IAppTheme) => theme.mixins.desktopLinkXSmall};
      color: ${({ theme }: IAppTheme) => theme.colors.label};
      white-space: nowrap;
    }
  }

  .status-image {
    width: 80px;
    height: 80px;
    border-radius: 16px;
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }: IAppTheme) => theme.colors.offWhite};
  }
`;

export interface StatusBlockData {
  id: number;
  title: string;
  value: string;
  label?: string;
  color: string;
  icon: ReactElement<IconType>;
}

type SubscriberStatusBlocksProps = {
  subscriber: IUser;
};

export default function SubscriberStatusBlocks({ subscriber }: SubscriberStatusBlocksProps) {
  const blocksData: StatusBlockData[] = [
    {
      id: 0,
      color: AppTheme.colors.primaryColor,
      icon: <RiMoneyDollarCircleLine size={46} color={AppTheme.colors.primaryColor} />,
      // label: 'Over last month $123,909',
      title: 'Total Equity Invested',
      value: `$${subscriber.totalcommitment}`
    },
    {
      id: 1,
      color: AppTheme.colors.warning,
      icon: <IoWarningOutline size={46} color={AppTheme.colors.warning} />,
      title: 'Compliance Status',
      value: `${subscriber.totalIssues ?? 0}`
    }
  ];

  return (
    <StatusBlocksContainer>
      {blocksData.map((block) => {
        return (
          <StatusBlockContainer key={block.id}>
            <div className="status-data">
              <div className="status-data-title">{block.title}</div>
              <div className="status-data-value" style={{ color: block.color }}>
                {block.value}
              </div>
              <div className="status-data-label">{block.label}</div>
            </div>
            <div className="status-image">{block.icon}</div>
          </StatusBlockContainer>
        );
      })}
    </StatusBlocksContainer>
  );
}
