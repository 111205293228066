import { IUser } from 'models/user.model';
import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import React, { useCallback, useEffect, useState } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { RiFileExcel2Line } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import useUserService from 'services/user.service';
import GTable, { RowStructure, TableActionsEnum } from 'shared/controls/GTable';
import ActionsBar, { IAction } from 'shared/sections/ActionsBar';
import TopBarActions from 'shared/sections/TopBarActions';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const SubscribersPageContainer = styled.div`
  display: flex;
  height: 100%;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .subscribers-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .status-container {
      padding: 2rem;
    }
  }
`;

const tableHeaders = ['Id', 'Full Name', 'Email', 'Total Commitment'];

const tableRowsStructure: RowStructure[] = [
  { key: 'id' },
  { key: 'name' },
  { key: 'email' },
  {
    key: 'totalcommitment',
    useComponent: (subscriber: IUser) => <span>$ {subscriber.totalcommitment ?? 0}</span>
  }
];

export default function SubscribersPage() {
  const { push } = useHistory();
  const { openDialog } = useApp();

  const userService = useUserService();

  const [subscribers, setSubscribers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    fetch();
  }, []);

  const fetch = React.useCallback(() => {
    setLoading(true);
    userService
      .allSubscribers()
      .then((res) => setSubscribers(res.data))
      .finally(() => setLoading(false));
  }, [userService]);

  const sideBarActions: IAction[] = [
    {
      id: 0,
      icon: <FiEdit3 size={32} />,
      executeAction: () => openDialog?.(<div />)
    },
    {
      id: 1,
      icon: <RiFileExcel2Line size={32} />,
      executeAction: () => {}
    }
  ];

  const actionExecuted = useCallback((subscriber: IUser, actionType: TableActionsEnum) => {
    if (actionType === TableActionsEnum.RowClick) {
      push({
        pathname: AppPagesEnum.SubscriberDetails,
        state: { subscriber }
      });
    }
  }, []);

  const filterSubscribers = React.useMemo(
    () =>
      (!!filter.length && subscribers.filter((subscriber) => subscriber.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))) ||
      subscribers,
    [subscribers, filter]
  );

  const tableProps = {
    headers: tableHeaders,
    rows: tableRowsStructure,
    data: filterSubscribers,
    onExecuteAction: actionExecuted,
    loading
  };

  return (
    <SubscribersPageContainer>
      <div className="subscribers-page-content">
        <TopBarActions label={`Subscribers List (${subscribers.length})`} {...{ fetch }} />

        <div className="d-flex align-items-center m-4">
          <span>Filter Subscribers by Name:</span>
          <input type="text" onChange={(event) => setFilter(event.target.value)} className="form-control w-25 ms-3" />
        </div>

        <hr className="m-0" />
        <GTable {...tableProps} />
      </div>
      <ActionsBar actions={sideBarActions} />
    </SubscribersPageContainer>
  );
}
