import { ISubscription } from 'models/subscription.model';
import { IUser } from 'models/user.model';
import moment from 'moment';
import AppPagesEnum from 'pages/pages.enum';
import { useApp } from 'providers/app.provider';
import React, { useEffect, useMemo, useState } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { RiArrowRightSLine, RiErrorWarningLine, RiFileExcel2Line } from 'react-icons/ri';
import { useHistory, useLocation } from 'react-router-dom';
import useUserService from 'services/user.service';
import GLoading from 'shared/controls/GLoading';
import GTableTabs from 'shared/controls/GTableTabs';
import GTableV2, { GTableV2RowStructure } from 'shared/controls/GTableV2';
import ActionsBar, { IAction } from 'shared/sections/ActionsBar';
import TopBarActions from 'shared/sections/TopBarActions';
import Title from 'shared/styled/Title';
import TopBarLabelContainer from 'shared/styled/TopBarLabelContainer';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

import SubscriberStatusBlocks from './components/SubscriberStatusBlocks';

const SubscriberDetailsPageContainer = styled.div`
  display: flex;
  height: 100%;
  ${({ theme }: IAppTheme) => theme.transitions.fadeIn}

  .subscriber-details-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .status-container {
      display: flex;
      flex-direction: column;
      padding: 2rem 2rem 0;
      overflow-y: hidden;
    }
  }
`;

const tableTabs = [
  {
    key: 0,
    icon: <RiErrorWarningLine size={20} />,
    label: 'Current Funds'
  },
  {
    key: 1,
    icon: <IoCheckmarkCircleOutline size={20} />,
    label: 'Past Funds'
  }
];

const tableRowsStructure: GTableV2RowStructure[] = [
  {
    key: 'name',
    getValue: (subscription: ISubscription) => (
      <span>
        <strong>{subscription.fund?.name}</strong>
      </span>
    )
  },
  { key: 'email' },
  {
    key: 'amount',
    getValue: (subscription: ISubscription) => (
      <span>
        Commitment: <strong>${subscription.totalAmount ?? 0}</strong>
      </span>
    )
  },
  {
    key: 'createdAt',
    getValue: (subscription: ISubscription) => (
      <span>
        Created On: <strong>{moment(subscription.createdAt).format('MM/DD/YYYY HH:mm')}</strong>
      </span>
    )
  }
];

export default function SubscriberDetailsPage() {
  const { push } = useHistory();
  const { state } = useLocation<{ subscriber: IUser }>();
  const { openDialog, openQuestionDialog, showSnackbar } = useApp();

  const UserService = useUserService();

  const [tabSelected, changeTab] = useState(0);
  const [subscriber, setSubscriber] = useState<IUser>();
  const [loading, setLoading] = useState(false);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    UserService.oneSubscriber(state.subscriber.id)
      .then((res) => setSubscriber(res.data))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (state?.subscriber) {
      fetchData();
    }
  }, []);

  const currentTabSubscriptions = useMemo(
    () =>
      subscriber?.subscriptions?.filter?.((subscription) => {
        if (tabSelected) {
          return !subscription.fund.active;
        }

        return subscription.fund.active;
      }) ?? ([] as ISubscription[]),
    [tabSelected, subscriber?.subscriptions]
  );

  const topBarLabel = useMemo(() => {
    return (
      <TopBarLabelContainer>
        <span>Subscribers</span>
        <RiArrowRightSLine size={20} />
        <span className="current-item">{state?.subscriber?.name}</span>
      </TopBarLabelContainer>
    );
  }, [state?.subscriber]);

  const sideBarActions: IAction[] = [
    {
      id: 0,
      icon: <FiEdit3 size={32} />,
      executeAction: () => openDialog?.(<div />)
    },
    {
      id: 1,
      icon: <RiFileExcel2Line size={32} />,
      executeAction: () => {}
    }
  ];

  function onSelectSubscription(subscription: ISubscription) {
    push({
      pathname: AppPagesEnum.SubscriptionDetails,
      state: { subscriptionId: subscription.id }
    });
  }

  const tableProps = {
    data: currentTabSubscriptions,
    rowsStructure: tableRowsStructure,
    onClickRow: onSelectSubscription,
    loading
  };

  return (
    <SubscriberDetailsPageContainer>
      <div className="subscriber-details-page-content">
        <TopBarActions {...{ label: topBarLabel, fetch: fetchData }} />

        {loading && <GLoading />}

        <div className="status-container">
          <div className="d-flex justify-content-between">
            <Title>{subscriber?.name} - Subscriber Details</Title>
            <div className="d-flex align-items-start"></div>
          </div>

          {subscriber && <SubscriberStatusBlocks {...{ subscriber }} />}

          <hr className="m-0" />
          <TopBarActions label={`Funds Subscriptions (${subscriber?.subscriptions.length})`} />
          <GTableTabs {...{ tabs: tableTabs, changeTab, tabSelected }} />
          <GTableV2 {...tableProps} />
        </div>
      </div>
      <ActionsBar actions={sideBarActions} />
    </SubscriberDetailsPageContainer>
  );
}
