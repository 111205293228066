import { IconButton, Tooltip } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { IoReload } from 'react-icons/io5';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const TopBarActionsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 1rem;
  ${({ theme }: IAppTheme) => theme.mixins.desktopLinkSmall}
  border-bottom: 2px solid ${({ theme }: IAppTheme) => theme.colors.line};
  min-height: 4rem;

  .sections-bar {
    display: flex;
    align-items: center;
  }

  .topBar-action-label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

type TopBarActionsProps = {
  label?: string | ReactElement<any, any>;
  fetch?: () => void;
};

export default function TopBarActions({ label, fetch }: TopBarActionsProps) {
  return (
    <TopBarActionsContainer>
      <div>
        {fetch && (
          <div className="sections-bar">
            <Tooltip title="Refresh Data">
              <IconButton aria-label="dropdown" size="medium" onClick={() => fetch?.()}>
                <IoReload />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>

      {label && <strong className="topBar-action-label">{label}</strong>}
    </TopBarActionsContainer>
  );
}
