import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { FiSearch } from 'react-icons/fi';
import styled from 'styled-components';
import { IAppTheme } from 'themes/app-theme.config';

const InputContainer = styled.div`
  position: relative;

  .input-container {
    width: 100%;

    input {
      @include font-body-small;
      background-color: transparent;
      background-color: ${({ theme }: IAppTheme) => theme.colors.input};
      border-radius: 6px;
      padding: 10px 16px;
      width: 100%;
      object-fit: contain;
      border: none;
      color: ${({ theme }: IAppTheme) => theme.colors.label};

      &::placeholder {
        color: $placeholder;
        color: ${({ theme }: IAppTheme) => theme.mixins.desktopTextXSmall};
        font-weight: 400;
        opacity: 1;
      }
    }

    .search-icon {
      margin-right: 10px;
      color: $placeholder;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  label {
    display: block;
  }

  .form-label {
    color: $label;
    color: ${({ theme }: IAppTheme) => theme.mixins.desktopTextSmall};
  }

  .invalid-feedback {
    display: block;
  }
`;

type QCInputProps = {
  label?: string;
  inputType?: 'search';
  disableForm?: boolean;
};

export default function GInput(props: InputHTMLAttributes<HTMLInputElement> & QCInputProps) {
  const { name, label, className, inputType, disableForm, ...rest } = props;

  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name as string);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);

  return (
    <InputContainer className={className}>
      {label && <label className="form-label">{label}</label>}

      <div className="input-container">
        <input ref={inputRef} type="text" defaultValue={defaultValue} className={`form-control ${error ? 'is-invalid' : ''}`} {...rest} />
        {inputType && <FiSearch className="search-icon" />}
      </div>
      {error && <div className="invalid-feedback">{error}</div>}
    </InputContainer>
  );
}
